import React, { useState, useEffect, useRef } from "react";
import { buildNestedFolders } from "../DMA/buildNestedFolders";
import Loader from "../../components/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Folder,
  ChevronRight,
  ChevronDown,
  X,
  Trash2,
  Download,
} from "lucide-react";

/** =========================
  1) ENV & Auth
========================= **/
const clientUrl = process.env.REACT_APP_CLIENT_URL || "https://example.com";
const clientId = process.env.REACT_APP_CLIENT_ID || "someId";
const clientSecret = process.env.REACT_APP_CLIENT_SECRET || "someSecret";
const encodedAuth = btoa(`${clientId}:${clientSecret}`);

/** =========================
  2) Azure Blob Details
========================= **/
const sasToken = `?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2026-01-01T04:39:29Z&st=2025-01-08T20:39:29Z&spr=https&sig=O%2F0i1VStuud2ZXC%2FNDjb9AzwX2E14TY37M9El4TT61I%3D`;
const containerUrl = `https://greengenblobstorage.blob.core.windows.net/development`;

/** =========================
  3) Impact Levels
========================= **/
const IMPACT_LEVELS = ["Negligible", "Moderate", "Significant", "High"];

/** =========================
  4) Sorting by ESRS
========================= **/
function getSortKey(esrsString) {
  if (/^\d+$/.test(esrsString)) {
    return [0, parseInt(esrsString, 10)];
  }
  if (/^E\d+$/i.test(esrsString)) {
    return [1, parseInt(esrsString.slice(1), 10)];
  }
  if (/^S\d+$/i.test(esrsString)) {
    return [2, parseInt(esrsString.slice(1), 10)];
  }
  if (/^G\d+$/i.test(esrsString)) {
    return [3, parseInt(esrsString.slice(1), 10)];
  }
  return [4, esrsString];
}

/** =========================
  A) Notification (Toast)
========================= **/
function Notification({ message, type = "success", onClose }) {
  if (!message) return null;
  const colorClass = type === "error" ? "bg-red-600" : "bg-green-600";
  return (
    <div className="fixed bottom-5 right-5 z-50 animate-toast-slideup">
      <div
        className={`${colorClass} text-white px-4 py-3 rounded shadow-md relative`}
      >
        <span>{message}</span>
        <button
          className="absolute top-1 right-2 text-white hover:text-gray-200"
          onClick={onClose}
        >
          <X size={16} />
        </button>
      </div>
    </div>
  );
}

/** =========================
  B) FolderSelectionModal
========================= **/
function FolderNode({
  folder,
  level,
  expandedFolders,
  setExpandedFolders,
  onSelectFolder,
}) {
  const hasChildren =
    Array.isArray(folder.children) && folder.children.length > 0;
  const isExpanded = expandedFolders.has(folder.id);

  const toggleExpand = (e) => {
    e.stopPropagation();
    const next = new Set(expandedFolders);
    if (next.has(folder.id)) {
      next.delete(folder.id);
    } else {
      next.add(folder.id);
    }
    setExpandedFolders(next);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    onSelectFolder(folder.id);
  };

  return (
    <div style={{ paddingLeft: `${level * 1.2}rem` }} className="mb-1">
      <div
        className="flex items-center p-2 rounded hover:bg-gray-50 cursor-pointer"
        onClick={handleClick}
      >
        {hasChildren && (
          <button
            onClick={toggleExpand}
            className="mr-1 text-gray-500 hover:text-gray-700"
          >
            {isExpanded ? (
              <ChevronDown size={16} />
            ) : (
              <ChevronRight size={16} />
            )}
          </button>
        )}
        <Folder className="text-yellow-500" size={16} />
        <span className="ml-2 text-sm font-medium text-gray-800">
          {folder.name}
        </span>
      </div>

      {isExpanded && hasChildren && (
        <div className="mt-1">
          {folder.children.map((child) => (
            <FolderNode
              key={child.id}
              folder={child}
              level={level + 1}
              expandedFolders={expandedFolders}
              setExpandedFolders={setExpandedFolders}
              onSelectFolder={onSelectFolder}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function FolderSelectionModal({
  isOpen,
  folders = [],
  onClose,
  onSelectFolder,
}) {
  const [expandedFolders, setExpandedFolders] = useState(new Set());

  if (!isOpen) return null;

  const handleSelectFolder = (folderId) => {
    onSelectFolder(folderId);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="relative w-full max-w-md rounded-md bg-white shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-700"
        >
          <X size={20} />
        </button>

        <div className="p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800">
            Select a Folder
          </h2>
          <p className="text-sm text-gray-500">
            Choose a folder for file attachments
          </p>
        </div>

        <div className="max-h-80 overflow-auto p-4">
          {folders.length === 0 ? (
            <p className="text-gray-500 text-center">No folders found.</p>
          ) : (
            folders.map((topFolder) => (
              <FolderNode
                key={topFolder.id}
                folder={topFolder}
                level={0}
                expandedFolders={expandedFolders}
                setExpandedFolders={setExpandedFolders}
                onSelectFolder={handleSelectFolder}
              />
            ))
          )}
        </div>

        <div className="flex justify-end gap-2 border-t border-gray-200 p-4">
          <button
            onClick={onClose}
            className="inline-flex items-center rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

/** =========================
   RemoveConfirmModal
========================= **/
function RemoveConfirmModal({ isOpen, attachment, onCancel, onConfirm }) {
  if (!isOpen || !attachment) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="bg-white rounded shadow-lg p-6 relative max-w-sm w-full">
        <button
          onClick={onCancel}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-700"
        >
          <X size={20} />
        </button>
        <h2 className="text-lg font-semibold mb-2">Remove Attachment</h2>
        <p className="text-sm text-gray-700 mb-4">
          Are you sure you want to remove this attachment?
          <br />
          <strong>{attachment.name || attachment.file_name}</strong>
        </p>

        <div className="flex justify-end gap-2">
          <button
            onClick={onCancel}
            className="rounded bg-gray-100 px-3 py-2 text-sm text-gray-700 hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="rounded bg-red-600 px-3 py-2 text-sm text-white hover:bg-red-700"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
}

/** =========================
   ConfirmActionModal
========================= **/
function ConfirmActionModal({
  isOpen,
  actionType = "Prepare", // "Prepare" or "Review"
  actionStatus = "on", // "on" or "off"
  onCancel,
  onConfirm,
}) {
  if (!isOpen) return null;

  // Build dynamic messages
  let title = `${actionType} Confirmation`;
  let message = "";
  if (actionStatus === "on") {
    message =
      actionType === "Prepare"
        ? "Are you sure you want to mark this as prepared?"
        : "Are you sure you want to mark this as reviewed?";
  } else {
    // actionStatus === "off"
    message =
      actionType === "Prepare"
        ? "Are you sure you want to unmark this as prepared?"
        : "Are you sure you want to unmark this as reviewed?";
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="bg-white rounded shadow-lg p-6 relative max-w-sm w-full">
        <button
          onClick={onCancel}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-700"
        >
          <X size={20} />
        </button>
        <h2 className="text-lg font-semibold mb-2">{title}</h2>
        <p className="text-sm text-gray-700 mb-4">{message}</p>

        <div className="flex justify-end gap-2">
          <button
            onClick={onCancel}
            className="rounded bg-gray-100 px-3 py-2 text-sm text-gray-700 hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="rounded bg-blue-600 px-3 py-2 text-sm text-white hover:bg-blue-700"
          >
            Yes, {actionStatus === "on" ? actionType : `Un-${actionType}`}
          </button>
        </div>
      </div>
    </div>
  );
}

/** =========================
   MultiCheckDropdown
========================= **/
function MultiCheckDropdown({
  options,
  selectedValues,
  onChange,
  noSelectionLabel = "---No selections---",
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close if click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (optionValue) => {
    if (selectedValues.includes(optionValue)) {
      onChange(selectedValues.filter((val) => val !== optionValue));
    } else {
      onChange([...selectedValues, optionValue]);
    }
  };

  const displayText =
    selectedValues.length === 0
      ? noSelectionLabel
      : `Selected: ${selectedValues.length}`;

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        type="button"
        className="relative block w-full rounded border border-gray-300 bg-white px-3 py-2 text-left text-sm text-gray-700 hover:border-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 appearance-none"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {displayText}
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400">
          <ChevronDown size={16} />
        </span>
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-72 max-h-60 overflow-auto rounded border border-gray-300 bg-white p-2 shadow">
          {options.length === 0 ? (
            <p className="text-sm text-gray-500">No options available.</p>
          ) : (
            options.map((opt) => (
              <label
                key={opt.value}
                className="flex items-center py-1 text-sm cursor-pointer"
              >
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={selectedValues.includes(opt.value)}
                  onChange={() => handleCheckboxChange(opt.value)}
                />
                {opt.label}
              </label>
            ))
          )}
        </div>
      )}
    </div>
  );
}

/** =========================
  MAIN Component: TopicRegister
========================= **/
export default function TopicRegister() {
  const { user } = useAuth0();
  const userEmail = user?.email;
  const userName = user?.name;

  // Toast
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("success");
  function showNotification(msg, type = "success") {
    setNotificationMessage(msg);
    setNotificationType(type);
    setTimeout(() => {
      setNotificationMessage("");
    }, 4000);
  }

  // 1) Topics & loading
  const [topics, setTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // 2) Per-topic local info
  const [assessments, setAssessments] = useState({});
  const [selectedTopicId, setSelectedTopicId] = useState(null);

  // 3) Folders
  const [rawFolders, setRawFolders] = useState([]);
  const [nestedFolders, setNestedFolders] = useState([]);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  // 4) Tag data
  const [taskTags, setTaskTags] = useState([]);
  const [topicTagsForFiles, setTopicTagsForFiles] = useState([]);

  // 5) Remove attachment modal
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [attachmentToRemove, setAttachmentToRemove] = useState(null);

  // 6) Prepare / Review modal (now includes toggling on/off)
  const [isConfirmActionOpen, setIsConfirmActionOpen] = useState(false);
  const [confirmActionType, setConfirmActionType] = useState("Prepare"); // "Prepare" or "Review"
  const [confirmActionStatus, setConfirmActionStatus] = useState("on"); // "on" or "off"
  const [topicIdForAction, setTopicIdForAction] = useState(null);

  // =============== fetchAllTags ===============
  async function fetchAllTags() {
    try {
      const [taskResp, topicResp] = await Promise.all([
        fetch(`${clientUrl}/ws/rest/getTasks`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
          body: JSON.stringify({ string: userEmail }),
        }),
        fetch(`${clientUrl}/ws/rest/getTopics`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
          body: JSON.stringify({ string: userEmail }),
        }),
      ]);
      if (!taskResp.ok) throw new Error("Failed to fetch tasks");
      if (!topicResp.ok) throw new Error("Failed to fetch topics for files");

      const [taskData, topicData] = await Promise.all([
        taskResp.json(),
        topicResp.json(),
      ]);

      // tasks
      if (Array.isArray(taskData) && taskData.length > 0) {
        const tasksArray = taskData[0].map((item) => item.tasks);
        setTaskTags(tasksArray);
      }

      // topics for file tagging
      if (Array.isArray(topicData) && topicData.length > 0) {
        const arrayOfTopics = topicData[0].map((item) => item.topics);
        setTopicTagsForFiles(arrayOfTopics || []);
      }
    } catch (err) {
      console.error("Error fetching tags:", err);
      showNotification("Error fetching tags. See console.", "error");
    }
  }

  // =============== Fetch Topics ===============
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const resp = await fetch(`${clientUrl}/ws/rest/getTopics`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
          body: JSON.stringify({ string: userEmail }),
        });
        if (!resp.ok) throw new Error("Failed to fetch topics");
        const data = await resp.json();

        if (Array.isArray(data) && data.length > 0) {
          const topicsArray = data[0].map((item) => item.topics);

          // Sort by ESRS
          topicsArray.sort((a, b) => {
            const aKey = getSortKey(a.esrs);
            const bKey = getSortKey(b.esrs);
            if (aKey[0] !== bKey[0]) return aKey[0] - bKey[0];
            return aKey[1] < bKey[1] ? -1 : aKey[1] > bKey[1] ? 1 : 0;
          });

          setTopics(topicsArray);

          // local "assessments" object
          const initAssessments = {};
          for (const t of topicsArray) {
            initAssessments[t.id] = {
              environmentImpact: t.impact_materiality || "",
              businessImpact: t.financial_materiality || "",
              rationale: t.rationale || "",
              serverFiles: Array.isArray(t.attachments) ? t.attachments : [],
              newFiles: [],
              folderId: null,
              preparer: t.preparer || null,
              reviewer: t.reviewer || null,
            };
          }
          setAssessments(initAssessments);

          // default select first
          if (topicsArray.length > 0) {
            setSelectedTopicId(topicsArray[0].id);
          }
        }
      } catch (err) {
        console.error("Error fetching topics:", err);
        showNotification("Error fetching topics. See console.", "error");
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // =============== Fetch Folders & Tag Data ===============
  useEffect(() => {
    // fetch folder data
    (async () => {
      try {
        const resp = await fetch(`${clientUrl}/ws/rest/getFileRepo`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
          body: JSON.stringify({ string: userEmail }),
        });
        if (!resp.ok) throw new Error("Failed to fetch file repo");
        const data = await resp.json();

        const folderArray = data[0] || [];
        setRawFolders(folderArray);

        const nested = buildNestedFolders(folderArray);
        setNestedFolders(nested);
      } catch (err) {
        console.error("Error fetching folders:", err);
        showNotification("Error fetching folders. See console.", "error");
      }
    })();

    // fetch tasks + topic tags
    fetchAllTags();
  }, []);

  // =========== Handlers ===========
  const handleTopicSelect = (tid) => {
    setSelectedTopicId(tid);
  };

  const handleEnvironmentChange = (tid, val) => {
    setAssessments((prev) => ({
      ...prev,
      [tid]: { ...prev[tid], environmentImpact: val },
    }));
  };

  const handleBusinessChange = (tid, val) => {
    setAssessments((prev) => ({
      ...prev,
      [tid]: { ...prev[tid], businessImpact: val },
    }));
  };

  const handleRationaleChange = (tid, val) => {
    setAssessments((prev) => ({
      ...prev,
      [tid]: { ...prev[tid], rationale: val },
    }));
  };

  const handleFolderSelect = (tid, folderId) => {
    setAssessments((prev) => ({
      ...prev,
      [tid]: { ...prev[tid], folderId },
    }));
  };

  // File upload
  const handleFileUpload = (tid, e) => {
    const newRawFiles = Array.from(e.target.files || []);
    if (!assessments[tid].folderId) {
      showNotification(
        "Please select a folder first before attaching files.",
        "error"
      );
      return;
    }

    const newFilesWithTags = newRawFiles.map((file) => ({
      file,
      selectedTaskTagIds: [],
      selectedTaskTagNames: [],
      selectedTopicTagIds: [],
      selectedTopicTagNames: [],
    }));

    setAssessments((prev) => ({
      ...prev,
      [tid]: {
        ...prev[tid],
        newFiles: [...prev[tid].newFiles, ...newFilesWithTags],
      },
    }));
  };

  const removeFile = (tid, fileObj) => {
    setAssessments((prev) => ({
      ...prev,
      [tid]: {
        ...prev[tid],
        newFiles: prev[tid].newFiles.filter((f) => f !== fileObj),
      },
    }));
  };

  // Remove existing attachments
  const handleOpenRemoveModal = (attachment) => {
    setAttachmentToRemove(attachment);
    setIsRemoveModalOpen(true);
  };
  const handleCloseRemoveModal = () => {
    setAttachmentToRemove(null);
    setIsRemoveModalOpen(false);
  };

  const handleRemoveServerAttachment = async () => {
    if (!attachmentToRemove) return;
    const fileId = attachmentToRemove.id;
    // We'll pick the first tag from the attachment, or default
    let selectedTag = attachmentToRemove.tags?.[0] || {
      tag_id: "",
      section: "Task",
    };

    const tagId = selectedTag?.tag_id || "";
    const section = selectedTag?.section || "Task";

    const payload = {
      user: userEmail,
      file_id: String(fileId),
      tag_id: String(tagId),
      section,
    };
    console.log("Removing attachment with payload:", payload);

    try {
      const resp = await fetch(`${clientUrl}/ws/rest/removeAttachment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify(payload),
      });
      if (!resp.ok) {
        throw new Error("Failed to remove attachment from server");
      }

      // local state remove
      setAssessments((prev) => {
        const newState = { ...prev };
        for (const eachTid of Object.keys(newState)) {
          const currentFiles = newState[eachTid].serverFiles || [];
          const updated = currentFiles.filter((f) => f.id !== fileId);
          newState[eachTid].serverFiles = updated;
        }
        return newState;
      });

      showNotification("Attachment removed successfully.", "success");
    } catch (err) {
      console.error("Error removing attachment:", err);
      showNotification("Error removing attachment. Check console.", "error");
    } finally {
      handleCloseRemoveModal();
    }
  };

  // Remove single tag
  const removeTagFromAttachment = async (attachment, tag) => {
    if (!attachment || !tag) return;
    const fileId = attachment.id;
    const tagId = tag.tag_id || "";
    const section = tag.section || "Task";

    const payload = {
      user: userEmail,
      file_id: String(fileId),
      tag_id: String(tagId),
      section,
    };
    console.log("Removing single tag =>", payload);

    try {
      const resp = await fetch(`${clientUrl}/ws/rest/removeAttachment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify(payload),
      });
      if (!resp.ok) {
        throw new Error("Failed to remove the single tag from server");
      }

      // local remove
      setAssessments((prev) => {
        const newState = { ...prev };
        for (const eachTid of Object.keys(newState)) {
          const currentFiles = newState[eachTid].serverFiles || [];
          const foundFile = currentFiles.find((f) => f.id === fileId);
          if (foundFile) {
            foundFile.tags = foundFile.tags.filter((t) => t.tag_id !== tagId);
          }
        }
        return newState;
      });

      showNotification(`Tag removed: ${tag.tag_name || tagId}`, "success");
    } catch (err) {
      console.error("Error removing single tag:", err);
      showNotification("Error removing single tag. Check console.", "error");
    }
  };

  /**
   * PREPARE / REVIEW ONCLICK
   * If user toggles Prepare or Review, we open the same modal but
   * pass the "type" (Prepare/Review) and "status" (on/off).
   * Then in handleConfirmAction we do the actual update.
   */
  const handleTogglePrepare = (tid) => {
    const current = assessments[tid];
    if (!current) return;

    // If 'preparer' is currently null => we're turning Prepare "on"
    if (!current.preparer) {
      setConfirmActionType("Prepare");
      setConfirmActionStatus("on");
      setTopicIdForAction(tid);
      setIsConfirmActionOpen(true);
    } else {
      // We want to turn "off"
      // But we can't un-prepare if there's a reviewer
      if (current.reviewer) {
        showNotification(
          "Cannot unmark as prepared while it is still marked as reviewed.",
          "error"
        );
        return;
      }
      // Otherwise we can un-prepare
      setConfirmActionType("Prepare");
      setConfirmActionStatus("off");
      setTopicIdForAction(tid);
      setIsConfirmActionOpen(true);
    }
  };

  const handleToggleReview = (tid) => {
    const current = assessments[tid];
    if (!current) return;

    // If 'reviewer' is currently null => we're turning Review "on"
    if (!current.reviewer) {
      setConfirmActionType("Review");
      setConfirmActionStatus("on");
      setTopicIdForAction(tid);
      setIsConfirmActionOpen(true);
    } else {
      // Otherwise, un-review
      setConfirmActionType("Review");
      setConfirmActionStatus("off");
      setTopicIdForAction(tid);
      setIsConfirmActionOpen(true);
    }
  };

  /**
   * Called after user clicks "Yes, Prepare" or "Yes, Un-Prepare"
   * or similarly for Review.
   */
  const handleConfirmAction = async () => {
    if (!topicIdForAction) return;

    const isPrepare = confirmActionType === "Prepare"; // true = Prepare, false = Review
    const turningOn = confirmActionStatus === "on";

    // 1) If user tries to “Review on” before “Prepare on,” block it
    if (!isPrepare && turningOn) {
      const topicData = assessments[topicIdForAction];
      if (!topicData.preparer) {
        showNotification(
          "Topic cannot be reviewed without preparing.",
          "error"
        );
        setIsConfirmActionOpen(false);
        return;
      }
    }

    // 2) Decide what to send for `field`
    let fieldName;
    if (isPrepare) {
      // Prepare or un-Prepare
      fieldName = turningOn ? "Prepare" : null; // <--- null if off
    } else {
      // Review or un-Review
      fieldName = turningOn ? "Review" : null; // <--- null if off
    }

    // Build the final payload
    const payload = {
      user: userEmail,
      section: "Topic",
      tag_id: String(topicIdForAction),
      field: fieldName, // <--- will be "Prepare", "Review", or null
    };

    console.log("Toggle prepare/review =>", payload);

    try {
      // 3) POST to your backend
      const resp = await fetch(`${clientUrl}/ws/rest/updateButtons`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify(payload),
      });
      if (!resp.ok) {
        throw new Error(`Failed to update: ${fieldName}`);
      }

      // 4) Update local state
      setAssessments((prev) => {
        const copy = { ...prev };
        const topicData = copy[topicIdForAction];

        if (isPrepare) {
          if (turningOn) {
            topicData.preparer = userEmail; // user is marking "Prepare" on
          } else {
            topicData.preparer = null; // user is unmarking "Prepare" => set to null
          }
        } else {
          if (turningOn) {
            topicData.reviewer = userName; // user is marking "Review" on
          } else {
            // user is unmarking "Review" => set to null
            topicData.reviewer = null;
            // Optionally also clear other fields if needed:
            // topicData.preparer = null;
            // topicData.environmentImpact = "";
            // topicData.businessImpact = "";
            // topicData.rationale = "";
          }
        }
        return copy;
      });

      // 5) Show success
      showNotification(
        `Successfully ${
          turningOn ? "marked" : "unmarked"
        } as ${confirmActionType}.`,
        "success"
      );
    } catch (err) {
      console.error(
        `Error toggling ${confirmActionType} (${confirmActionStatus}):`,
        err
      );
      showNotification(`Error toggling. Check console.`, "error");
    } finally {
      setIsConfirmActionOpen(false);
    }
  };

  // Save -> /ws/rest/updateTopic
  const handleSaveTopic = async (tid) => {
    const data = assessments[tid];
    if (!data) return;

    const existing = data.serverFiles || [];
    const newlyUploaded = data.newFiles || [];

    // 1) Transform existing
    const existingAttachments = existing.map((att) => ({
      id: att.id || "",
      name: att.name || att.file_name || "UnknownAttachment",
      extension: att.extension || "dat",
      size: att.size || "0",
      mime: att.mime || "application/octet-stream",
      download_uri: att.download_uri || "",
      folder_id: data.folderId || "",
      tags: att.tags || [],
    }));

    // 2) Upload new
    const newAttachments = [];
    for (let fileObj of newlyUploaded) {
      const {
        file,
        selectedTaskTagIds,
        selectedTaskTagNames,
        selectedTopicTagIds,
        selectedTopicTagNames,
      } = fileObj;

      const azureName = file.name;
      const uploadUrl = `${containerUrl}/${azureName}${sasToken}`;
      try {
        await fetch(uploadUrl, {
          method: "PUT",
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "Content-Type": file.type || "application/octet-stream",
          },
          body: file,
        });
        console.log("Uploaded file to Azure:", file.name);
      } catch (err) {
        console.error("Azure upload error for file:", file.name, err);
        showNotification(`Failed to upload "${file.name}" to Azure.`, "error");
      }

      const finalDownloadUri = `${containerUrl}/${azureName}${sasToken}`;
      const dotIndex = file.name.lastIndexOf(".");
      const extension = dotIndex > -1 ? file.name.slice(dotIndex + 1) : "dat";

      // Build Task tags
      const taskTagObjs =
        selectedTaskTagIds.length > 0
          ? selectedTaskTagIds.map((taskId, idx) => ({
              section: "Task",
              tag_id: taskId,
              tag_name: selectedTaskTagNames[idx] || "",
            }))
          : [
              {
                section: "Task",
                tag_id: "",
                tag_name: "No associated task",
              },
            ];

      // Build Topic tags
      const currentTopic = topics.find((t) => t.id === parseInt(tid, 10)) || {};
      const fallbackTopic = {
        section: "Topic",
        tag_id: tid,
        tag_name: currentTopic.name || "Unknown",
      };

      const topicTagObjs =
        selectedTopicTagIds.length > 0
          ? selectedTopicTagIds.map((topicId, idx) => ({
              section: "Topic",
              tag_id: topicId,
              tag_name: selectedTopicTagNames[idx] || "",
            }))
          : [fallbackTopic];

      const newAttachment = {
        id: "",
        name: file.name,
        extension,
        size: String(file.size),
        mime: file.type || "application/octet-stream",
        download_uri: finalDownloadUri,
        folder_id: data.folderId || "",
        tags: [...taskTagObjs, ...topicTagObjs],
      };

      newAttachments.push(newAttachment);
    }

    // 3) Merge existing + new
    const finalAttachments = [...existingAttachments, ...newAttachments];

    // 4) Build payload
    const payload = {
      id: String(tid),
      financial_materiality: data.businessImpact || "Moderate",
      impact_materiality: data.environmentImpact || "Moderate",
      rationale: data.rationale || "",
      user: userEmail,
      preparer: data.preparer || "",
      reviewer: data.reviewer || "",
      attachments: finalAttachments,
    };

    console.log("Payload for updateTopic:", payload);

    try {
      const resp = await fetch(`${clientUrl}/ws/rest/updateTopic`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify(payload),
      });
      if (!resp.ok) throw new Error("Failed to update topic");

      const ctype = resp.headers.get("content-type") || "";
      if (ctype.includes("application/json")) {
        const result = await resp.json();
        console.log("Topic updated (JSON):", result);
      } else {
        const text = await resp.text();
        console.log("Topic updated (text):", text);
      }

      showNotification("Topic updated successfully.", "success");

      // 5) Merge newly added -> serverFiles
      setAssessments((prev) => ({
        ...prev,
        [tid]: {
          ...prev[tid],
          serverFiles: finalAttachments,
          newFiles: [],
        },
      }));

      // 6) Re-fetch tags
      fetchAllTags();
    } catch (err) {
      console.error("Error saving topic:", err);
      showNotification("Error saving topic. Check console.", "error");
    }
  };

  // ========== Rendering ==========
  if (isLoading) {
    return <Loader />;
  }

  const selectedTopic = topics.find((t) => t.id === selectedTopicId);
  const currentData = selectedTopicId ? assessments[selectedTopicId] : null;

  const getFolderNameById = (fid) => {
    if (!fid) return "";
    const f = rawFolders.find((fl) => fl.folder_id === parseInt(fid, 10));
    return f ? f.folder_name : "";
  };

  const handleDownloadAttachment = async (attachment) => {
    if (!attachment.download_uri) {
      showNotification("No download URI for this attachment.", "error");
      return;
    }
    try {
      const response = await fetch(attachment.download_uri);
      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", attachment.name || "downloadedFile");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download error:", error);
      showNotification("File download failed. Check console.", "error");
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Our Toast Notification */}
      <Notification
        message={notificationMessage}
        type={notificationType}
        onClose={() => setNotificationMessage("")}
      />

      {/* LEFT: Topics List */}
      <aside className="w-1/4 border-r bg-white p-4">
        <h3 className="text-lg font-semibold mb-4">Topics</h3>
        {topics.length === 0 ? (
          <p className="text-sm text-gray-500">No topics found.</p>
        ) : (
          <ul className="space-y-2">
            {topics.map((topic) => (
              <li key={topic.id}>
                <button
                  onClick={() => handleTopicSelect(topic.id)}
                  className={`block w-full text-left p-2 rounded
                    ${
                      topic.id === selectedTopicId
                        ? "bg-blue-100 text-blue-700"
                        : "hover:bg-gray-100 text-gray-700"
                    }`}
                >
                  {topic.esrs} - {topic.name}
                </button>
              </li>
            ))}
          </ul>
        )}
      </aside>

      {/* MIDDLE: Details & Attach */}
      <main className="w-1/2 bg-white p-6">
        {selectedTopic && currentData ? (
          <>
            {/* Title */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold text-gray-800">
                {selectedTopic.esrs} - {selectedTopic.name}
              </h3>
            </div>

            {/* Impact on People/Environment */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Impact on People/Environment
              </label>
              <select
                className="relative block w-full rounded border border-gray-300 bg-white px-3 py-2 text-sm text-gray-700 hover:border-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 appearance-none"
                value={currentData.environmentImpact}
                onChange={(e) =>
                  handleEnvironmentChange(selectedTopicId, e.target.value)
                }
              >
                <option value="">Select impact level</option>
                {IMPACT_LEVELS.map((lvl) => (
                  <option key={lvl} value={lvl}>
                    {lvl}
                  </option>
                ))}
              </select>
            </div>

            {/* Impact on Business Success */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Impact on Business Success
              </label>
              <select
                className="relative block w-full rounded border border-gray-300 bg-white px-3 py-2 text-sm text-gray-700 hover:border-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 appearance-none"
                value={currentData.businessImpact}
                onChange={(e) =>
                  handleBusinessChange(selectedTopicId, e.target.value)
                }
              >
                <option value="">Select impact level</option>
                {IMPACT_LEVELS.map((lvl) => (
                  <option key={lvl} value={lvl}>
                    {lvl}
                  </option>
                ))}
              </select>
            </div>

            {/* Rationale */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Rationale
              </label>
              <textarea
                rows={3}
                className="w-full p-2 border border-gray-300 rounded text-sm focus:outline-none"
                placeholder="Explain why these impacts matter..."
                value={currentData.rationale}
                onChange={(e) =>
                  handleRationaleChange(selectedTopicId, e.target.value)
                }
              />
            </div>

            {/* Folder selection */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Choose Folder
              </label>
              <button
                type="button"
                onClick={() => setIsFolderModalOpen(true)}
                className="relative block w-full rounded border border-gray-300 bg-white px-3 py-2 text-left text-sm text-gray-700 hover:border-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 appearance-none"
              >
                {currentData.folderId
                  ? `Folder: ${getFolderNameById(currentData.folderId)}`
                  : "Click to select a folder"}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400">
                  <ChevronDown size={16} />
                </span>
              </button>
            </div>

            {/* Attach Files */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Attach Files
              </label>
              <input
                type="file"
                multiple
                onChange={(e) => handleFileUpload(selectedTopicId, e)}
                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
            </div>

            {/* Newly selected files */}
            {currentData.newFiles.length > 0 && (
              <div className="mb-4 border rounded p-2 bg-gray-50">
                <h4 className="text-sm font-medium">Newly Selected Files</h4>
                <div className="mt-2 space-y-1">
                  {currentData.newFiles.map((fileObj, idx) => {
                    const {
                      file,
                      selectedTaskTagIds,
                      selectedTaskTagNames,
                      selectedTopicTagIds,
                      selectedTopicTagNames,
                    } = fileObj;

                    return (
                      <div
                        key={idx}
                        className="flex flex-col sm:flex-row items-start sm:items-center p-2 bg-white rounded"
                      >
                        {/* File name */}
                        <span className="flex-1 text-sm text-gray-700">
                          {file.name}
                        </span>

                        {/* TASK TAGS */}
                        <div className="mt-2 sm:mt-0 sm:ml-2 w-48">
                          <label className="block text-xs font-medium text-gray-700 mb-1">
                            Task Association
                          </label>
                          <MultiCheckDropdown
                            options={taskTags.map((tg) => ({
                              value: String(tg.id),
                              label: tg.name,
                            }))}
                            selectedValues={selectedTaskTagIds}
                            onChange={(updatedIds) => {
                              const updatedNames = updatedIds.map((id) => {
                                const match = taskTags.find(
                                  (t) => String(t.id) === id
                                );
                                return match ? match.name : "";
                              });

                              setAssessments((prev) => {
                                const newFiles = [
                                  ...prev[selectedTopicId].newFiles,
                                ];
                                newFiles[idx] = {
                                  ...newFiles[idx],
                                  selectedTaskTagIds: updatedIds,
                                  selectedTaskTagNames: updatedNames,
                                };
                                return {
                                  ...prev,
                                  [selectedTopicId]: {
                                    ...prev[selectedTopicId],
                                    newFiles,
                                  },
                                };
                              });
                            }}
                            noSelectionLabel="No associated tasks"
                          />
                          {selectedTaskTagNames.length > 0 && (
                            <div className="text-xs text-gray-500 mt-1">
                              {selectedTaskTagNames.join(", ")}
                            </div>
                          )}
                        </div>

                        {/* TOPIC TAGS */}
                        <div className="mt-2 sm:mt-0 sm:ml-2 w-48">
                          <label className="block text-xs font-medium text-gray-700 mb-1">
                            Topic Association
                          </label>
                          <MultiCheckDropdown
                            options={topicTagsForFiles
                              .filter((tg) => tg.id !== selectedTopicId)
                              .map((tg) => ({
                                value: String(tg.id),
                                label: tg.name,
                              }))}
                            selectedValues={selectedTopicTagIds}
                            onChange={(updatedIds) => {
                              const updatedNames = updatedIds.map((id) => {
                                const match = topicTagsForFiles.find(
                                  (tt) => String(tt.id) === id
                                );
                                return match ? match.name : "";
                              });

                              setAssessments((prev) => {
                                const newFiles = [
                                  ...prev[selectedTopicId].newFiles,
                                ];
                                newFiles[idx] = {
                                  ...newFiles[idx],
                                  selectedTopicTagIds: updatedIds,
                                  selectedTopicTagNames: updatedNames,
                                };
                                return {
                                  ...prev,
                                  [selectedTopicId]: {
                                    ...prev[selectedTopicId],
                                    newFiles,
                                  },
                                };
                              });
                            }}
                            noSelectionLabel="No associated topics"
                          />
                          {selectedTopicTagNames.length > 0 ? (
                            <div className="text-xs text-gray-500 mt-1">
                              {selectedTopicTagNames.join(", ")}
                            </div>
                          ) : (
                            <div className="text-xs text-gray-500 mt-1 italic">
                              No additional topics selected
                            </div>
                          )}
                        </div>

                        {/* Remove file */}
                        <button
                          type="button"
                          onClick={() => removeFile(selectedTopicId, fileObj)}
                          className="ml-auto mt-2 sm:mt-0 sm:ml-2 text-red-500 hover:text-red-700"
                        >
                          <Trash2 size={16} />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/* Buttons: Prepare / Review / Save */}
            <div className="flex items-center gap-4 mb-6">
              {/* Prepare Button */}
              <button
                onClick={() => handleTogglePrepare(selectedTopicId)}
                className={`relative group p-2 rounded-full font-bold w-8 h-8 flex items-center justify-center
                  ${
                    currentData.preparer
                      ? "bg-blue-100 text-blue-700 hover:bg-blue-200"
                      : "bg-gray-200 text-gray-500 hover:bg-gray-300"
                  }`}
              >
                P
                <span
                  className="absolute hidden group-hover:block bg-gray-900 text-white text-xs 
                    rounded px-2 py-1 bottom-full mb-1 left-1/2 transform -translate-x-1/2 
                    whitespace-nowrap"
                >
                  {currentData.preparer
                    ? "Click to unmark as prepared"
                    : "Mark as prepared"}
                </span>
              </button>

              {/* Review Button */}
              <button
                onClick={() => handleToggleReview(selectedTopicId)}
                className={`relative group p-2 rounded-full font-bold w-8 h-8 flex items-center justify-center
                  ${
                    currentData.reviewer
                      ? "bg-green-100 text-green-700 hover:bg-green-200"
                      : "bg-gray-200 text-gray-500 hover:bg-gray-300"
                  }`}
              >
                R
                <span
                  className="absolute hidden group-hover:block bg-gray-900 text-white text-xs 
                    rounded px-2 py-1 bottom-full mb-1 left-1/2 transform -translate-x-1/2 
                    whitespace-nowrap"
                >
                  {currentData.reviewer
                    ? "Click to unmark as reviewed"
                    : "Mark as reviewed"}
                </span>
              </button>

              {/* Save Button */}
              <button
                onClick={() => handleSaveTopic(selectedTopicId)}
                className="px-4 py-2 bg-blue-600 text-white font-medium rounded
                  hover:bg-blue-700 transition-all duration-200
                  shadow-md hover:shadow-lg focus:outline-none
                  focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Save
              </button>
            </div>

            {/* Preparer / Reviewer info */}
            {currentData.preparer && (
              <div className="mb-2 text-sm text-blue-600">
                Topic prepared by {currentData.preparer}
              </div>
            )}
            {currentData.reviewer && (
              <div className="mb-4 text-sm text-green-600">
                Topic reviewed by {currentData.reviewer}
              </div>
            )}

            {/* Summary (materiality & rationale) */}
            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
              <h4 className="text-md font-semibold mb-2 text-gray-700">
                Materiality &amp; Rationale
              </h4>
              <p className="text-sm text-gray-600 mb-1">
                <span className="font-medium">
                  Impact on People/Environment:
                </span>{" "}
                {currentData.environmentImpact || "Not selected"}
              </p>
              <p className="text-sm text-gray-600 mb-1">
                <span className="font-medium">Impact on Business Success:</span>{" "}
                {currentData.businessImpact || "Not selected"}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-medium">Rationale:</span>{" "}
                {currentData.rationale || "No rationale provided"}
              </p>
            </div>
          </>
        ) : (
          <p className="text-gray-500">
            Please select a topic from the left to view details.
          </p>
        )}
      </main>

      {/* RIGHT: Attachments panel */}
      <section className="w-1/4 bg-white p-6 border-l">
        {selectedTopic && currentData ? (
          <>
            <h3 className="text-lg font-semibold mb-2 text-gray-800">
              Attachments
            </h3>
            {currentData.serverFiles.length > 0 && (
              <div className="border rounded p-2 bg-gray-50">
                <h4 className="text-sm font-medium">Existing Attachments</h4>
                <div className="mt-2 space-y-1">
                  {currentData.serverFiles.map((att, i) => (
                    <div
                      key={i}
                      className="mb-3 p-2 bg-white rounded shadow-sm text-sm text-gray-700 flex items-center"
                    >
                      <div className="flex-1">
                        <div
                          className="truncate w-48 font-medium text-blue-800"
                          title={att.name || att.file_name || "Attachment"}
                        >
                          {att.name || att.file_name || "Attachment"}
                        </div>

                        {/* Tag pills */}
                        <div className="mt-1 flex flex-wrap gap-1">
                          {att.tags?.map((tag, idx2) => (
                            <span
                              key={idx2}
                              className="flex items-center rounded-full bg-blue-100 px-2 py-0.5 text-xs text-blue-700"
                            >
                              {tag.section}: {tag.tag_name || tag.name}
                              <button
                                className="ml-1 text-red-500 hover:text-red-700"
                                onClick={() =>
                                  removeTagFromAttachment(att, tag)
                                }
                                title="Remove this tag"
                              >
                                <X size={14} />
                              </button>
                            </span>
                          ))}
                        </div>
                      </div>

                      {/* Download */}
                      <button
                        className="ml-2 text-gray-400 hover:text-blue-600"
                        onClick={() => handleDownloadAttachment(att)}
                      >
                        <Download size={16} />
                      </button>

                      {/* Remove entire attachment */}
                      <button
                        className="ml-2 text-gray-400 hover:text-red-600"
                        onClick={() => handleOpenRemoveModal(att)}
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <p className="text-gray-500">Select a topic to view attachments</p>
        )}
      </section>

      {/* FolderSelectionModal */}
      <FolderSelectionModal
        isOpen={isFolderModalOpen}
        folders={nestedFolders}
        onClose={() => setIsFolderModalOpen(false)}
        onSelectFolder={(folderId) => {
          if (selectedTopicId) {
            handleFolderSelect(selectedTopicId, folderId);
          }
        }}
      />

      {/* Remove Attachment Modal */}
      <RemoveConfirmModal
        isOpen={isRemoveModalOpen}
        attachment={attachmentToRemove}
        onCancel={handleCloseRemoveModal}
        onConfirm={handleRemoveServerAttachment}
      />

      {/* Confirm Action Modal (Prepare / Review, on/off) */}
      <ConfirmActionModal
        isOpen={isConfirmActionOpen}
        actionType={confirmActionType}
        actionStatus={confirmActionStatus}
        onCancel={() => setIsConfirmActionOpen(false)}
        onConfirm={handleConfirmAction}
      />
    </div>
  );
}
