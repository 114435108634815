// UsersSection.jsx
import React from "react";
import { PencilAltIcon, XCircleIcon } from "@heroicons/react/outline";

const UsersSection = ({ users, handleOpenUserModal, deleteUser }) => {
  return (
    <div className="mb-16">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold text-gray-800 tracking-tight">
          Manage Users
        </h2>
        <button
          onClick={() => handleOpenUserModal()}
          className="inline-flex items-center bg-indigo-600 text-white px-4 py-2
                     rounded-md hover:bg-indigo-700 transition-colors font-semibold"
        >
          + Add User
        </button>
      </div>

      <div className="overflow-x-auto border border-gray-200 rounded-lg shadow bg-white">
        <table className="min-w-full divide-y divide-gray-200 text-sm">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left font-semibold text-gray-700 uppercase tracking-wider">
                First Name
              </th>
              <th className="px-6 py-3 text-left font-semibold text-gray-700 uppercase tracking-wider">
                Last Name
              </th>
              <th className="px-6 py-3 text-left font-semibold text-gray-700 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left font-semibold text-gray-700 uppercase tracking-wider">
                Groups
              </th>
              <th className="px-6 py-3 text-left font-semibold text-gray-700 uppercase tracking-wider">
                Levels
              </th>
              <th className="px-6 py-3 text-center font-semibold text-gray-700 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {users.map((u) => {
              // Convert arrays of objects -> array of names
              const groupNames = (u.permission_groups || [])
                .map((g) => g.name)
                .join(", ");
              const levelNames = (u.levels || []).map((l) => l.name).join(", ");

              return (
                <tr key={u.id} className="hover:bg-gray-100 transition-colors">
                  <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-800">
                    {u.firstName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-800">
                    {u.lastName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                    {u.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                    {groupNames}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                    {levelNames}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    <button
                      onClick={() => handleOpenUserModal(u)}
                      className="text-indigo-600 hover:text-indigo-800 mr-3"
                    >
                      <PencilAltIcon className="w-5 h-5 inline" />
                    </button>
                    <button
                      onClick={() => deleteUser(u.id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <XCircleIcon className="w-5 h-5 inline" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersSection;
