import React, { useState, useEffect } from "react";
import Loader from "../../../components/Loader";

// Section components
import UsersSection from "./UsersSection";
import PermissionGroupsSection from "./PermissionGroupsSection";
import UserGroupsSection from "./UserGroupsSection";

// Modal components
import UserModal from "./modals/UserModal";
import PermissionGroupModal from "./modals/PermissionGroupModal";
import UserGroupModal from "./modals/UserGroupModal";

import { XCircleIcon } from "@heroicons/react/outline";

const AdminDashboard = () => {
  // --------------------------------------------------------------------
  // State: Users, Groups, UserGroups, Levels, etc.
  // --------------------------------------------------------------------
  const [users, setUsers] = useState([]);
  const [levels, setLevels] = useState([]);
  const [groups, setGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([]);

  const [usersLoading, setUsersLoading] = useState(true);
  const [levelsLoading, setLevelsLoading] = useState(true);
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [userGroupsLoading, setUserGroupsLoading] = useState(true);

  const [permissionsTemplate, setPermissionsTemplate] = useState(null);
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  const userEmail = "cducker@miagen.com"; // Logged-in user's email
  const [currentUserGroups, setCurrentUserGroups] = useState([]);

  // --------------------------------------------------------------------
  // State: For modals & editing/creating
  // --------------------------------------------------------------------
  // ----- User Modal -----
  const [showUserModal, setShowUserModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [newUser, setNewUser] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    permission_groups: [],
    levels: [],
  });
  const [userError, setUserError] = useState("");

  // ----- Permission Group Modal -----
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [editingGroup, setEditingGroup] = useState(null);
  const [newGroup, setNewGroup] = useState({
    name: "",
    description: "",
    enabled: true,
    memberIds: [],
    permissions: [],
  });
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [groupIdToDelete, setGroupIdToDelete] = useState(null);

  // ----- User Group Modal -----
  const [showUserGroupModal, setShowUserGroupModal] = useState(false);
  const [editingUserGroup, setEditingUserGroup] = useState(null);
  const [newUserGroup, setNewUserGroup] = useState({
    id: "",
    name: "",
    description: "",
    created_by: userEmail,
    modified_by: null,
    users: [],
  });
  const [showDeleteUserGroupModal, setShowDeleteUserGroupModal] =
    useState(false);
  const [userGroupIdToDelete, setUserGroupIdToDelete] = useState(null);

  // --------------------------------------------------------------------
  // Env & Auth
  // --------------------------------------------------------------------
  const clientUrl = process.env.REACT_APP_CLIENT_URL;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const authString = `${clientId}:${clientSecret}`;
  const encodedAuth = btoa(authString);

  // --------------------------------------------------------------------
  // Fetch data on mount
  // --------------------------------------------------------------------
  useEffect(() => {
    const fetchAllData = async () => {
      await fetchLevels();
      await fetchGroups();
      await fetchUsers();
      await fetchPermissionsList();
      await fetchUserGroups();
    };
    fetchAllData();
  }, []);

  // --------------------------------------------------------------------
  // 1) Fetch Levels
  // --------------------------------------------------------------------
  const fetchLevels = async () => {
    setLevelsLoading(true);
    try {
      const response = await fetch(`${clientUrl}/ws/rest/getLevels`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify({ string: userEmail }),
      });
      if (!response.ok) {
        const err = await response.text();
        console.error("Levels Error:", err);
        throw new Error("Failed to fetch levels");
      }
      const data = await response.json();
      const levelsArray =
        Array.isArray(data) && Array.isArray(data[0]) ? data[0] : data;

      setLevels(levelsArray);
    } catch (error) {
      console.error("Error fetching levels:", error);
    } finally {
      setLevelsLoading(false);
    }
  };

  // --------------------------------------------------------------------
  // 2) Fetch Permission Groups
  // --------------------------------------------------------------------
  const fetchGroups = async () => {
    setGroupsLoading(true);
    try {
      const response = await fetch(`${clientUrl}/ws/rest/getGroups`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
      });
      if (!response.ok) {
        const err = await response.text();
        console.error("Groups Error:", err);
        throw new Error("Failed to fetch groups");
      }
      const data = await response.json();
      const transformed = data.map((group) => ({
        id: parseInt(group.id),
        name: group.name,
        description: group.description,
        enabled: group.enabled === "true",
        memberIds: group.memberIds?.map(Number) || [],
        permissions: group.permissions || [],
      }));
      setGroups(transformed);
    } catch (error) {
      console.error("Error fetching groups:", error);
    } finally {
      setGroupsLoading(false);
    }
  };

  // --------------------------------------------------------------------
  // 3) Fetch Users
  // --------------------------------------------------------------------
  const fetchUsers = async () => {
    setUsersLoading(true);
    try {
      const response = await fetch(`${clientUrl}/ws/rest/getUsers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify({ string: userEmail }),
      });
      if (!response.ok) {
        const err = await response.text();
        console.error("Users Error:", err);
        throw new Error("Failed to fetch users");
      }
      const data = await response.json();
      const usersArray =
        Array.isArray(data) && Array.isArray(data[0]) ? data[0] : data;

      const transformedUsers = usersArray.map((u) => ({
        id: parseInt(u.id),
        firstName: u.first_name,
        lastName: u.last_name,
        email: u.email,
        flag: u.flag || "true",
        permission_groups: u.permission_groups || [],
        levels: u.levels || [],
      }));

      setUsers(transformedUsers);

      // Identify which groups the logged-in user belongs to (if needed)
      const loggedInUser = transformedUsers.find((u) => u.email === userEmail);
      if (loggedInUser) {
        const justGroupIds = loggedInUser.permission_groups.map((pg) => pg.id);
        setCurrentUserGroups(justGroupIds);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setUsersLoading(false);
    }
  };

  // --------------------------------------------------------------------
  // 4) Fetch Permissions Template
  // --------------------------------------------------------------------
  const fetchPermissionsList = async () => {
    setPermissionsLoading(true);
    try {
      const response = await fetch(`${clientUrl}/ws/rest/getPermissionsList`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
      });
      if (!response.ok) {
        const err = await response.text();
        console.error("Permissions Error:", err);
        throw new Error("Failed to fetch permissions list");
      }
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0 && data[0].permissions) {
        setPermissionsTemplate(data[0].permissions);
      } else {
        setPermissionsTemplate([]);
      }
    } catch (error) {
      console.error("Error fetching permissions list:", error);
      setPermissionsTemplate([]);
    } finally {
      setPermissionsLoading(false);
    }
  };

  // --------------------------------------------------------------------
  // 5) Fetch User Groups
  // --------------------------------------------------------------------
  const fetchUserGroups = async () => {
    setUserGroupsLoading(true);
    try {
      const response = await fetch(`${clientUrl}/ws/rest/getUserGroups`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify({ string: userEmail }),
      });
      if (!response.ok) {
        const err = await response.text();
        console.error("UserGroups Error:", err);
        throw new Error("Failed to fetch user groups");
      }
      const data = await response.json();
      setUserGroups(data);
    } catch (error) {
      console.error("Error fetching user groups:", error);
    } finally {
      setUserGroupsLoading(false);
    }
  };

  // --------------------------------------------------------------------
  // USERS CRUD
  // --------------------------------------------------------------------
  const sendUserDataToBackend = async (userObj) => {
    const groupIds = userObj.permission_groups.map((pg) => String(pg.id));
    const levelIds = userObj.levels.map((lvl) => String(lvl.id));

    const payload = {
      id: userObj.id ? String(userObj.id) : undefined,
      first_name: userObj.firstName,
      last_name: userObj.lastName,
      email: userObj.email,
      permissions: [
        {
          groups: groupIds,
          levels: levelIds,
        },
      ],
      user: userEmail,
    };

    console.log("Sending user data to backend:", payload);

    const resp = await fetch(`${clientUrl}/ws/rest/addUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedAuth}`,
      },
      body: JSON.stringify(payload),
    });
    if (!resp.ok) {
      const err = await resp.text();
      throw new Error(err);
    }
    console.log("User data saved successfully!");
  };

  const deleteUserFromBackend = async (userId) => {
    const payload = { id: userId.toString(), user: userEmail };
    const resp = await fetch(`${clientUrl}/ws/rest/deleteUser`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedAuth}`,
      },
      body: JSON.stringify(payload),
    });
    if (!resp.ok) {
      const err = await resp.text();
      throw new Error(err);
    }
  };

  const handleOpenUserModal = (user = null) => {
    setUserError("");
    if (user) {
      setEditingUser(user);
    } else {
      setEditingUser(null);
      setNewUser({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        permission_groups: [],
        levels: [],
      });
    }
    setShowUserModal(true);
  };

  const handleCloseUserModal = () => {
    setEditingUser(null);
    setShowUserModal(false);
    setUserError("");
  };

  const addUser = async () => {
    if (!newUser.firstName.trim() || !newUser.lastName.trim()) {
      setUserError("First name and last name required.");
      return;
    }
    try {
      await sendUserDataToBackend(newUser);
      await fetchUsers();
      setNewUser({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        permission_groups: [],
        levels: [],
      });
      setShowUserModal(false);
    } catch (error) {
      console.error("Error adding user:", error);
      if (error.message.includes("already exists")) {
        setUserError("User already exists. Use different email.");
      } else {
        setUserError("An error occurred while adding the user.");
      }
    }
  };

  const updateUser = async () => {
    if (!editingUser.firstName.trim() || !editingUser.lastName.trim()) {
      setUserError("First name and last name required.");
      return;
    }
    try {
      await sendUserDataToBackend(editingUser);
      await fetchUsers();
      setEditingUser(null);
      setShowUserModal(false);
    } catch (error) {
      console.error("Error updating user:", error);
      if (error.message.includes("already exists")) {
        setUserError("User already exists. Use different email.");
      } else {
        setUserError("An error occurred while updating the user.");
      }
    }
  };

  const deleteUser = async (userId) => {
    try {
      await deleteUserFromBackend(userId);
      await fetchUsers();
      // Optionally remove user from group membership
      setGroups((prev) =>
        prev.map((g) => ({
          ...g,
          memberIds: g.memberIds.filter((id) => id !== userId),
        }))
      );
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  // --------------------------------------------------------------------
  // PERMISSION GROUPS CRUD
  // --------------------------------------------------------------------
  const sendGroupDataToBackend = async (grp) => {
    const preparedPermissions = grp.permissions.map((permSection) => {
      const selectedSubtasks = permSection.subtasks
        .filter((st) => st.flag === true)
        .map((st) => st.subtask);
      return {
        section: permSection.section,
        subtasks: selectedSubtasks,
      };
    });
    const payload = {
      name: grp.name,
      description: grp.description,
      permissions: preparedPermissions,
      enabled: grp.enabled,
      memberIds: grp.memberIds,
    };
    if (grp.id) payload.id = grp.id.toString();

    const resp = await fetch(`${clientUrl}/ws/rest/addGroup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedAuth}`,
      },
      body: JSON.stringify(payload),
    });
    if (!resp.ok) {
      const err = await resp.text();
      throw new Error(err);
    }
  };

  const deleteGroupFromBackend = async (groupId) => {
    const payload = { user: userEmail, id: groupId.toString() };
    const resp = await fetch(`${clientUrl}/ws/rest/deleteGroup`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedAuth}`,
      },
      body: JSON.stringify(payload),
    });
    if (!resp.ok) {
      const err = await resp.text();
      throw new Error(err);
    }
  };

  const confirmDeleteGroup = (groupId) => {
    setGroupIdToDelete(groupId);
    setShowDeleteGroupModal(true);
  };
  const handleCloseDeleteGroupModal = () => {
    setGroupIdToDelete(null);
    setShowDeleteGroupModal(false);
  };
  const handleConfirmDeleteGroup = async () => {
    if (!groupIdToDelete) return;
    try {
      await deleteGroupFromBackend(groupIdToDelete);
      await fetchGroups();
      // Also remove from any user referencing that group
      setUsers((prev) =>
        prev.map((u) => ({
          ...u,
          permission_groups: u.permission_groups.filter(
            (pg) => pg.id !== groupIdToDelete
          ),
        }))
      );
    } catch (err) {
      console.error("Error deleting group:", err);
    } finally {
      handleCloseDeleteGroupModal();
    }
  };

  const toggleGroupEnabled = async (groupId) => {
    try {
      const grp = groups.find((g) => g.id === groupId);
      if (grp) {
        const updated = { ...grp, enabled: !grp.enabled };
        await sendGroupDataToBackend(updated);
        await fetchGroups();
      }
    } catch (error) {
      console.error("Error toggling group enabled:", error);
    }
  };

  const toggleSubtaskFlag = (sectionName, subtaskName) => {
    const current = editingGroup || newGroup;
    const updatedPermissions = current.permissions.map((sec) => {
      if (sec.section === sectionName) {
        const updatedSubtasks = sec.subtasks.map((st) => {
          if (st.subtask === subtaskName) {
            return { ...st, flag: !st.flag };
          }
          return st;
        });
        return { ...sec, subtasks: updatedSubtasks };
      }
      return sec;
    });
    if (editingGroup) {
      setEditingGroup({ ...editingGroup, permissions: updatedPermissions });
    } else {
      setNewGroup({ ...newGroup, permissions: updatedPermissions });
    }
  };

  const handleOpenGroupModal = (grp = null) => {
    if (grp) {
      setEditingGroup(grp);
      setShowGroupModal(true);
    } else {
      if (permissionsTemplate && permissionsTemplate.length > 0) {
        const cloned = JSON.parse(JSON.stringify(permissionsTemplate));
        cloned.forEach((s) => {
          s.subtasks.forEach((st) => {
            st.flag = false;
          });
        });
        setNewGroup({
          name: "",
          description: "",
          enabled: true,
          memberIds: [],
          permissions: cloned,
        });
      }
      setEditingGroup(null);
      setShowGroupModal(true);
    }
  };
  const handleCloseGroupModal = () => {
    setEditingGroup(null);
    setShowGroupModal(false);
  };
  const addGroup = async () => {
    if (!newGroup.name.trim()) return;
    try {
      await sendGroupDataToBackend(newGroup);
      await fetchGroups();
      setNewGroup({
        name: "",
        description: "",
        enabled: true,
        memberIds: [],
        permissions: [],
      });
      setShowGroupModal(false);
    } catch (error) {
      console.error("Error adding group:", error);
    }
  };
  const updateGroup = async () => {
    if (!editingGroup.name.trim()) return;
    try {
      await sendGroupDataToBackend(editingGroup);
      await fetchGroups();
      setEditingGroup(null);
      setShowGroupModal(false);
    } catch (error) {
      console.error("Error updating group:", error);
    }
  };

  // --------------------------------------------------------------------
  // USER GROUPS CRUD
  // --------------------------------------------------------------------
  const sendUserGroupDataToBackend = async (ug) => {
    // Convert ug.users (array of objects) -> array of string IDs
    const userIds = ug.users.map((u) => String(u.id));
    const payload = {
      id: ug.id || "",
      name: ug.name,
      description: ug.description,
      user: userEmail,
      // Must be array of strings to avoid the "START_OBJECT" error
      users: userIds,
    };
    console.log("Sending user group data:", payload);

    const resp = await fetch(`${clientUrl}/ws/rest/addUserGroups`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedAuth}`,
      },
      body: JSON.stringify(payload),
    });
    if (!resp.ok) {
      const err = await resp.text();
      throw new Error(err);
    }
    console.log("User group data saved successfully!");
  };

  const deleteUserGroupFromBackend = async (groupId) => {
    const payload = {
      id: groupId.toString(),
      user: userEmail,
    };
    try {
      const response = await fetch(`${clientUrl}/ws/rest/deleteUserGroup`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      console.log("User group deleted successfully.");
    } catch (error) {
      console.error("Error deleting user group:", error);
      throw error;
    }
  };

  const handleOpenUserGroupModal = (ug = null) => {
    if (ug) {
      // Editing
      setEditingUserGroup(ug);
      // Clear newUserGroup so we only track in editingUserGroup
      setNewUserGroup({
        id: "",
        name: "",
        description: "",
        created_by: userEmail,
        modified_by: null,
        users: [],
      });
    } else {
      // Creating new
      setEditingUserGroup(null);
      setNewUserGroup({
        id: "",
        name: "",
        description: "",
        created_by: userEmail,
        modified_by: null,
        users: [],
      });
    }
    setShowUserGroupModal(true);
  };
  const handleCloseUserGroupModal = () => {
    setEditingUserGroup(null);
    setShowUserGroupModal(false);
  };

  // FIX #1 - addUserGroup
  const addUserGroup = async () => {
    // We build the payload from "newUserGroup", ensuring "users" is string IDs
    try {
      await sendUserGroupDataToBackend(newUserGroup);
      await fetchUserGroups();
      setShowUserGroupModal(false);
    } catch (error) {
      console.error("Error adding user group:", error);
    }
  };

  // FIX #2 - updateUserGroup
  const updateUserGroup = async () => {
    if (!editingUserGroup) return;
    if (!editingUserGroup.name.trim()) return;
    try {
      await sendUserGroupDataToBackend(editingUserGroup);
      await fetchUserGroups();
      setEditingUserGroup(null);
      setShowUserGroupModal(false);
    } catch (error) {
      console.error("Error updating user group:", error);
    }
  };

  const confirmDeleteUserGroup = (ugId) => {
    setUserGroupIdToDelete(ugId);
    setShowDeleteUserGroupModal(true);
  };
  const handleCloseDeleteUserGroupModal = () => {
    setUserGroupIdToDelete(null);
    setShowDeleteUserGroupModal(false);
  };
  const handleConfirmDeleteUserGroup = async () => {
    if (!userGroupIdToDelete) return;
    try {
      await deleteUserGroupFromBackend(userGroupIdToDelete);
      await fetchUserGroups();
    } catch (error) {
      console.error("Error deleting user group:", error);
    } finally {
      handleCloseDeleteUserGroupModal();
    }
  };

  // Toggling a user in a user group
  const toggleUserInUserGroup = (usr) => {
    if (editingUserGroup) {
      const existing = editingUserGroup.users.find((u) => u.id === usr.id);
      let updated;
      if (existing) {
        updated = editingUserGroup.users.filter((u) => u.id !== usr.id);
      } else {
        updated = [
          ...editingUserGroup.users,
          { id: usr.id, email: usr.email, flag: false },
        ];
      }
      setEditingUserGroup({ ...editingUserGroup, users: updated });
    } else {
      const existing = newUserGroup.users.find((u) => u.id === usr.id);
      let updated;
      if (existing) {
        updated = newUserGroup.users.filter((u) => u.id !== usr.id);
      } else {
        updated = [
          ...newUserGroup.users,
          { id: usr.id, email: usr.email, flag: false },
        ];
      }
      setNewUserGroup({ ...newUserGroup, users: updated });
    }
  };

  // --------------------------------------------------------------------
  // TABS or NAV MENU
  // --------------------------------------------------------------------
  const [activeSection, setActiveSection] = useState("users");

  const renderNavTabs = () => {
    const tabs = [
      { id: "users", label: "Manage Users" },
      { id: "permissionGroups", label: "Manage Permission Sets" },
      { id: "userGroups", label: "Manage User Groups" },
    ];
    return (
      <div className="mb-8 border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => {
            const isActive = activeSection === tab.id;
            return (
              <button
                key={tab.id}
                onClick={() => setActiveSection(tab.id)}
                className={
                  isActive
                    ? "text-blue-600 border-blue-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                    : "text-gray-500 hover:text-gray-700 whitespace-nowrap py-4 px-1 border-b-2 border-transparent font-medium text-sm"
                }
                aria-current={isActive ? "page" : undefined}
              >
                {tab.label}
              </button>
            );
          })}
        </nav>
      </div>
    );
  };

  // --------------------------------------------------------------------
  // Rendering
  // --------------------------------------------------------------------
  const loading =
    usersLoading ||
    groupsLoading ||
    levelsLoading ||
    permissionsLoading ||
    userGroupsLoading;

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="p-6 max-w-7xl mx-auto">
        {/* Page Title */}
        <h1 className="text-3xl sm:text-4xl font-extrabold mb-8 text-gray-800 tracking-tight">
          Admin Dashboard
        </h1>

        {/* Render the tab navigation */}
        {renderNavTabs()}

        {/* Conditionally render the sections */}
        {activeSection === "users" && (
          <UsersSection
            users={users}
            handleOpenUserModal={handleOpenUserModal}
            deleteUser={deleteUser}
          />
        )}

        {activeSection === "permissionGroups" && (
          <PermissionGroupsSection
            groups={groups}
            toggleGroupEnabled={toggleGroupEnabled}
            handleOpenGroupModal={handleOpenGroupModal}
            confirmDeleteGroup={confirmDeleteGroup}
          />
        )}

        {activeSection === "userGroups" && (
          <UserGroupsSection
            userGroups={userGroups}
            handleOpenUserGroupModal={handleOpenUserGroupModal}
            confirmDeleteUserGroup={confirmDeleteUserGroup}
          />
        )}

        {/* ============ USER MODAL ============ */}
        <UserModal
          show={showUserModal}
          onClose={handleCloseUserModal}
          editingUser={editingUser}
          setEditingUser={setEditingUser}
          newUser={newUser}
          setNewUser={setNewUser}
          userError={userError}
          addUser={addUser}
          updateUser={updateUser}
          allGroups={groups}
          allLevels={levels}
        />

        {/* ============ PERMISSION GROUP MODAL ============ */}
        <PermissionGroupModal
          show={showGroupModal}
          onClose={handleCloseGroupModal}
          editingGroup={editingGroup}
          setEditingGroup={setEditingGroup}
          newGroup={newGroup}
          setNewGroup={setNewGroup}
          addGroup={addGroup}
          updateGroup={updateGroup}
          toggleSubtaskFlag={toggleSubtaskFlag}
        />

        {/* ====== DELETE PERMISSION GROUP CONFIRMATION ====== */}
        {showDeleteGroupModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div className="fixed inset-0 bg-gray-500 opacity-50"></div>
              <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-md w-full">
                <div className="p-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Confirm Deletion
                  </h3>
                  <p className="text-gray-700 mb-6">
                    Are you sure you want to delete this group? This cannot be
                    undone.
                  </p>
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={handleCloseDeleteGroupModal}
                      className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleConfirmDeleteGroup}
                      className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* ============ USER GROUP MODAL ============ */}
        <UserGroupModal
          show={showUserGroupModal}
          onClose={handleCloseUserGroupModal}
          editingUserGroup={editingUserGroup}
          setEditingUserGroup={setEditingUserGroup}
          newUserGroup={newUserGroup}
          setNewUserGroup={setNewUserGroup}
          addUserGroup={addUserGroup}
          updateUserGroup={updateUserGroup}
          toggleUserInUserGroup={toggleUserInUserGroup}
          users={users}
        />

        {/* ====== DELETE USER GROUP CONFIRMATION ====== */}
        {showDeleteUserGroupModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div className="fixed inset-0 bg-gray-500 opacity-50"></div>
              <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-md w-full">
                <div className="p-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Confirm Deletion
                  </h3>
                  <p className="text-gray-700 mb-6">
                    Are you sure you want to delete this user group? This cannot
                    be undone.
                  </p>
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={handleCloseDeleteUserGroupModal}
                      className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleConfirmDeleteUserGroup}
                      className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
