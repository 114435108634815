// UserModal.jsx
import React from "react";
import { XCircleIcon } from "@heroicons/react/outline";

const UserModal = ({
  show,
  onClose,
  editingUser,
  setEditingUser,
  newUser,
  setNewUser,
  userError,
  addUser,
  updateUser,
  // We'll pass in the full lists of possible groups/levels
  allGroups = [],
  allLevels = [],
}) => {
  if (!show) return null;

  const isEditing = !!editingUser;
  const current = isEditing ? editingUser : newUser;

  // Generic text input handler
  const handleChange = (field, value) => {
    if (isEditing) {
      setEditingUser({ ...editingUser, [field]: value });
    } else {
      setNewUser({ ...newUser, [field]: value });
    }
  };

  // Toggle a group object in current.permission_groups
  const toggleGroup = (grp) => {
    const existing = current.permission_groups.find((g) => g.id === grp.id);
    let updatedGroups;
    if (existing) {
      // remove it
      updatedGroups = current.permission_groups.filter((g) => g.id !== grp.id);
    } else {
      // add it
      updatedGroups = [
        ...current.permission_groups,
        { id: grp.id, name: grp.name },
      ];
    }
    if (isEditing) {
      setEditingUser({ ...editingUser, permission_groups: updatedGroups });
    } else {
      setNewUser({ ...newUser, permission_groups: updatedGroups });
    }
  };

  // Toggle a level object in current.levels
  const toggleLevel = (lvl) => {
    const existing = current.levels.find((l) => l.id === lvl.id);
    let updatedLevels;
    if (existing) {
      updatedLevels = current.levels.filter((l) => l.id !== lvl.id);
    } else {
      updatedLevels = [...current.levels, { id: lvl.id, name: lvl.name }];
    }
    if (isEditing) {
      setEditingUser({ ...editingUser, levels: updatedLevels });
    } else {
      setNewUser({ ...newUser, levels: updatedLevels });
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        {/* Backdrop */}
        <div
          className="fixed inset-0 bg-gray-500 opacity-50"
          onClick={onClose}
        ></div>

        {/* Modal content */}
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-2xl w-full relative">
          {/* Header */}
          <div className="bg-white px-6 py-4 border-b border-gray-200 flex items-center justify-between">
            <h3 className="text-xl font-medium text-gray-900">
              {isEditing ? "Edit User" : "Add New User"}
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <XCircleIcon className="w-6 h-6" />
            </button>
          </div>

          {/* Body */}
          <div className="px-6 py-4">
            {userError && (
              <div className="mb-4 text-red-600 font-medium">{userError}</div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Left: Basic Info */}
              <div className="space-y-4">
                {/* First Name */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <input
                    type="text"
                    value={current.firstName}
                    onChange={(e) => handleChange("firstName", e.target.value)}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm 
                               focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                {/* Last Name */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <input
                    type="text"
                    value={current.lastName}
                    onChange={(e) => handleChange("lastName", e.target.value)}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm 
                               focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                {/* Email */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    value={current.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm 
                               focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>

              {/* Right: Groups & Levels */}
              <div className="space-y-4">
                {/* Groups */}
                <div className="border rounded-md p-4 max-h-48 overflow-auto">
                  <h4 className="text-sm font-medium text-gray-700 mb-2">
                    Assign to Groups
                  </h4>
                  <div className="space-y-2">
                    {allGroups.map((grp) => {
                      const isAssigned = !!current.permission_groups.find(
                        (g) => g.id === grp.id
                      );
                      return (
                        <label
                          key={grp.id}
                          className="flex items-center space-x-2"
                        >
                          <input
                            type="checkbox"
                            checked={isAssigned}
                            onChange={() => toggleGroup(grp)}
                            className="form-checkbox h-4 w-4 text-indigo-600"
                          />
                          <span className="text-gray-700 text-sm">
                            {grp.name}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                </div>

                {/* Levels */}
                <div className="border rounded-md p-4 max-h-48 overflow-auto">
                  <h4 className="text-sm font-medium text-gray-700 mb-2">
                    Assign Levels
                  </h4>
                  <div className="space-y-2">
                    {allLevels.map((lvl) => {
                      const isAssigned = !!current.levels.find(
                        (l) => l.id === lvl.id
                      );
                      return (
                        <label
                          key={lvl.id}
                          className="flex items-center space-x-2"
                        >
                          <input
                            type="checkbox"
                            checked={isAssigned}
                            onChange={() => toggleLevel(lvl)}
                            className="form-checkbox h-4 w-4 text-green-600"
                          />
                          <span className="text-gray-700 text-sm">
                            {lvl.name}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="bg-gray-50 px-6 py-3 flex justify-end border-t border-gray-200">
            <button
              onClick={onClose}
              className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition mr-2"
            >
              Cancel
            </button>
            <button
              onClick={isEditing ? updateUser : addUser}
              className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition"
            >
              {isEditing ? "Update User" : "Add User"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
