// src/components/ProjectPlan/FolderNode.jsx

import React from "react";
import {
  ChevronRight,
  ChevronDown as ChevronDownIcon,
  Folder as FolderIcon,
} from "lucide-react";

/**
 * Renders a single folder node in a nested folder tree.
 */
function FolderNode({
  folder,
  level,
  expandedFolders,
  setExpandedFolders,
  onSelectFolder,
}) {
  const hasChildren =
    Array.isArray(folder.children) && folder.children.length > 0;
  const isExpanded = expandedFolders.has(folder.id);

  const toggleExpand = (e) => {
    e.stopPropagation();
    const next = new Set(expandedFolders);
    if (next.has(folder.id)) {
      next.delete(folder.id);
    } else {
      next.add(folder.id);
    }
    setExpandedFolders(next);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    onSelectFolder(folder.id);
  };

  return (
    <div style={{ paddingLeft: `${level * 1.2}rem` }} className="mb-1">
      <div
        className="flex items-center p-2 rounded hover:bg-gray-50 cursor-pointer"
        onClick={handleClick}
      >
        {hasChildren ? (
          <button
            onClick={toggleExpand}
            className="mr-1 text-gray-500 hover:text-gray-700"
          >
            {isExpanded ? (
              <ChevronDownIcon size={16} />
            ) : (
              <ChevronRight size={16} />
            )}
          </button>
        ) : null}
        <FolderIcon className="text-yellow-500" size={16} />
        <span className="ml-2 text-sm font-medium text-gray-800">
          {folder.name}
        </span>
      </div>
      {isExpanded && hasChildren && (
        <div className="mt-1">
          {folder.children.map((child) => (
            <FolderNode
              key={child.id}
              folder={child}
              level={level + 1}
              expandedFolders={expandedFolders}
              setExpandedFolders={setExpandedFolders}
              onSelectFolder={onSelectFolder}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default FolderNode;
