// src/components/ProjectPlan/FolderSelectionModal.jsx

import React, { useState } from "react";
import { X } from "lucide-react";
import FolderNode from "./FolderNode";

/**
 * Modal that displays a nested folder tree so the user can select a folder.
 */
function FolderSelectionModal({
  isOpen,
  folders = [],
  onClose,
  onSelectFolder,
}) {
  const [expandedFolders, setExpandedFolders] = useState(new Set());

  if (!isOpen) return null;

  const handleSelectFolder = (folderId) => {
    onSelectFolder(folderId);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="relative w-full max-w-md rounded-md bg-white shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-700"
        >
          <X size={20} />
        </button>
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800">
            Select a Folder
          </h2>
          <p className="text-sm text-gray-500">
            Choose a folder for file attachments
          </p>
        </div>
        <div className="max-h-80 overflow-auto p-4">
          {folders.length === 0 ? (
            <p className="text-gray-500 text-center">No folders found.</p>
          ) : (
            folders.map((topFolder) => (
              <FolderNode
                key={topFolder.id}
                folder={topFolder}
                level={0}
                expandedFolders={expandedFolders}
                setExpandedFolders={setExpandedFolders}
                onSelectFolder={handleSelectFolder}
              />
            ))
          )}
        </div>
        <div className="flex justify-end gap-2 border-t border-gray-200 p-4">
          <button
            onClick={onClose}
            className="inline-flex items-center rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default FolderSelectionModal;
