// UserGroupsSection.jsx

import React, { useState } from "react";
import { UserGroupIcon } from "@heroicons/react/outline"; // If you want icons

function UserGroupsSection({
  userGroups,
  handleOpenUserGroupModal,
  confirmDeleteUserGroup,
}) {
  // State to keep track of which group is currently selected
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  // Retrieve the details of the currently selected group
  const selectedGroup = userGroups.find((g) => g.id === selectedGroupId);

  return (
    <div className="my-12">
      <h2 className="text-2xl font-extrabold text-gray-800 mb-6 tracking-tight">
        User Groups
      </h2>

      <div className="flex space-x-6">
        {/* ========== Left Sidebar ========== */}
        <div className="w-1/4 bg-white rounded-xl shadow-md overflow-hidden flex flex-col">
          <div className="p-4 border-b border-gray-200">
            <h3 className="text-lg font-semibold text-gray-700 flex items-center">
              <UserGroupIcon className="h-5 w-5 mr-2 text-gray-400" />
              All Groups
            </h3>
          </div>

          <div className="flex-1 overflow-auto">
            <ul className="divide-y divide-gray-200">
              {userGroups.map((group) => {
                const isSelected = selectedGroupId === group.id;
                return (
                  <li
                    key={group.id}
                    onClick={() => setSelectedGroupId(group.id)}
                    className={`p-4 cursor-pointer transition-colors 
                      ${isSelected ? "bg-blue-50" : "hover:bg-gray-50"}`}
                  >
                    <div className="font-medium text-gray-800">
                      {group.name}
                    </div>
                    <p
                      className={`text-sm ${
                        isSelected ? "text-blue-600" : "text-gray-500"
                      } truncate`}
                    >
                      {group.description}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="p-4 border-t border-gray-200">
            <button
              onClick={() => handleOpenUserGroupModal()}
              className="w-full inline-flex justify-center items-center bg-green-600 text-white px-4 py-2 
              rounded-md hover:bg-green-700 transition-colors font-semibold"
            >
              + Add New Group
            </button>
          </div>
        </div>

        {/* ========== Right Panel ========== */}
        <div className="w-3/4">
          {selectedGroup ? (
            <div className="bg-white rounded-xl shadow-md p-6 h-full flex flex-col">
              {/* Header Row */}
              <div className="flex items-center justify-between">
                <h3 className="text-2xl font-bold text-gray-700">
                  {selectedGroup.name}
                </h3>
                <div className="space-x-2">
                  <button
                    onClick={() => handleOpenUserGroupModal(selectedGroup)}
                    className="bg-blue-600 text-white px-4 py-2 
                    rounded-md hover:bg-blue-700 transition-colors"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => confirmDeleteUserGroup(selectedGroup.id)}
                    className="bg-red-600 text-white px-4 py-2 
                    rounded-md hover:bg-red-700 transition-colors"
                  >
                    Delete
                  </button>
                </div>
              </div>

              {/* Description */}
              <p className="mt-3 mb-5 text-gray-600">
                {selectedGroup.description}
              </p>

              <hr className="my-2" />

              {/* Members */}
              <h4 className="text-lg font-semibold text-gray-700 mt-4">
                Members
              </h4>
              {selectedGroup.users && selectedGroup.users.length > 0 ? (
                <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mt-4">
                  {selectedGroup.users.map((user) => (
                    <li
                      key={user.id}
                      className="flex items-center p-3 bg-gray-50 rounded-md shadow-sm"
                    >
                      <div className="flex-1">
                        <span className="block text-gray-800 font-medium">
                          {user.email}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500 mt-2">No members assigned.</p>
              )}
            </div>
          ) : (
            <div className="bg-white rounded-xl shadow-md p-6 h-full flex items-center justify-center text-gray-500 italic">
              Select a group on the left to see details.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserGroupsSection;
