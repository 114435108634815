import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import {
  ChevronRightIcon,
  ChevronDownIcon,
  PlusCircleIcon,
} from "@heroicons/react/solid";

// Build a hierarchy (tree) from flat data
function buildTree(data) {
  const byId = {};

  data.forEach((item) => {
    byId[item.id] = { ...item, children: [] };
  });

  let root = null;

  for (let id in byId) {
    const node = byId[id];
    if (node.rolls_up_to) {
      const parentId = parseInt(node.rolls_up_to, 10);
      if (!isNaN(parentId) && byId[parentId]) {
        byId[parentId].children.push(node);
      } else {
        console.warn(
          `Node "${node.name}" (id: ${node.id}) rolls up to "${node.rolls_up_to}", but no parent found with that id.`
        );
      }
    } else {
      if (root) {
        console.warn("Multiple potential root nodes found. Check data.");
      }
      root = node;
    }
  }

  return root;
}

const TreeNode = ({ node, selectedNode, setSelectedNode }) => {
  const [open, setOpen] = useState(true);
  const hasChildren = node.children && node.children.length > 0;
  const isSelected = selectedNode && selectedNode.id === node.id;

  return (
    <div className="ml-3">
      <div
        onClick={() => setSelectedNode(node)}
        className={`flex items-center px-2 py-1 rounded-lg cursor-pointer transition-colors
          ${
            isSelected
              ? "bg-green-100 text-green-900 font-medium"
              : "hover:bg-gray-100 text-gray-800"
          }
        `}
      >
        {hasChildren ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
            className="mr-1 focus:outline-none text-gray-500"
          >
            {open ? (
              <ChevronDownIcon className="w-4 h-4" />
            ) : (
              <ChevronRightIcon className="w-4 h-4" />
            )}
          </button>
        ) : (
          <span className="w-4 h-4 mr-1 inline-block" />
        )}
        {node.name}
      </div>

      {hasChildren && open && (
        <div className="ml-4 border-l border-gray-200 pl-3">
          {node.children.map((child) => (
            <TreeNode
              key={child.id}
              node={child}
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Modal = ({ open, onClose, title, children }) => {
  if (!open) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div className="bg-white w-full max-w-md rounded-lg shadow-lg p-6 relative">
        <button
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 text-2xl leading-none"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">{title}</h2>
        {children}
      </div>
    </div>
  );
};

const LevelsTree = () => {
  const { user, isAuthenticated } = useAuth0();

  // For the hierarchy
  const [root, setRoot] = useState(null);
  const [allLevels, setAllLevels] = useState([]);
  const [idToNameMap, setIdToNameMap] = useState({});
  const [selectedNode, setSelectedNode] = useState(null);

  const [levelsLoading, setLevelsLoading] = useState(true);
  const [levelsError, setLevelsError] = useState(null);

  // For attribute values (checkboxes)
  const [allAttributeValues, setAllAttributeValues] = useState([]);
  const [selectedValueIds, setSelectedValueIds] = useState([]); // which checkboxes are chosen

  // Modal
  const [showAddLevelModal, setShowAddLevelModal] = useState(false);
  const [newLevelName, setNewLevelName] = useState("");
  const [newParentId, setNewParentId] = useState("");

  // -----------------------
  // Fetch levels
  // -----------------------
  useEffect(() => {
    if (!isAuthenticated || !user?.email) {
      console.warn("User not authenticated or no email available.");
      setLevelsLoading(false);
      setLevelsError("User is not authenticated or no email is available.");
      return;
    }

    const fetchLevels = async () => {
      setLevelsLoading(true);
      setLevelsError(null);

      try {
        const clientUrl = process.env.REACT_APP_CLIENT_URL;
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

        const authString = `${clientId}:${clientSecret}`;
        const encodedAuth = btoa(authString);

        console.log("Fetching levels from API...");
        const response = await fetch(`${clientUrl}/ws/rest/getLevels`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
          body: JSON.stringify({ string: user.email }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Levels fetch failed:", errorText);
          throw new Error(`Failed to fetch levels: ${errorText}`);
        }

        const data = await response.json();
        console.log("Raw getLevels data:", data);

        let levelsArray;
        if (Array.isArray(data)) {
          if (Array.isArray(data[0])) {
            levelsArray = data[0];
          } else {
            levelsArray = data;
          }
        } else {
          console.warn("Data returned from API is not an array.", data);
          levelsArray = [];
        }

        console.log("Parsed levelsArray:", levelsArray);

        if (levelsArray.length === 0) {
          console.warn("No levels returned or invalid structure.");
          setLevelsError("No levels returned from API.");
          setLevelsLoading(false);
          return;
        }

        const treeRoot = buildTree(levelsArray);
        console.log("Constructed tree root:", treeRoot);

        // Build ID->Name map
        const mapObj = {};
        levelsArray.forEach((lvl) => {
          mapObj[lvl.id] = lvl.name;
        });

        setIdToNameMap(mapObj);
        setAllLevels(levelsArray);

        if (!treeRoot) {
          console.warn("No valid root level found. Check rolls_up_to fields.");
          setLevelsError("No valid root level found. Check data structure.");
          setLevelsLoading(false);
          return;
        }

        setRoot(treeRoot);
        setSelectedNode(treeRoot);
      } catch (err) {
        console.error("Error:", err);
        setLevelsError(err.message);
      } finally {
        setLevelsLoading(false);
      }
    };

    fetchLevels();
  }, [isAuthenticated, user]);

  // -----------------------
  // Fetch attribute values
  // -----------------------
  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const clientUrl = process.env.REACT_APP_CLIENT_URL;
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

        const authString = `${clientId}:${clientSecret}`;
        const encodedAuth = btoa(authString);

        // Call getLevelAttributes with a hard-coded or user email
        const response = await fetch(
          `${clientUrl}/ws/rest/getLevelAttributes`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${encodedAuth}`,
            },
            body: JSON.stringify({ string: "cducker@miagen.com" }),
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`getLevelAttributes failed: ${errorText}`);
        }

        const attrData = await response.json();
        console.log("Raw getLevelAttributes response:", attrData);

        const parsed =
          Array.isArray(attrData) && Array.isArray(attrData[0])
            ? attrData[0]
            : attrData;

        const flattened = [];
        parsed.forEach((attributeObj) => {
          if (attributeObj.values) {
            attributeObj.values.forEach((val) => {
              flattened.push({
                attribute_id: attributeObj.attribute_id,
                attribute_name: attributeObj.attribute_name,
                value_id: String(val.value_id),
                value: val.value,
              });
            });
          }
        });

        console.log("Flattened attribute values:", flattened);
        setAllAttributeValues(flattened);
      } catch (err) {
        console.error("Error fetching attribute values:", err);
      }
    };

    fetchAttributes();
  }, []);

  // -----------------------
  // Refresh Levels
  // -----------------------
  const refreshLevels = async () => {
    if (!isAuthenticated || !user?.email) return;
    setLevelsLoading(true);
    setLevelsError(null);

    try {
      const clientUrl = process.env.REACT_APP_CLIENT_URL;
      const clientId = process.env.REACT_APP_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

      const authString = `${clientId}:${clientSecret}`;
      const encodedAuth = btoa(authString);

      console.log("Refreshing levels...");
      const response = await fetch(`${clientUrl}/ws/rest/getLevels`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify({ string: user.email }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch levels: ${errorText}`);
      }

      const data = await response.json();
      console.log("Raw getLevels data (refresh):", data);

      let levelsArray;
      if (Array.isArray(data)) {
        if (Array.isArray(data[0])) {
          levelsArray = data[0];
        } else {
          levelsArray = data;
        }
      } else {
        console.warn("Data returned from API is not an array.", data);
        levelsArray = [];
      }

      const treeRoot = buildTree(levelsArray);
      console.log("Constructed tree root (refresh):", treeRoot);

      // Rebuild ID->Name map
      const mapObj = {};
      levelsArray.forEach((lvl) => {
        mapObj[lvl.id] = lvl.name;
      });
      setIdToNameMap(mapObj);

      setAllLevels(levelsArray);
      setRoot(treeRoot);
      setSelectedNode(treeRoot);
    } catch (err) {
      console.error("Error:", err);
      setLevelsError(err.message);
    } finally {
      setLevelsLoading(false);
    }
  };

  // -----------------------
  // Add new Level
  // -----------------------
  const handleAddLevel = async (e) => {
    e.preventDefault();
    if (!newLevelName.trim() || !newParentId) {
      alert("Please enter a level name and select a parent level.");
      return;
    }

    // We'll pass in any selected attribute value IDs
    const payload = {
      name: newLevelName.trim(),
      rolls_up_to: newParentId,
      user: "cducker@miagen.com",
      attribute_values: selectedValueIds,
    };

    try {
      const clientUrl = process.env.REACT_APP_CLIENT_URL;
      const clientId = process.env.REACT_APP_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

      const authString = `${clientId}:${clientSecret}`;
      const encodedAuth = btoa(authString);

      console.log("Adding new level with payload:", payload);
      const response = await fetch(`${clientUrl}/ws/rest/addLevel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to add level:", errorText);
        throw new Error(`Failed to add level: ${errorText}`);
      }

      // Reset
      setShowAddLevelModal(false);
      setNewLevelName("");
      setNewParentId("");
      setSelectedValueIds([]);

      await refreshLevels();
    } catch (err) {
      console.error(err);
      alert("Error adding level: " + err.message);
    }
  };

  // -----------------------
  // Delete Level
  // -----------------------
  const handleDeleteLevel = async () => {
    if (!selectedNode) return;

    const confirmed = window.confirm(
      `Are you sure you want to delete "${selectedNode.name}"?`
    );
    if (!confirmed) return;

    try {
      const clientUrl = process.env.REACT_APP_CLIENT_URL;
      const clientId = process.env.REACT_APP_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

      const authString = `${clientId}:${clientSecret}`;
      const encodedAuth = btoa(authString);

      const payload = {
        user: "cducker@miagen.com",
        id: String(selectedNode.id),
      };

      console.log("Deleting level with payload:", payload);

      const response = await fetch(`${clientUrl}/ws/rest/deleteLevel`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to delete level:", errorText);
        throw new Error(`Failed to delete level: ${errorText}`);
      }

      await refreshLevels();
    } catch (err) {
      console.error(err);
      alert("Error deleting level: " + err.message);
    }
  };

  // For the detail panel, figure out what name the parent has
  let parentName = "—";
  if (selectedNode && selectedNode.rolls_up_to) {
    const pid = parseInt(selectedNode.rolls_up_to, 10);
    if (!isNaN(pid) && idToNameMap[pid]) {
      parentName = idToNameMap[pid];
    }
  }

  // Handle check/uncheck for attribute values in the "Add Level" modal
  const handleCheckboxChange = (valueId) => {
    setSelectedValueIds((prev) => {
      if (prev.includes(valueId)) {
        return prev.filter((v) => v !== valueId);
      } else {
        return [...prev, valueId];
      }
    });
  };

  // -----------------------
  // Rendering
  // -----------------------
  if (levelsLoading) {
    return <Loader />;
  }

  if (levelsError) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <p className="text-red-600">Error: {levelsError}</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      {/* HEADER */}
      <header className="bg-white text-gray-800 px-8 py-4 border-b border-gray-200 shadow-sm">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Levels Management</h1>
          <div className="flex items-center space-x-3">
            <button
              onClick={() => {
                setShowAddLevelModal(true);
              }}
              className="inline-flex items-center space-x-1 bg-green-600 text-white hover:bg-green-700 px-3 py-2 rounded-md text-sm font-medium shadow transition-colors"
            >
              <PlusCircleIcon className="w-5 h-5" />
              <span>Add New Level</span>
            </button>
            {selectedNode && selectedNode.id && (
              <button
                onClick={handleDeleteLevel}
                className="bg-red-600 hover:bg-red-700 text-white px-3 py-2 rounded-md text-sm font-medium shadow transition-colors"
              >
                Delete Selected
              </button>
            )}
          </div>
        </div>
      </header>

      <div className="flex flex-1 overflow-hidden">
        {/* SIDEBAR */}
        <aside className="w-80 bg-white p-4 border-r border-gray-200 overflow-auto">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">
            Hierarchy
          </h2>
          {root ? (
            <TreeNode
              node={root}
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
            />
          ) : (
            <p className="text-gray-500">No hierarchy to display</p>
          )}
        </aside>

        {/* MAIN CONTENT */}
        <main className="flex-1 p-6 overflow-auto">
          {selectedNode ? (
            <div className="bg-white rounded-lg shadow p-6">
              <h1 className="text-xl font-semibold text-gray-800 mb-3">
                {selectedNode.name}
              </h1>
              <p className="text-sm text-gray-600 mb-4">
                <strong>Rolls Up To:</strong> {parentName}
              </p>
              <div>
                <h2 className="text-md font-medium text-gray-700 mb-2">
                  Attributes
                </h2>
                {selectedNode.attributes &&
                selectedNode.attributes.length > 0 ? (
                  <ul className="list-disc list-inside space-y-1">
                    {selectedNode.attributes.map((attr, idx) => (
                      <li key={idx}>
                        <span className="font-medium">
                          {attr.attribute_name}:
                        </span>{" "}
                        {attr.attribute_value}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-500">No attributes available.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-full text-gray-500">
              Select a level to view details
            </div>
          )}
        </main>
      </div>

      {/* ADD LEVEL MODAL */}
      <Modal
        open={showAddLevelModal}
        onClose={() => {
          setShowAddLevelModal(false);
          setNewLevelName("");
          setNewParentId("");
          setSelectedValueIds([]);
        }}
        title="Add New Level"
      >
        <form onSubmit={handleAddLevel} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Level Name
            </label>
            <input
              type="text"
              value={newLevelName}
              onChange={(e) => setNewLevelName(e.target.value)}
              className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-200"
              placeholder="e.g. New Branch"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Rolls Up To
            </label>
            <select
              className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-200"
              value={newParentId}
              onChange={(e) => setNewParentId(e.target.value)}
              required
            >
              <option value="" disabled>
                -- Select Parent Level --
              </option>
              {allLevels.map((lvl) => (
                <option key={lvl.id} value={lvl.id}>
                  {lvl.name}
                </option>
              ))}
            </select>
          </div>

          {/* CHECKBOXES FOR ATTR VALUES */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Select Attribute Values
            </label>
            <div className="max-h-48 overflow-auto border rounded p-2 space-y-2">
              {allAttributeValues.length === 0 ? (
                <p className="text-gray-500">No attribute values found.</p>
              ) : (
                allAttributeValues.map((valObj) => (
                  <div key={valObj.value_id} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`attrVal_${valObj.value_id}`}
                      className="mr-2"
                      checked={selectedValueIds.includes(valObj.value_id)}
                      onChange={() => handleCheckboxChange(valObj.value_id)}
                    />
                    <label htmlFor={`attrVal_${valObj.value_id}`}>
                      {valObj.value}{" "}
                      <span className="text-xs text-gray-500">
                        ({valObj.attribute_name})
                      </span>
                    </label>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="flex justify-end space-x-3 pt-2">
            <button
              type="button"
              onClick={() => {
                setShowAddLevelModal(false);
                setNewLevelName("");
                setNewParentId("");
                setSelectedValueIds([]);
              }}
              className="px-4 py-2 bg-gray-200 rounded-md text-gray-700 hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded-md transition-colors"
            >
              Add Level
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default LevelsTree;
