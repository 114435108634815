import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  HomeIcon,
  ClipboardListIcon,
  MenuAlt2Icon,
  BookOpenIcon,
  FolderIcon,
  // DocumentReportIcon,
  // DocumentTextIcon,
  TrendingUpIcon,
  CollectionIcon,
  DocumentSearchIcon,
} from "@heroicons/react/outline";
import LogoutButton from "./LogoutButton";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="flex bg-white">
      {/* Sidebar */}
      <div
        className={`${
          isOpen ? "w-64" : "w-20"
        } bg-white fixed top-0 left-0 h-screen p-5 pt-8 duration-300 shadow-lg flex flex-col`}
      >
        {/* Header Section (Logo + Toggle) */}
        <div className="flex items-center justify-between mb-6">
          {/* Logo */}
          <NavLink to="/">
            <img
              src="https://res.cloudinary.com/djjxmi9xc/image/upload/v1729087878/GreenGen_Logo_356_SOLO_xdbfwh.png"
              alt="GreenGen Logo"
              className={`cursor-pointer transition-all duration-500 ${
                isOpen ? "w-28" : "w-10"
              }`}
            />
          </NavLink>

          {/* Toggle Button */}
          <button
            className="text-green-700 hover:bg-green-100 p-2 rounded-full focus:outline-none transition-transform duration-300"
            onClick={() => setIsOpen(!isOpen)}
          >
            <MenuAlt2Icon className="w-5 h-5" />
          </button>
        </div>

        {/* Navigation */}
        <nav className="space-y-2 flex-1">
          {/* Admin Dashboard */}
          <NavLink
            to="/admin-dashboard"
            className={({ isActive }) =>
              `group relative flex items-center p-2 rounded-md text-gray-700 hover:bg-green-100 transition-colors duration-300 ${
                isActive ? "bg-green-100 text-green-700" : ""
              }`
            }
          >
            <HomeIcon className="w-6 h-6" />
            {isOpen && (
              <span className="text-base font-medium ml-3">
                Admin Dashboard
              </span>
            )}
            {/* Tooltip if collapsed */}
            {!isOpen && (
              <span
                className="absolute left-full ml-2 hidden group-hover:block 
                  px-2 py-1 text-xs text-white bg-gray-700 rounded shadow-lg whitespace-nowrap"
              >
                Admin Dashboard
              </span>
            )}
          </NavLink>

          {/* ================== COMMENTED OUT: Kickoff Survey ===================
          <NavLink
            to="/pre-kick-off-survey"
            className={({ isActive }) =>
              `group relative flex items-center p-2 rounded-md text-gray-700 hover:bg-green-100 transition-colors duration-300 ${
                isActive ? "bg-green-100 text-green-700" : ""
              }`
            }
          >
            <DocumentTextIcon className="w-6 h-6" />
            {isOpen && (
              <span className="text-base font-medium ml-3">Kickoff Survey</span>
            )}
            {!isOpen && (
              <span
                className="absolute left-full ml-2 hidden group-hover:block 
                  px-2 py-1 text-xs text-white bg-gray-700 rounded shadow-lg whitespace-nowrap"
              >
                Kickoff Survey
              </span>
            )}
          </NavLink>
          ================== END COMMENTED OUT =================== */}

          {/* Project Plan */}
          <NavLink
            to="/project-plan"
            className={({ isActive }) =>
              `group relative flex items-center p-2 rounded-md text-gray-700 hover:bg-green-100 transition-colors duration-300 ${
                isActive ? "bg-green-100 text-green-700" : ""
              }`
            }
          >
            <ClipboardListIcon className="w-6 h-6" />
            {isOpen && (
              <span className="text-base font-medium ml-3">Project Plan</span>
            )}
            {!isOpen && (
              <span
                className="absolute left-full ml-2 hidden group-hover:block
                  px-2 py-1 text-xs text-white bg-gray-700 rounded shadow-lg whitespace-nowrap"
              >
                Project Plan
              </span>
            )}
          </NavLink>

          {/* Topic Register */}
          <NavLink
            to="/topic-register"
            className={({ isActive }) =>
              `group relative flex items-center p-2 rounded-md text-gray-700 hover:bg-green-100 transition-colors duration-300 ${
                isActive ? "bg-green-100 text-green-700" : ""
              }`
            }
          >
            <BookOpenIcon className="w-6 h-6" />
            {isOpen && (
              <span className="text-base font-medium ml-3">
                Topics Register
              </span>
            )}
            {!isOpen && (
              <span
                className="absolute left-full ml-2 hidden group-hover:block
                  px-2 py-1 text-xs text-white bg-gray-700 rounded shadow-lg whitespace-nowrap"
              >
                Topics Register
              </span>
            )}
          </NavLink>

          {/* File Repository */}
          <NavLink
            to="/file-repository"
            className={({ isActive }) =>
              `group relative flex items-center p-2 rounded-md text-gray-700 hover:bg-green-100 transition-colors duration-300 ${
                isActive ? "bg-green-100 text-green-700" : ""
              }`
            }
          >
            <FolderIcon className="w-6 h-6" />
            {isOpen && (
              <span className="text-base font-medium ml-3">
                File Repository
              </span>
            )}
            {!isOpen && (
              <span
                className="absolute left-full ml-2 hidden group-hover:block 
                  px-2 py-1 text-xs text-white bg-gray-700 rounded shadow-lg whitespace-nowrap"
              >
                File Repository
              </span>
            )}
          </NavLink>

          {/* ================== COMMENTED OUT: Summary Report ===================
          <NavLink
            to="/summary-report"
            className={({ isActive }) =>
              `group relative flex items-center p-2 rounded-md text-gray-700 hover:bg-green-100 transition-colors duration-300 ${
                isActive ? "bg-green-100 text-green-700" : ""
              }`
            }
          >
            <DocumentReportIcon className="w-6 h-6" />
            {isOpen && (
              <span className="text-base font-medium ml-3">Summary Report</span>
            )}
            {!isOpen && (
              <span
                className="absolute left-full ml-2 hidden group-hover:block 
                  px-2 py-1 text-xs text-white bg-gray-700 rounded shadow-lg whitespace-nowrap"
              >
                Summary Report
              </span>
            )}
          </NavLink>
          ================== END COMMENTED OUT =================== */}

          {/* Levels */}
          <NavLink
            to="/levels"
            className={({ isActive }) =>
              `group relative flex items-center p-2 rounded-md text-gray-700 hover:bg-green-100 transition-colors duration-300 ${
                isActive ? "bg-green-100 text-green-700" : ""
              }`
            }
          >
            <TrendingUpIcon className="w-6 h-6" />
            {isOpen && (
              <span className="text-base font-medium ml-3">Levels</span>
            )}
            {!isOpen && (
              <span
                className="absolute left-full ml-2 hidden group-hover:block
                  px-2 py-1 text-xs text-white bg-gray-700 rounded shadow-lg whitespace-nowrap"
              >
                Levels
              </span>
            )}
          </NavLink>

          {/* Level Attributes */}
          <NavLink
            to="/level-attributes"
            className={({ isActive }) =>
              `group relative flex items-center p-2 rounded-md text-gray-700 hover:bg-green-100 transition-colors duration-300 ${
                isActive ? "bg-green-100 text-green-700" : ""
              }`
            }
          >
            <CollectionIcon className="w-6 h-6" />
            {isOpen && (
              <span className="text-base font-medium ml-3">
                Level Attributes
              </span>
            )}
            {!isOpen && (
              <span
                className="absolute left-full ml-2 hidden group-hover:block
                  px-2 py-1 text-xs text-white bg-gray-700 rounded shadow-lg whitespace-nowrap"
              >
                Level Attributes
              </span>
            )}
          </NavLink>

          {/* Audit Trail */}
          <NavLink
            to="/audit-trail"
            className={({ isActive }) =>
              `group relative flex items-center p-2 rounded-md text-gray-700 hover:bg-green-100 transition-colors duration-300 ${
                isActive ? "bg-green-100 text-green-700" : ""
              }`
            }
          >
            <DocumentSearchIcon className="w-6 h-6" />
            {isOpen && (
              <span className="text-base font-medium ml-3">Audit Trail</span>
            )}
            {!isOpen && (
              <span
                className="absolute left-full ml-2 hidden group-hover:block
                  px-2 py-1 text-xs text-white bg-gray-700 rounded shadow-lg whitespace-nowrap"
              >
                Audit Trail
              </span>
            )}
          </NavLink>
        </nav>

        {/* Logout Button */}
        <div className="w-full flex justify-center mt-auto">
          <LogoutButton />
        </div>
      </div>

      {/* Main Content */}
      <div className={`flex-1 ${isOpen ? "pl-64" : "pl-20"}`}>{children}</div>
    </div>
  );
};

export default Layout;
