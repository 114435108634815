// PermissionGroupsSection.jsx
import React from "react";
import {
  PlusCircleIcon,
  PencilAltIcon,
  XCircleIcon,
} from "@heroicons/react/outline";

const PermissionGroupsSection = ({
  groups,
  toggleGroupEnabled,
  handleOpenGroupModal,
  confirmDeleteGroup,
}) => {
  return (
    <div className="mb-16">
      {/* Header Row */}
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold text-gray-800 tracking-tight">
          Permission Sets
        </h2>
        <button
          onClick={() => handleOpenGroupModal()}
          className="inline-flex items-center bg-indigo-600 text-white px-4 py-2
                     rounded-md hover:bg-indigo-700 transition-colors font-semibold"
        >
          <PlusCircleIcon className="w-5 h-5 mr-2" />
          Add Group
        </button>
      </div>

      {/* Table Container */}
      <div className="overflow-x-auto border border-gray-200 rounded-lg shadow bg-white">
        <table className="min-w-full divide-y divide-gray-200 text-sm">
          {/* Table Head */}
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left font-semibold text-gray-700 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left font-semibold text-gray-700 uppercase tracking-wider"
              >
                Description
              </th>
              {/* <th
                scope="col"
                className="px-6 py-3 text-center font-semibold text-gray-700 uppercase tracking-wider"
              >
                Enabled
              </th> */}
              <th
                scope="col"
                className="px-6 py-3 text-center font-semibold text-gray-700 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody className="divide-y divide-gray-200">
            {groups.map((g) => (
              <tr key={g.id} className="hover:bg-gray-100 transition-colors">
                <td className="px-6 py-4 whitespace-nowrap text-gray-800 font-medium">
                  {g.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                  {g.description}
                </td>
                {/* <td className="px-6 py-4 whitespace-nowrap text-center">
                  <button
                    onClick={() => toggleGroupEnabled(g.id)}
                    className={`px-3 py-1 rounded-full text-xs font-semibold uppercase transition-colors 
                      ${
                        g.enabled
                          ? "bg-green-100 text-green-800 hover:bg-green-200"
                          : "bg-red-100 text-red-800 hover:bg-red-200"
                      }`}
                  >
                    {g.enabled ? "Enabled" : "Disabled"}
                  </button>
                </td> */}
                <td className="px-6 py-4 whitespace-nowrap text-center">
                  <button
                    onClick={() => handleOpenGroupModal(g)}
                    className="text-indigo-600 hover:text-indigo-800 mr-3"
                  >
                    <PencilAltIcon className="w-5 h-5 inline" />
                  </button>
                  <button
                    onClick={() => confirmDeleteGroup(g.id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <XCircleIcon className="w-5 h-5 inline" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PermissionGroupsSection;
