// DashboardHome.js

import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
  CheckSquare,
  AlertCircle,
  Clock3,
  ClipboardList,
  MoreHorizontal,
  Calendar as CalendarIcon,
  XCircle,
  User,
} from "lucide-react";

/** Recharts for dynamic chart data */
import {
  AreaChart,
  Area,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { UserNameContext } from "../../ProtectedRoute";

/* ------------------------------------------------------------------
   HELPER FUNCTIONS: GROUP TASKS & BUILD CHART DATA
------------------------------------------------------------------- */

/** Returns all days in a given (year, 0-based month). */
function getDaysInMonth(year, month) {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

/** Group completed tasks by day => { 'YYYY-MM-DD': count } */
function groupCompletedTasksByDay(tasks) {
  return tasks.reduce((acc, task) => {
    if (task.status === "true" && task.complete_date) {
      const dateStr = new Date(task.complete_date).toISOString().slice(0, 10);
      acc[dateStr] = (acc[dateStr] || 0) + 1;
    }
    return acc;
  }, {});
}

/** Build data for "This vs. Last Month" chart. */
function buildMonthVsMonthData(tasks) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  let lastMonth = currentMonth - 1;
  let lastMonthYear = currentYear;
  if (lastMonth < 0) {
    lastMonth = 11;
    lastMonthYear -= 1;
  }

  const completedByDay = groupCompletedTasksByDay(tasks);
  const daysThisMonth = getDaysInMonth(currentYear, currentMonth);
  const daysLastMonth = getDaysInMonth(lastMonthYear, lastMonth);

  const dateMap = {};

  // Insert "thisMonth"
  daysThisMonth.forEach((d) => {
    const key = d.toISOString().slice(0, 10);
    dateMap[key] = {
      date: key,
      thisMonth: completedByDay[key] || 0,
      lastMonth: 0,
    };
  });

  // Insert "lastMonth"
  daysLastMonth.forEach((d) => {
    const key = d.toISOString().slice(0, 10);
    if (!dateMap[key]) {
      dateMap[key] = {
        date: key,
        thisMonth: 0,
        lastMonth: completedByDay[key] || 0,
      };
    } else {
      dateMap[key].lastMonth = completedByDay[key] || 0;
    }
  });

  return Object.values(dateMap).sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
}

/** Build data for "Last 7 Days" => e.g. [{ date, completed }, ...]. */
function buildWeekData(tasks) {
  const completedByDay = groupCompletedTasksByDay(tasks);
  const result = [];
  const now = new Date();

  // last 7 days
  for (let i = 6; i >= 0; i--) {
    const d = new Date(now);
    d.setDate(now.getDate() - i);
    const key = d.toISOString().slice(0, 10);
    result.push({
      date: key,
      completed: completedByDay[key] || 0,
    });
  }
  return result;
}

/** Group tasks by assignee => [ { id, name, tasks: [...] }, ... ] */
function groupTasksByAssignee(allTasks) {
  const assigneeMap = {};
  allTasks.forEach((task) => {
    if (Array.isArray(task.assignees)) {
      task.assignees.forEach(({ id, name }) => {
        if (!assigneeMap[id]) {
          assigneeMap[id] = { id, name, tasks: [] };
        }
        assigneeMap[id].tasks.push(task);
      });
    }
  });
  return Object.values(assigneeMap);
}

/* ------------------------------------------------------------------
   TASK DETAIL MODAL
------------------------------------------------------------------- */
function TaskDetailsModal({ task, onClose }) {
  if (!task) return null;

  const getStatusColor = (status) => {
    if (status === "true") return "bg-green-50 text-green-600 border-green-100";
    const dueDate = task.due_date ? new Date(task.due_date) : null;
    const now = new Date();
    if (dueDate && dueDate < now)
      return "bg-red-50 text-red-600 border-red-100";
    return "bg-blue-50 text-blue-600 border-blue-100";
  };

  const getStatusText = (status) => {
    if (status === "true") return "Completed";
    const dueDate = task.due_date ? new Date(task.due_date) : null;
    const now = new Date();
    if (dueDate && dueDate < now) return "Overdue";
    return "In Progress";
  };

  return (
    <div className="fixed inset-0 z-50 bg-black/40 backdrop-blur-sm flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-md relative overflow-hidden">
        {/* Gradient decoration */}
        <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-bl from-blue-50/20 to-transparent rounded-bl-full" />

        {/* Header section */}
        <div className="p-6 border-b border-gray-100">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold text-gray-900">
              {task.name || `Task ${task.id}`}
            </h2>
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <XCircle className="w-5 h-5" />
            </button>
          </div>
          <div className="mt-2">
            <span
              className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(
                task.status
              )}`}
            >
              {getStatusText(task.status)}
            </span>
          </div>
        </div>

        {/* Content section */}
        <div className="p-6 space-y-4">
          {/* Description */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 mb-1">
              Description
            </h3>
            {task.description ? (
              <p className="text-sm text-gray-600">{task.description}</p>
            ) : (
              <p className="text-sm text-gray-400 italic">
                No description provided
              </p>
            )}
          </div>

          {/* Dates Section */}
          <div className="grid grid-cols-2 gap-4">
            {/* Due Date */}
            <div className="bg-gray-50 rounded-lg p-3">
              <h3 className="text-xs font-semibold text-gray-600 mb-1">
                Due Date
              </h3>
              {task.due_date ? (
                <p className="text-sm font-medium text-gray-900">
                  {new Date(task.due_date).toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </p>
              ) : (
                <p className="text-sm text-gray-400 italic">Not set</p>
              )}
            </div>

            {/* Completion Date */}
            {task.status === "true" && task.complete_date && (
              <div className="bg-green-50 rounded-lg p-3">
                <h3 className="text-xs font-semibold text-gray-600 mb-1">
                  Completed On
                </h3>
                <p className="text-sm font-medium text-gray-900">
                  {new Date(task.complete_date).toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </p>
              </div>
            )}
          </div>

          {/* Assignees */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 mb-2">
              Assignees
            </h3>
            {task.assignees && task.assignees.length > 0 ? (
              <div className="space-y-2">
                {task.assignees.map((assignee) => (
                  <div
                    key={assignee.id}
                    className="flex items-center gap-2 bg-gray-50 rounded-lg p-2"
                  >
                    <div className="p-1.5 bg-blue-50 rounded-lg">
                      <User className="w-4 h-4 text-blue-600" />
                    </div>
                    <span className="text-sm text-gray-700">
                      {assignee.name}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-sm text-gray-400 italic">No assignees</p>
            )}
          </div>
        </div>

        {/* Footer section with close button */}
        <div className="border-t border-gray-100 p-4 bg-gray-50">
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors duration-200 font-medium text-sm"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

/* ------------------------------------------------------------------
   MAIN DASHBOARD
------------------------------------------------------------------- */
export default function DashboardHome() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const dbUserName = useContext(UserNameContext);

  const [allTasks, setAllTasks] = useState([]);
  const [greeting, setGreeting] = useState("");
  const [chartFilter, setChartFilter] = useState("month");
  const [assigneesList, setAssigneesList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // For categorizing tasks in bottom cards
  const [overdueTasks, setOverdueTasks] = useState([]);
  const [inProgressOrUpcoming, setInProgressOrUpcoming] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);

  // For showing task detail modal
  const [selectedTask, setSelectedTask] = useState(null);

  // ENV
  const clientUrl = process.env.REACT_APP_CLIENT_URL;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const encodedAuth = btoa(`${clientId}:${clientSecret}`);

  /** 1) Greeting by time */
  useEffect(() => {
    const hour = new Date().getHours();
    if (hour < 12) setGreeting("Good morning");
    else if (hour < 16) setGreeting("Good afternoon");
    else setGreeting("Good evening");
  }, []);

  /** 2) Fetch tasks */
  useEffect(() => {
    const fetchTasks = async () => {
      if (!isAuthenticated || !user?.email) return;
      try {
        const payload = { string: user.email };
        const response = await axios.post(
          `${clientUrl}/ws/rest/getTasks`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${encodedAuth}`,
            },
          }
        );

        if (Array.isArray(response.data) && response.data.length > 0) {
          const groupsArray = response.data[0];
          if (Array.isArray(groupsArray)) {
            // Flatten tasks
            const fetchedTasks = groupsArray.flatMap((item) => item.tasks);
            setAllTasks(fetchedTasks);
          }
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };
    fetchTasks();
  }, [clientUrl, encodedAuth, isAuthenticated, user]);

  /** 3) Categorize tasks + build assignees list */
  useEffect(() => {
    if (!allTasks.length) return;

    const grouped = groupTasksByAssignee(allTasks);
    setAssigneesList(grouped);

    const now = new Date();
    const overdueArr = [];
    const upcomingArr = [];
    const completedArr = [];

    allTasks.forEach((task) => {
      if (task.status === "true") {
        // completed
        completedArr.push(task);
      } else {
        const dueDate = task.due_date ? new Date(task.due_date) : null;
        if (dueDate && dueDate < now) {
          overdueArr.push(task);
        } else if (dueDate && dueDate - now <= 7 * 24 * 60 * 60 * 1000) {
          upcomingArr.push({
            ...task,
            statusLabel: "Upcoming",
          });
        } else {
          upcomingArr.push({
            ...task,
            statusLabel: "In progress",
          });
        }
      }
    });

    setOverdueTasks(overdueArr);
    setCompletedTasks(completedArr);
    setInProgressOrUpcoming(upcomingArr);
  }, [allTasks]);

  // If there's more than 1 assignee, auto-rotate every 5s (no dots shown)
  useEffect(() => {
    if (assigneesList.length <= 1) return;
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % assigneesList.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [assigneesList.length]);

  // 5) Check loading / auth
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        Loading user info...
      </div>
    );
  }
  if (!isAuthenticated) return null;

  /** 6) Build chart data from tasks + filter */
  let chartData = [];
  if (chartFilter === "week") {
    chartData = buildWeekData(allTasks);
  } else {
    chartData = buildMonthVsMonthData(allTasks);
  }

  // Basic user info
  const firstName = dbUserName ? dbUserName.split(" ")[0] : "";
  const currentDate = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  /** Stats */
  const doneCount = completedTasks.length;
  const overdueCount = overdueTasks.length;
  const upcomingCount = inProgressOrUpcoming.filter(
    (t) => t.statusLabel === "Upcoming"
  ).length;
  const totalTasks = allTasks.length;
  const donePercent =
    totalTasks > 0 ? Math.round((doneCount / totalTasks) * 100) : 0;

  // Current assignee (auto-rotated)
  const currentAssignee = assigneesList[currentIndex] || null;

  /** Show detail modal */
  const handleShowDetails = (task) => {
    setSelectedTask(task);
  };
  const handleCloseDetails = () => {
    setSelectedTask(null);
  };

  /**
   * STATS TILE
   */
  const StatTile = ({ icon, label, value, iconColorClass }) => (
    <div className="bg-white rounded-xl p-5 shadow-lg hover:shadow-xl transition-all duration-300 relative overflow-hidden group border border-gray-100">
      <div className="absolute top-0 right-0 w-24 h-24 bg-gradient-to-bl from-gray-100 to-transparent rounded-bl-full opacity-90" />
      <div className="flex items-center gap-4">
        <div
          className={`${
            iconColorClass === "text-green-600"
              ? "bg-green-100 shadow-sm shadow-green-100"
              : iconColorClass === "text-red-600"
              ? "bg-red-100 shadow-sm shadow-red-100"
              : iconColorClass === "text-yellow-600"
              ? "bg-yellow-100 shadow-sm shadow-yellow-100"
              : "bg-blue-100 shadow-sm shadow-blue-100"
          } p-3 rounded-xl group-hover:scale-105 transition-transform duration-300`}
        >
          {icon}
        </div>

        <div className="flex flex-col">
          <span className="text-sm font-semibold text-gray-600">{label}</span>
          <span className="text-2xl font-bold text-gray-900 mt-0.5">
            {value}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 text-gray-800 p-4 md:p-6">
      {/* TOP HEADER */}
      <header className="flex items-center justify-between mb-6">
        <div className="space-y-1">
          <h1 className="text-2xl font-bold text-gray-900">
            {greeting}, {firstName}
          </h1>
          <p className="text-sm font-medium text-gray-600">
            Welcome to your GreenGen Dashboard
          </p>
        </div>
        <div className="flex items-center gap-2 text-sm font-medium text-gray-700 bg-white px-4 py-2 rounded-lg shadow-md">
          <CalendarIcon className="w-4 h-4 text-blue-600" />
          {currentDate}
        </div>
      </header>

      {/* STATS ROW */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        {/* Finished */}
        <StatTile
          icon={<CheckSquare className="w-5 h-5 text-green-600" />}
          label="Finished Tasks"
          value={doneCount}
          iconColorClass="text-green-600"
        />

        {/* Overdue */}
        <StatTile
          icon={<AlertCircle className="w-5 h-5 text-red-600" />}
          label="Overdue Tasks"
          value={overdueCount}
          iconColorClass="text-red-600"
        />

        {/* Upcoming */}
        <StatTile
          icon={<Clock3 className="w-5 h-5 text-yellow-600" />}
          label="Upcoming Tasks"
          value={upcomingCount}
          iconColorClass="text-yellow-600"
        />

        {/* Total */}
        <StatTile
          icon={<ClipboardList className="w-5 h-5 text-blue-600" />}
          label="Total Tasks"
          value={totalTasks}
          iconColorClass="text-blue-600"
        />
      </div>

      {/* MIDDLE: CHART (2/3) + ASSIGNEE (1/3) */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        {/* Chart card */}
        <div className="md:col-span-2 bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 p-6 relative overflow-hidden">
          <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-bl from-blue-50/20 to-transparent rounded-bl-full pointer-events-none" />

          <div className="flex items-center justify-between mb-4">
            <h2 className="text-sm font-bold text-gray-900">Task Analysis</h2>
            {/* Chart Filter */}
            <select
              value={chartFilter}
              onChange={(e) => setChartFilter(e.target.value)}
              className="text-xs bg-gray-100 border border-gray-200 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-100 transition-all font-medium"
            >
              <option value="month">This vs Last Month</option>
              <option value="week">Last 7 Days</option>
            </select>
          </div>

          {/* The Recharts container */}
          <div className="h-[200px]">
            <ResponsiveContainer>
              {chartFilter === "week" ? (
                <AreaChart
                  data={chartData}
                  margin={{ top: 10, right: 20, bottom: 0, left: -10 }}
                >
                  <CartesianGrid stroke="#f1f1f1" vertical={false} />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="completed"
                    stroke="#3b82f6"
                    fill="#3b82f6"
                    fillOpacity={0.15}
                    strokeWidth={2}
                    dot={false}
                    name="Completed"
                  />
                </AreaChart>
              ) : (
                <AreaChart
                  data={chartData}
                  margin={{ top: 10, right: 20, bottom: 0, left: -10 }}
                >
                  <CartesianGrid stroke="#f1f1f1" vertical={false} />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="lastMonth"
                    stroke="#fca130"
                    fill="#fca130"
                    fillOpacity={0.15}
                    strokeWidth={2}
                    dot={false}
                    name="Last Month"
                  />
                  <Area
                    type="monotone"
                    dataKey="thisMonth"
                    stroke="#3b82f6"
                    fill="#3b82f6"
                    fillOpacity={0.15}
                    strokeWidth={2}
                    dot={false}
                    name="This Month"
                  />
                </AreaChart>
              )}
            </ResponsiveContainer>
          </div>
        </div>

        {/* Assignee (fixed height, scroll) + no dots */}
        <div className="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 p-6 relative overflow-hidden">
          <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-bl from-blue-50/20 to-transparent rounded-bl-full pointer-events-none" />

          {assigneesList.length === 0 ? (
            <div className="flex items-center gap-2 text-gray-400">
              <User className="w-5 h-5" />
              <p className="text-sm italic">No assignees</p>
            </div>
          ) : (
            <>
              <div className="flex items-center gap-2 mb-4">
                <div className="p-2 bg-blue-50 rounded-lg">
                  <User className="w-4 h-4 text-blue-600" />
                </div>
                <span className="text-sm font-bold text-gray-900">
                  {currentAssignee?.name}
                </span>
              </div>

              {/* Scrollable list of tasks */}
              <div className="space-y-2 no-scrollbar overflow-y-auto max-h-52">
                {currentAssignee?.tasks?.map((task) => (
                  <div
                    key={task.id}
                    className="group p-3 rounded-lg bg-gray-50 hover:bg-blue-50/50 transition-all duration-200"
                  >
                    <div className="flex flex-col gap-1">
                      {/* Name => detail modal */}
                      <button
                        onClick={() => setSelectedTask(task)}
                        className="text-sm text-gray-700 group-hover:text-blue-600 font-medium text-left transition-colors"
                      >
                        {task.name || `Task ${task.id}`}
                      </button>
                      {/* Show date if present */}
                      {task.due_date ? (
                        <span className="text-xs text-gray-400">
                          Due: {new Date(task.due_date).toLocaleDateString()}
                        </span>
                      ) : (
                        <span className="text-xs text-gray-400 italic">
                          No due date
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      {/* BOTTOM CARDS (Overdue, In progress/upcoming, Completed) */}
      <section>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-3">
            <h2 className="text-sm font-bold text-gray-900">Current Tasks</h2>
            <span className="text-gray-600 text-xs font-medium">
              Done {donePercent}%
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Overdue Card */}
          <div className="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 p-6 relative overflow-hidden">
            <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-bl from-red-50/20 to-transparent rounded-bl-full pointer-events-none" />
            <h3 className="text-sm font-bold text-red-600 mb-4">Overdue</h3>
            <div className="space-y-2 no-scrollbar overflow-y-auto max-h-52">
              {overdueTasks.length === 0 ? (
                <p className="text-sm text-gray-400 italic">No overdue tasks</p>
              ) : (
                overdueTasks.map((task) => (
                  <div
                    key={task.id}
                    className="group p-3 rounded-lg bg-gray-50 hover:bg-red-50/50 transition-all duration-200"
                  >
                    <div className="flex items-center gap-2">
                      <div className="p-1.5 bg-red-100 rounded-lg">
                        <AlertCircle className="w-3 h-3 text-red-600" />
                      </div>
                      <button
                        onClick={() => setSelectedTask(task)}
                        className="text-sm text-gray-700 group-hover:text-red-600 font-medium text-left transition-colors flex-1"
                      >
                        {task.name || `Task ${task.id}`}
                      </button>
                    </div>
                    {task.due_date ? (
                      <span className="text-xs text-gray-400 ml-9">
                        Due: {new Date(task.due_date).toLocaleDateString()}
                      </span>
                    ) : (
                      <span className="text-xs text-gray-400 italic ml-9">
                        No due date
                      </span>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>

          {/* In Progress / Upcoming Card */}
          <div className="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 p-6 relative overflow-hidden">
            <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-bl from-yellow-50/20 to-transparent rounded-bl-full pointer-events-none" />
            <h3 className="text-sm font-bold text-gray-900 mb-4">
              In Progress / Upcoming
            </h3>
            <div className="space-y-2 no-scrollbar overflow-y-auto max-h-52">
              {inProgressOrUpcoming.length === 0 ? (
                <p className="text-sm text-gray-400 italic">No tasks</p>
              ) : (
                inProgressOrUpcoming.map((task) => (
                  <div
                    key={task.id}
                    className="group p-3 rounded-lg bg-gray-50 hover:bg-yellow-50/50 transition-all duration-200"
                  >
                    <div className="flex items-center gap-2">
                      <div
                        className={`p-1.5 rounded-lg ${
                          task.statusLabel === "Upcoming"
                            ? "bg-yellow-100"
                            : "bg-blue-100"
                        }`}
                      >
                        {task.statusLabel === "Upcoming" ? (
                          <Clock3 className="w-3 h-3 text-yellow-600" />
                        ) : (
                          <Clock3 className="w-3 h-3 text-blue-600" />
                        )}
                      </div>
                      <button
                        onClick={() => setSelectedTask(task)}
                        className="text-sm text-gray-700 group-hover:text-yellow-600 font-medium text-left transition-colors flex-1"
                      >
                        {task.name || `Task ${task.id}`}
                      </button>
                    </div>
                    {task.due_date ? (
                      <span className="text-xs text-gray-400 ml-9">
                        Due: {new Date(task.due_date).toLocaleDateString()}
                      </span>
                    ) : (
                      <span className="text-xs text-gray-400 italic ml-9">
                        No due date
                      </span>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Completed Card (ignore due date) */}
          <div className="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 p-6 relative overflow-hidden">
            <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-bl from-green-50/20 to-transparent rounded-bl-full pointer-events-none" />
            <h3 className="text-sm font-bold text-gray-900 mb-4">Completed</h3>
            <div className="space-y-2 no-scrollbar overflow-y-auto max-h-52">
              {completedTasks.length === 0 ? (
                <p className="text-sm text-gray-400 italic">
                  No completed tasks
                </p>
              ) : (
                completedTasks.map((task) => (
                  <div
                    key={task.id}
                    className="group p-3 rounded-lg bg-gray-50 hover:bg-green-50/50 transition-all duration-200"
                  >
                    <div className="flex items-center gap-2">
                      <div className="p-1.5 bg-green-100 rounded-lg">
                        <CheckSquare className="w-3 h-3 text-green-600" />
                      </div>
                      <button
                        onClick={() => setSelectedTask(task)}
                        className="text-sm text-gray-700 group-hover:text-green-600 font-medium text-left transition-colors flex-1"
                      >
                        {task.name || `Task ${task.id}`}
                      </button>
                    </div>
                    {/* We are ignoring due_date for completed tasks */}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </section>

      {/* TASK DETAIL MODAL */}
      {selectedTask && (
        <TaskDetailsModal task={selectedTask} onClose={handleCloseDetails} />
      )}
    </div>
  );
}
