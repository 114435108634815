import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Plus,
  X,
  Paperclip,
  Upload,
  User as UserIcon,
  Users as UsersIcon,
  Check,
  Trash2 as TrashIcon,
  ChevronRight,
  ChevronDown as ChevronDownIcon,
} from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Local components/utils -- adjust paths as needed
import Loader from "../../../components/Loader";
import FolderSelectionModal from "./FolderSelectionModal";
import DeleteConfirmModal from "./DeleteConfirmModal";
import { buildNestedFolders } from "./folderUtils";
import {
  deduplicateById,
  parseDateOrFallback,
  formatDate,
  formatFileSize,
} from "./utils";
import { useAuth0 } from "@auth0/auth0-react";

/* ===================================================
   1) Build a tree of tasks using rolls_up_to
   =================================================== */
function buildTaskTree(flatTasks) {
  const taskMap = new Map();
  flatTasks.forEach((t) => {
    taskMap.set(t.id, { ...t, children: [] });
  });

  const roots = [];
  taskMap.forEach((task) => {
    const parentId = task.rolls_up_to ? parseInt(task.rolls_up_to, 10) : null;
    if (!parentId || !taskMap.has(parentId)) {
      // top-level
      roots.push(task);
    } else {
      // child
      const parent = taskMap.get(parentId);
      parent.children.push(task);
    }
  });
  return roots;
}

/* ==================================================================
   2) A single row in the table, with logic to block sub-subtasks
   ================================================================== */
function TaskTableRow({
  task,
  level,
  expandedSet,
  setExpandedSet,
  onSelectTask,
  onToggleComplete,
  onAddSubtask,
}) {
  // A subtask means it already has a parent => cannot have its own children
  const isTopLevel = !task.rolls_up_to; // only top-level can have children
  const hasChildren = isTopLevel && task.children && task.children.length > 0;
  const isExpanded = expandedSet.has(task.id);

  // For "Add Subtask"
  const [subTaskName, setSubTaskName] = useState("");

  const toggleExpand = (e) => {
    e.stopPropagation();
    // only do expand if it's top-level
    if (!isTopLevel) return;
    const next = new Set(expandedSet);
    if (next.has(task.id)) next.delete(task.id);
    else next.add(task.id);
    setExpandedSet(next);
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    onToggleComplete(task);
  };

  // Parent row
  const parentRow = (
    <tr
      className="hover:bg-gray-50 transition cursor-pointer"
      onClick={() => onSelectTask(task.id)}
    >
      {/* Task Name col */}
      <td className="px-4 py-3" style={{ paddingLeft: `${level * 1.5}rem` }}>
        <div className="flex items-center gap-2">
          {/* Show chevron only if top-level */}
          {isTopLevel ? (
            <button
              onClick={toggleExpand}
              className="text-gray-400 hover:text-gray-600"
            >
              {hasChildren && isExpanded ? (
                <ChevronDownIcon size={16} />
              ) : (
                <ChevronRight size={16} />
              )}
            </button>
          ) : (
            <div style={{ width: "1rem" }} />
          )}

          {/* Complete checkbox */}
          <input
            type="checkbox"
            checked={task.status === "true"}
            onChange={handleCheckboxClick}
            className="form-checkbox h-4 w-4 text-blue-500"
          />

          {/* Task name */}
          <span
            className={`font-medium ${
              task.status === "true"
                ? "line-through text-gray-500"
                : "text-gray-800"
            }`}
          >
            {task.name}
          </span>
        </div>
      </td>

      {/* Assignees */}
      <td className="px-4 py-3 text-gray-700">
        {task.assignees.length === 0 ? (
          <span className="italic text-gray-400">None</span>
        ) : (
          <div className="flex -space-x-2">
            {task.assignees.map((asn, i) => (
              <div
                key={asn.id + "-" + i}
                className="relative group inline-flex items-center justify-center w-6 h-6 rounded-full border-2 border-white text-xs font-medium bg-blue-100 text-blue-800"
              >
                {asn.name?.charAt(0) || "?"}
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-2 py-1 bg-gray-900 text-white text-xs rounded shadow opacity-0 group-hover:opacity-100 transition pointer-events-none whitespace-nowrap">
                  {asn.name}
                </div>
              </div>
            ))}
          </div>
        )}
      </td>

      {/* Due Date */}
      <td className="px-4 py-3 text-gray-700">{formatDate(task.due_date)}</td>

      {/* Priority */}
      <td className="px-4 py-3 text-gray-700">
        <span
          className={`px-2 py-0.5 rounded-full text-xs font-medium ${
            task.priority === "High"
              ? "bg-red-100 text-red-800"
              : task.priority === "Medium"
              ? "bg-yellow-100 text-yellow-800"
              : task.priority === "Low"
              ? "bg-green-100 text-green-800"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          {task.priority}
        </span>
      </td>

      {/* Completed Date */}
      <td className="px-4 py-3 text-gray-700">
        {formatDate(task.complete_date)}
      </td>
    </tr>
  );

  // Child rows
  const childRows = [];
  // If top-level & expanded & has children => display them
  if (isTopLevel && isExpanded && hasChildren) {
    task.children.forEach((child) => {
      childRows.push(
        <TaskTableRow
          key={child.id}
          task={child}
          level={level + 1}
          expandedSet={expandedSet}
          setExpandedSet={setExpandedSet}
          onSelectTask={onSelectTask}
          onToggleComplete={onToggleComplete}
          onAddSubtask={onAddSubtask}
        />
      );
    });
  }

  // Also show "Add Subtask" for top-level if expanded
  if (isTopLevel && isExpanded) {
    childRows.push(
      <tr key={`add-sub-${task.id}`}>
        <td colSpan={5} style={{ paddingLeft: `${(level + 1) * 1.5}rem` }}>
          <div className="flex items-center gap-2 py-2 bg-gray-50">
            <span className="text-sm italic text-gray-600">+ Add Subtask:</span>
            <input
              type="text"
              value={subTaskName}
              onChange={(e) => setSubTaskName(e.target.value)}
              placeholder="Subtask name"
              className="border border-gray-300 rounded px-2 py-1 text-sm"
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (!subTaskName.trim()) {
                  alert("Enter a subtask name");
                  return;
                }
                onAddSubtask(task.id, subTaskName.trim());
                setSubTaskName("");
              }}
              className="inline-flex items-center gap-1 text-blue-600 hover:text-blue-800 text-sm"
            >
              <Plus size={14} />
              Add
            </button>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <>
      {parentRow}
      {childRows}
    </>
  );
}

/* =======================================================
   3) Main ProjectPlan Component
   ======================================================= */
const ProjectPlan = () => {
  // --------- States ---------
  const [tasks, setTasks] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [loadingAssignees, setLoadingAssignees] = useState(true);
  const [loadingTasks, setLoadingTasks] = useState(true);

  // For new tasks
  const [showNewTaskForm, setShowNewTaskForm] = useState(false);
  const [newTaskName, setNewTaskName] = useState("");
  const [newTaskDesc, setNewTaskDesc] = useState("");
  const [newTaskStartDate, setNewTaskStartDate] = useState(null);
  const [newTaskEndDate, setNewTaskEndDate] = useState(null);
  const [newTaskPriority, setNewTaskPriority] = useState("None");
  const [newTaskAttachments, setNewTaskAttachments] = useState([]);
  const [newTaskFolderId, setNewTaskFolderId] = useState(null);
  const [newTaskAssignees, setNewTaskAssignees] = useState([]);
  const [showNewTaskUserList, setShowNewTaskUserList] = useState(false);
  const [showNewTaskGroupList, setShowNewTaskGroupList] = useState(false);

  // For editing existing tasks
  const [selectedTask, setSelectedTask] = useState(null);
  const [editableTask, setEditableTask] = useState(null);
  const [showEditUserList, setShowEditUserList] = useState(false);
  const [showEditGroupList, setShowEditGroupList] = useState(false);

  // Delete confirmation
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Folders
  const [rawFolders, setRawFolders] = useState([]);
  const [nestedFolders, setNestedFolders] = useState([]);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  // For attachments
  const [availableTags] = useState([
    { id: "100", name: "Planning Tag" },
    { id: "200", name: "Budget Tag" },
  ]);

  // Filters
  const [filterType, setFilterType] = useState("all");
  const [assigneeFilter, setAssigneeFilter] = useState("all");

  // For expand/collapse
  const [expandedTasks, setExpandedTasks] = useState(new Set());

  // Auth
  const { user } = useAuth0();
  const userEmail = user?.email;

  // Env
  const clientUrl = process.env.REACT_APP_CLIENT_URL;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const encodedAuth = btoa(`${clientId}:${clientSecret}`);

  const loading = loadingAssignees || loadingTasks;

  // --------- useEffect ---------
  useEffect(() => {
    fetchAssignees();
    fetchTasks();
    fetchFolders();
    // eslint-disable-next-line
  }, []);

  // --------- Fetchers ---------
  async function fetchAssignees() {
    try {
      const resp = await fetch(`${clientUrl}/ws/rest/getAssignees`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify({ string: userEmail || "" }),
      });
      if (!resp.ok) throw new Error("Failed to fetch assignees");
      const data = await resp.json();
      const arr = Array.isArray(data) ? data : [];
      const transformed = arr.map((item) => ({
        id: item.id,
        name: item["email/group_name"] || "",
        description: item.description || "",
        object: item.object,
        flag: !!item.flag,
      }));
      setAssignees(transformed);
    } catch (err) {
      console.error("Error fetching assignees:", err);
    } finally {
      setLoadingAssignees(false);
    }
  }

  async function fetchTasks() {
    try {
      const payload = { string: userEmail || "" };
      const response = await axios.post(
        `${clientUrl}/ws/rest/getTasks`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
        }
      );
      const data = response.data;

      if (Array.isArray(data) && data.length > 0) {
        const tasksArray = data[0].map((item) => item.tasks);
        const transformed = tasksArray.map((t) => {
          const sDate = t.start_date ? new Date(t.start_date) : null;
          const dDate = t.due_date ? new Date(t.due_date) : null;
          const cDate = t.complete_date ? new Date(t.complete_date) : null;

          return {
            ...t,
            id: t.id,
            start_date: sDate,
            due_date: dDate,
            complete_date: cDate,
            attachments: t.attachments || [],
            assignees: deduplicateById(t.assignees || []),
          };
        });
        setTasks(transformed);
      } else {
        setTasks([]);
      }
    } catch (err) {
      console.error("Error fetching tasks:", err);
      setTasks([]);
    } finally {
      setLoadingTasks(false);
    }
  }

  async function fetchFolders() {
    try {
      const resp = await fetch(`${clientUrl}/ws/rest/getFileRepo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify({ string: userEmail || "" }),
      });
      if (!resp.ok) throw new Error("Failed to fetch file repo");
      const data = await resp.json();
      const folderArray = data[0] || [];
      setRawFolders(folderArray);
      // Build nested folder structure
      const nested = buildNestedFolders(folderArray);
      setNestedFolders(nested);
    } catch (err) {
      console.error("Error fetching folders:", err);
    }
  }

  // --------- Filters ---------
  function doesTaskMatchFilterType(task) {
    const completed = task.status === "true";
    if (filterType === "completed") return completed;
    if (filterType === "incomplete") return !completed;
    return true;
  }
  function doesTaskMatchAssigneeFilter(task) {
    if (assigneeFilter === "unassigned") return task.assignees.length === 0;
    if (assigneeFilter === "all") return true;
    return task.assignees.some((a) => a.name === assigneeFilter);
  }

  // -----------------------------
  // New Task Form
  // -----------------------------
  function handleOpenNewTaskForm() {
    setShowNewTaskForm(true);
    setSelectedTask(null);
    setEditableTask(null);

    setNewTaskName("");
    setNewTaskDesc("");
    setNewTaskStartDate(null);
    setNewTaskEndDate(null);
    setNewTaskPriority("None");
    setNewTaskAssignees([]);
    setNewTaskAttachments([]);
    setNewTaskFolderId(null);
    setShowNewTaskUserList(false);
    setShowNewTaskGroupList(false);
  }

  function cancelNewTask() {
    setShowNewTaskForm(false);
  }

  async function createNewTask() {
    if (!newTaskName.trim()) {
      alert("Please provide a task name.");
      return;
    }

    // Convert attachments
    const finalAttachments = newTaskAttachments.map((att) => ({
      id: "",
      name: att.name,
      extension: att.extension,
      size: String(att.size),
      mime: att.mime || "application/octet-stream",
      download_uri: "",
      folder_id: att.folder_id,
      tags: att.selectedTagId
        ? [
            {
              id: att.selectedTagId,
              name: att.selectedTagName,
            },
          ]
        : [],
    }));

    const newTaskPayload = {
      id: "",
      name: newTaskName.trim(),
      description: newTaskDesc.trim(),
      status: "false",
      start_date: newTaskStartDate,
      due_date: newTaskEndDate,
      complete_date: null,
      priority: newTaskPriority,
      attachments: finalAttachments,
      assignees: newTaskAssignees.map((id) => {
        const found = assignees.find((a) => a.id === id);
        if (found) {
          return {
            id: String(found.id),
            name: found.description || found.name,
          };
        }
        return { id: String(id), name: "Unknown" };
      }),
      rolls_up_to: "", // top-level
    };

    const saved = await updateTaskOnServer(newTaskPayload);
    if (saved) {
      // "smart" approach: if the ID already existed, replace; else append
      setTasks((prev) => {
        const idx = prev.findIndex((t) => t.id === saved.id);
        if (idx >= 0) {
          const next = [...prev];
          next[idx] = saved;
          return next;
        }
        return [...prev, saved];
      });
    }
    setShowNewTaskForm(false);
  }

  function handleToggleNewTaskAssignee(id) {
    setNewTaskAssignees((prev) =>
      prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id]
    );
  }

  // Folder / Attachments for new task
  function handleOpenFolderModal() {
    setIsFolderModalOpen(true);
  }

  function handleFolderSelect(folderId) {
    setNewTaskFolderId(folderId);
  }

  function handleNewTaskFileUpload(e) {
    const file = e.target.files[0];
    if (!file) return;
    if (!newTaskFolderId) {
      alert("Please select a folder before attaching files.");
      return;
    }
    const extension = file.name.includes(".") ? file.name.split(".").pop() : "";
    const newAttachment = {
      id: "",
      file,
      name: file.name,
      extension,
      size: file.size,
      mime: file.type,
      download_uri: "",
      folder_id: newTaskFolderId,
      selectedTagId: "",
      selectedTagName: "",
    };
    setNewTaskAttachments((prev) => [...prev, newAttachment]);
  }

  function removeNewTaskFile(attachmentObj) {
    setNewTaskAttachments((prev) => prev.filter((f) => f !== attachmentObj));
  }

  function handleTagChange(attachmentIndex, newTagId) {
    setNewTaskAttachments((prev) => {
      const next = [...prev];
      const match = availableTags.find((t) => t.id === newTagId);
      next[attachmentIndex] = {
        ...next[attachmentIndex],
        selectedTagId: newTagId,
        selectedTagName: match ? match.name : "",
      };
      return next;
    });
  }

  // -----------------------------
  // Edit Task logic
  // -----------------------------
  function selectTask(taskId) {
    const t = tasks.find((x) => x.id === taskId);
    if (!t) return;
    setShowNewTaskForm(false);
    setSelectedTask(t);

    const copy = JSON.parse(JSON.stringify(t));
    setEditableTask(copy);
    setShowEditUserList(false);
    setShowEditGroupList(false);
  }

  function setEditableField(field, newValue) {
    setEditableTask((prevEditableTask) => {
      // Make a copy so we can modify fields
      const updated = { ...prevEditableTask };

      // If the user changed a date field, set it.
      // Otherwise, if newValue is `null` or empty, keep the old date.
      if (field === "start_date") {
        // If you want to allow clearing the date, remove the `|| updated.start_date`
        updated.start_date = newValue || updated.start_date;
      } else if (field === "due_date") {
        updated.due_date = newValue || updated.due_date;
      } else {
        // For non-date fields, just set the field directly.
        updated[field] = newValue;
      }

      // Now update the local tasks array in a "smart" way:
      setTasks((oldTasks) => {
        const idx = oldTasks.findIndex((t) => t.id === updated.id);
        if (idx < 0) {
          // If for some reason the task isn't found, do nothing (or append)
          return oldTasks;
        }
        const newArr = [...oldTasks];
        newArr[idx] = updated;
        return newArr;
      });

      // Call the server to apply changes (partial or full update)
      updateTaskOnServer(updated);

      // Return the new editableTask state
      return updated;
    });
  }

  async function handleToggleTaskComplete(task) {
    const newStatus = task.status === "true" ? "false" : "true";
    const newCompleteDate = newStatus === "true" ? new Date() : null;
    const updated = {
      ...task,
      status: newStatus,
      complete_date: newCompleteDate,
    };

    // Replace in local state
    setTasks((old) => {
      const idx = old.findIndex((t) => t.id === updated.id);
      if (idx < 0) return old;
      const next = [...old];
      next[idx] = updated;
      return next;
    });
    if (editableTask && editableTask.id === updated.id)
      setEditableTask(updated);
    if (selectedTask && selectedTask.id === updated.id)
      setSelectedTask(updated);

    await updateTaskOnServer(updated);
  }

  function handleEditTaskAssigneeToggle(assigneeId) {
    if (!editableTask) return;
    setEditableTask((prev) => {
      const currentIDs = (prev.assignees || []).map((a) => a.id);
      const already = currentIDs.includes(assigneeId);
      let newList;
      if (!already) {
        const found = assignees.find(
          (a) => String(a.id) === String(assigneeId)
        );
        const newAsnObj = found
          ? { id: String(found.id), name: found.description || found.name }
          : { id: String(assigneeId), name: "Unknown" };
        newList = [...(prev.assignees || []), newAsnObj];
      } else {
        newList = (prev.assignees || []).filter(
          (a) => a.id !== String(assigneeId)
        );
      }
      const updated = { ...prev, assignees: newList };

      // Replace in local tasks
      setTasks((old) => {
        const idx = old.findIndex((t) => t.id === updated.id);
        if (idx < 0) return old;
        const next = [...old];
        next[idx] = updated;
        return next;
      });
      updateTaskOnServer(updated);
      return updated;
    });
  }

  // -----------------------------
  // Delete Task
  // -----------------------------
  async function deleteTask(taskId) {
    try {
      await axios.delete(`${clientUrl}/ws/rest/deleteTask`, {
        data: {
          user: userEmail,
          id: String(taskId),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
      });
      setTasks((prev) => prev.filter((t) => t.id !== taskId));
      setSelectedTask(null);
      setEditableTask(null);
    } catch (err) {
      console.error("Failed to delete task:", err);
      alert("Error deleting task. Check console for details.");
    }
  }

  // -----------------------------
  // Add Subtask (only for top-level)
  // -----------------------------
  async function handleAddSubtask(parentId, subTaskName) {
    const newPayload = {
      id: "",
      name: subTaskName,
      status: "false",
      // other fields default
      rolls_up_to: String(parentId),
      attachments: [],
      assignees: [],
    };
    const saved = await updateTaskOnServer(newPayload);
    if (saved) {
      // replace or append
      setTasks((prev) => {
        const idx = prev.findIndex((t) => t.id === saved.id);
        if (idx >= 0) {
          const next = [...prev];
          next[idx] = saved;
          return next;
        }
        return [...prev, saved];
      });
    }
  }

  // -----------------------------
  // Server logic
  // -----------------------------
  function sanitizeTaskForSave(task) {
    return {
      id: task.id ?? "",
      name: task.name || "",
      description: task.description || "",
      status: task.status || "false",
      user: task.user || null,
      owner: task.owner || null,
      group_assignees: task.group_assignees || [],
      complete_by: task.complete_by || null,
      start_date:
        task.start_date instanceof Date
          ? task.start_date.toISOString().split("T")[0]
          : "",
      due_date:
        task.due_date instanceof Date
          ? task.due_date.toISOString().split("T")[0]
          : "",
      complete_date:
        task.complete_date instanceof Date
          ? task.complete_date.toISOString()
          : "",
      priority: task.priority || "None",
      rolls_up_to: task.rolls_up_to || "",
      attachments: (task.attachments || []).map((att) => ({
        id: att.id ?? "",
        name: att.name,
        extension: att.extension,
        size: att.size ? String(att.size) : "0",
        mime: att.mime || "application/octet-stream",
        download_uri: att.download_uri || "",
        folder_id: att.folder_id || "",
        tags: att.tags || [],
      })),
      assignees: (task.assignees || []).map((a) => a.id),
    };
  }

  async function updateTaskOnServer(task) {
    try {
      const payload = { tasks: sanitizeTaskForSave(task) };
      const response = await axios.post(
        `${clientUrl}/ws/rest/addTask`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
        }
      );
      const data = response.data;
      if (Array.isArray(data) && data.length > 0) {
        const arr = data[0].map((item) => item.tasks);
        if (arr.length > 0) {
          const returned = arr[0];
          const merged = {
            ...task,
            ...returned,
            start_date: parseDateOrFallback(
              returned.start_date,
              task.start_date
            ),
            due_date: parseDateOrFallback(returned.due_date, task.due_date),
            complete_date: parseDateOrFallback(
              returned.complete_date,
              task.complete_date
            ),
            assignees: deduplicateById(returned.assignees || []),
            attachments: returned.attachments || task.attachments,
          };
          return merged;
        }
      }
    } catch (error) {
      console.error("Error updating task on server:", error);
    }
    return task;
  }

  // -------------------------------------------
  // Render
  // -------------------------------------------
  if (loading) {
    return <Loader />;
  }

  // Filter tasks
  const filteredTasks = tasks.filter(
    (t) => doesTaskMatchFilterType(t) && doesTaskMatchAssigneeFilter(t)
  );

  // Build tree of top-level tasks
  const taskTree = buildTaskTree(filteredTasks);

  // Decide widths
  const showRightPanel = showNewTaskForm || (selectedTask && editableTask);
  const leftPanelWidth = showRightPanel ? "w-2/3" : "w-full";
  const rightPanelWidth = showRightPanel ? "w-1/3" : "w-0 hidden";

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* LEFT: Task Table & Filters */}
      <div
        className={`${leftPanelWidth} flex flex-col border-r bg-white transition-all`}
      >
        {/* Header */}
        <div className="p-6 bg-gray-200 border-b">
          <h1 className="text-2xl font-semibold text-gray-800">Project Plan</h1>
          <p className="text-sm text-gray-600">Manage tasks & deliverables</p>
        </div>

        {/* Filters */}
        <div className="bg-gray-50 p-4 border-b flex flex-wrap gap-4">
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <select
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              className="border border-gray-300 rounded-md px-3 py-2 text-sm"
            >
              <option value="all">All</option>
              <option value="completed">Completed</option>
              <option value="incomplete">Incomplete</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700 mb-1">
              Assignee
            </label>
            <select
              value={assigneeFilter}
              onChange={(e) => setAssigneeFilter(e.target.value)}
              className="border border-gray-300 rounded-md px-3 py-2 text-sm"
            >
              <option value="all">All</option>
              <option value="unassigned">Unassigned</option>
              {[
                ...new Set(
                  tasks.flatMap((t) => t.assignees.map((a) => a.name || ""))
                ),
              ]
                .filter((nm) => nm)
                .map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
          <div className="ml-auto">
            <button
              onClick={handleOpenNewTaskForm}
              className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md text-sm"
            >
              <Plus size={16} />
              Add Task
            </button>
          </div>
        </div>

        {/* Main Task Table */}
        <div className="overflow-auto flex-grow p-4">
          <div className="bg-white rounded-md border">
            <table className="min-w-full divide-y divide-gray-200 text-sm">
              <thead className="bg-gray-50 text-gray-700">
                <tr>
                  <th className="px-4 py-3 text-left font-semibold w-1/4">
                    Task Name
                  </th>
                  <th className="px-4 py-3 text-left font-semibold w-1/6">
                    Assignees
                  </th>
                  <th className="px-4 py-3 text-left font-semibold w-1/6">
                    Due Date
                  </th>
                  <th className="px-4 py-3 text-left font-semibold w-1/6">
                    Priority
                  </th>
                  <th className="px-4 py-3 text-left font-semibold w-1/6">
                    Completed Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {taskTree.length === 0 ? (
                  <tr>
                    <td
                      colSpan={5}
                      className="px-4 py-6 text-center text-gray-500"
                    >
                      No tasks match the selected filter.
                    </td>
                  </tr>
                ) : (
                  taskTree.map((rootTask) => (
                    <TaskTableRow
                      key={rootTask.id}
                      task={rootTask}
                      level={0}
                      expandedSet={expandedTasks}
                      setExpandedSet={setExpandedTasks}
                      onSelectTask={selectTask}
                      onToggleComplete={handleToggleTaskComplete}
                      onAddSubtask={handleAddSubtask}
                    />
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* RIGHT PANEL: Either New Task Form or Edit Task */}
      <div
        className={`${rightPanelWidth} bg-gray-50 border-l flex flex-col h-screen relative transition-all`}
      >
        {/* NEW Task */}
        {showNewTaskForm && (
          <div className="p-6 overflow-auto relative flex-grow">
            <button
              onClick={cancelNewTask}
              className="absolute top-4 right-4 p-2 hover:bg-gray-200 rounded-full transition"
              title="Close"
            >
              <X className="w-5 h-5 text-gray-600" />
            </button>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Create New Task
            </h2>

            {/* Name & Priority */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex flex-col">
                <label className="mb-1 text-sm font-medium text-gray-700">
                  Task Name
                </label>
                <input
                  type="text"
                  value={newTaskName}
                  onChange={(e) => setNewTaskName(e.target.value)}
                  placeholder="Task Title"
                  className="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-900 focus:outline-none"
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-1 text-sm font-medium text-gray-700">
                  Priority
                </label>
                <select
                  value={newTaskPriority}
                  onChange={(e) => setNewTaskPriority(e.target.value)}
                  className="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-900 focus:outline-none"
                >
                  <option value="None">None</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </select>
              </div>
            </div>

            {/* Description */}
            <div className="mt-4">
              <label className="mb-1 text-sm font-medium text-gray-700 block">
                Description
              </label>
              <textarea
                rows={4}
                value={newTaskDesc}
                onChange={(e) => setNewTaskDesc(e.target.value)}
                placeholder="Enter task details..."
                className="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-900 w-full focus:outline-none"
              />
            </div>

            {/* Dates */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
              <div className="flex flex-col">
                <label className="mb-1 text-sm font-medium text-gray-700">
                  Start Date
                </label>
                <DatePicker
                  selected={newTaskStartDate}
                  onChange={(date) => setNewTaskStartDate(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Start date"
                  className="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-900"
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-1 text-sm font-medium text-gray-700">
                  Due Date
                </label>
                <DatePicker
                  selected={newTaskEndDate}
                  onChange={(date) => setNewTaskEndDate(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Due date"
                  className="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-900"
                />
              </div>
            </div>

            {/* Assignees */}
            <div className="mt-4">
              <label className="mb-1 text-sm font-medium text-gray-700">
                Assign To
              </label>
              <div className="flex items-center gap-4 mt-1">
                <button
                  type="button"
                  title="Assign Users"
                  onClick={() => {
                    setShowNewTaskUserList(!showNewTaskUserList);
                    setShowNewTaskGroupList(false);
                  }}
                  className="p-2 border rounded hover:bg-gray-50 transition"
                >
                  <UserIcon className="text-gray-600" size={14} />
                </button>
                <button
                  type="button"
                  title="Assign Groups"
                  onClick={() => {
                    setShowNewTaskGroupList(!showNewTaskGroupList);
                    setShowNewTaskUserList(false);
                  }}
                  className="p-2 border rounded hover:bg-gray-50 transition"
                >
                  <UsersIcon className="text-green-600" size={14} />
                </button>
              </div>

              {showNewTaskUserList && (
                <div className="border mt-2 p-3 rounded bg-gray-50">
                  <h4 className="text-sm font-medium mb-2">Select User(s)</h4>
                  {assignees
                    .filter((a) => a.object === "user")
                    .map((usr) => {
                      const isChecked = newTaskAssignees.includes(usr.id);
                      return (
                        <label
                          key={usr.id}
                          className="flex items-center gap-2 mb-2 cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={() => handleToggleNewTaskAssignee(usr.id)}
                          />
                          <span>{usr.name}</span>
                          {isChecked && (
                            <Check size={14} className="text-green-600" />
                          )}
                        </label>
                      );
                    })}
                </div>
              )}

              {showNewTaskGroupList && (
                <div className="border mt-2 p-3 rounded bg-gray-50">
                  <h4 className="text-sm font-medium mb-2">Select Group(s)</h4>
                  {assignees
                    .filter((a) => a.object === "group")
                    .map((grp) => {
                      const isChecked = newTaskAssignees.includes(grp.id);
                      return (
                        <label
                          key={grp.id}
                          className="flex items-center gap-2 mb-2 cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={() => handleToggleNewTaskAssignee(grp.id)}
                          />
                          <span>{grp.name}</span>
                          {isChecked && (
                            <Check size={14} className="text-green-600" />
                          )}
                        </label>
                      );
                    })}
                </div>
              )}
            </div>

            {/* Folder + Attachments */}
            <div className="mt-4">
              <label className="mb-1 text-sm font-medium text-gray-700 block">
                Choose Folder
              </label>
              <button
                onClick={handleOpenFolderModal}
                className="w-full border border-gray-300 rounded-md p-2 bg-gray-50 text-left"
              >
                {newTaskFolderId
                  ? `Folder ID: ${newTaskFolderId}`
                  : "Select a folder"}
              </button>

              <div className="mt-4">
                <label className="mb-1 text-sm font-medium text-gray-700 block">
                  Attach Files
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="file"
                    onChange={handleNewTaskFileUpload}
                    className="hidden"
                    id="new-task-attachment-file"
                  />
                  <label
                    htmlFor="new-task-attachment-file"
                    className="inline-flex items-center gap-2 text-sm border border-gray-300 px-3 py-2 rounded-md cursor-pointer hover:bg-gray-50"
                  >
                    <Upload className="w-4 h-4 text-gray-600" />
                    Add File
                  </label>
                </div>
                {newTaskAttachments.length > 0 && (
                  <div className="mt-2 border p-2 bg-gray-50 rounded">
                    <h4 className="text-sm font-medium mb-2">
                      Newly Selected Files
                    </h4>
                    {newTaskAttachments.map((att, idx) => (
                      <div
                        key={idx}
                        className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-2 bg-white rounded mb-2"
                      >
                        <span className="flex-1 text-sm text-gray-700 truncate">
                          {att.name}
                        </span>
                        <button
                          onClick={() => removeNewTaskFile(att)}
                          className="mt-2 sm:mt-0 sm:ml-4 text-red-500 hover:text-red-700 text-sm"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Bottom Buttons */}
            <div className="flex items-center justify-end mt-6 space-x-3">
              <button
                onClick={cancelNewTask}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm"
              >
                Cancel
              </button>
              <button
                onClick={createNewTask}
                className="px-4 py-2 bg-blue-600 text-white font-medium rounded-md text-sm hover:bg-blue-700"
              >
                Create Task
              </button>
            </div>
          </div>
        )}

        {/* If editing an existing task */}
        {selectedTask && editableTask && !showNewTaskForm && (
          <div className="p-6 overflow-auto relative flex-grow">
            <button
              onClick={() => {
                setSelectedTask(null);
                setEditableTask(null);
              }}
              className="absolute top-4 right-4 p-2 hover:bg-gray-200 rounded-full transition"
              title="Close"
            >
              <X className="w-5 h-5 text-gray-600" />
            </button>

            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Editing: <span className="font-normal">{editableTask.name}</span>
            </h2>

            {/* Edit Task Name */}
            <div className="mb-6">
              <label className="text-sm font-medium text-gray-700 block mb-1">
                Task Name
              </label>
              <input
                type="text"
                value={editableTask.name || ""}
                onChange={(e) => setEditableField("name", e.target.value)}
                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none text-sm text-gray-700"
                placeholder="Update task name..."
              />
            </div>

            {/* Dates & Priority */}
            <div className="mb-6 space-y-4">
              <h3 className="font-semibold text-gray-800">
                Dates &amp; Priority
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700">
                    Start Date
                  </label>
                  <DatePicker
                    selected={
                      editableTask.start_date instanceof Date
                        ? editableTask.start_date
                        : null
                    }
                    onChange={(date) => setEditableField("start_date", date)}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Start date"
                    className="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-700 focus:outline-none"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700">
                    Due Date
                  </label>
                  <DatePicker
                    selected={
                      editableTask.due_date instanceof Date
                        ? editableTask.due_date
                        : null
                    }
                    onChange={(date) => setEditableField("due_date", date)}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Due date"
                    className="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-700 focus:outline-none"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-sm font-medium text-gray-700">
                  Priority
                </label>
                <select
                  value={editableTask.priority}
                  onChange={(e) => setEditableField("priority", e.target.value)}
                  className="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-700 focus:outline-none"
                >
                  <option value="None">None</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </select>
              </div>
            </div>

            {/* Description */}
            <div className="mb-6">
              <label className="text-sm font-medium text-gray-700 block mb-1">
                Description
              </label>
              <textarea
                value={editableTask.description || ""}
                onChange={(e) =>
                  setEditableField("description", e.target.value)
                }
                rows={4}
                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none text-sm text-gray-700"
                placeholder="Enter task details..."
              />
            </div>

            {/* Assignees */}
            <div className="mb-6">
              <h3 className="font-semibold text-gray-800 mb-2">Assignees</h3>
              <div className="flex flex-wrap gap-2 mb-2">
                {(editableTask.assignees || []).map((a) => (
                  <div
                    key={a.id}
                    className="inline-flex items-center px-2 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
                  >
                    {a.name}
                    <button
                      onClick={() => handleEditTaskAssigneeToggle(a.id)}
                      className="ml-2 rounded-full hover:bg-blue-200 p-1"
                    >
                      <X className="w-3 h-3" />
                    </button>
                  </div>
                ))}
                {editableTask.assignees?.length === 0 && (
                  <span className="text-sm text-gray-500 italic">None</span>
                )}
              </div>

              <div className="flex items-center gap-4">
                <button
                  type="button"
                  title="Add/Remove Users"
                  onClick={() => {
                    setShowEditUserList((prev) => !prev);
                    setShowEditGroupList(false);
                  }}
                  className="p-2 border rounded hover:bg-gray-50 transition"
                >
                  <UserIcon className="text-gray-600" size={14} />
                </button>
                <button
                  type="button"
                  title="Add/Remove Groups"
                  onClick={() => {
                    setShowEditGroupList((prev) => !prev);
                    setShowEditUserList(false);
                  }}
                  className="p-2 border rounded hover:bg-gray-50 transition"
                >
                  <UsersIcon className="text-green-600" size={14} />
                </button>
              </div>

              {showEditUserList && (
                <div className="border mt-2 p-3 rounded bg-gray-50">
                  <h4 className="text-sm font-medium mb-2">Select User(s)</h4>
                  {assignees
                    .filter((a) => a.object === "user")
                    .map((usr) => {
                      const selectedIDs = (editableTask.assignees || []).map(
                        (aa) => aa.id
                      );
                      const checked = selectedIDs.includes(String(usr.id));
                      return (
                        <label
                          key={usr.id}
                          className="flex items-center gap-2 mb-2 cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            checked={checked}
                            onChange={() =>
                              handleEditTaskAssigneeToggle(String(usr.id))
                            }
                          />
                          <span>{usr.name}</span>
                          {checked && (
                            <Check size={14} className="text-green-600" />
                          )}
                        </label>
                      );
                    })}
                </div>
              )}

              {showEditGroupList && (
                <div className="border mt-2 p-3 rounded bg-gray-50">
                  <h4 className="text-sm font-medium mb-2">Select Group(s)</h4>
                  {assignees
                    .filter((a) => a.object === "group")
                    .map((grp) => {
                      const selectedIDs = (editableTask.assignees || []).map(
                        (aa) => aa.id
                      );
                      const checked = selectedIDs.includes(String(grp.id));
                      return (
                        <label
                          key={grp.id}
                          className="flex items-center gap-2 mb-2 cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            checked={checked}
                            onChange={() =>
                              handleEditTaskAssigneeToggle(String(grp.id))
                            }
                          />
                          <span>{grp.name}</span>
                          {checked && (
                            <Check size={14} className="text-green-600" />
                          )}
                        </label>
                      );
                    })}
                </div>
              )}
            </div>

            {/* Attachments */}
            <div>
              <div className="flex items-center gap-2 mb-3">
                <Paperclip className="w-5 h-5 text-gray-600" />
                <h3 className="font-semibold text-gray-800 text-sm">
                  Attachments
                </h3>
              </div>
              <div className="space-y-2 mb-4 text-sm">
                {(editableTask.attachments || []).map((att, idx) => (
                  <div
                    key={idx}
                    className="flex items-center justify-between p-3 bg-white rounded-md border"
                  >
                    <div className="flex items-center gap-2">
                      <Paperclip className="w-5 h-5 text-gray-600" />
                      <span className="text-gray-800">{att.name}</span>
                      {att.size && (
                        <span className="text-xs text-gray-500">
                          ({formatFileSize(att.size)})
                        </span>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        const newAtts = editableTask.attachments.filter(
                          (_, i) => i !== idx
                        );
                        const updated = {
                          ...editableTask,
                          attachments: newAtts,
                        };
                        setTasks((old) =>
                          old.map((t) => (t.id === updated.id ? updated : t))
                        );
                        setEditableTask(updated);
                        updateTaskOnServer(updated);
                      }}
                      className="p-1 rounded-full hover:bg-gray-100"
                    >
                      <X className="w-5 h-5 text-gray-600" />
                    </button>
                  </div>
                ))}
                {editableTask.attachments?.length === 0 && (
                  <div className="text-gray-500 italic">No attachments</div>
                )}
              </div>

              {/* Add more attachments */}
              <div className="flex items-center gap-2">
                <input
                  type="file"
                  onChange={(e) => {
                    if (!editableTask) return;
                    const file = e.target.files[0];
                    if (!file) return;
                    const extension = file.name.includes(".")
                      ? file.name.split(".").pop()
                      : "";
                    const newAttachment = {
                      id: "",
                      name: file.name,
                      extension,
                      size: file.size,
                      mime: file.type,
                      download_uri: "",
                    };
                    const updated = {
                      ...editableTask,
                      attachments: [
                        ...(editableTask.attachments || []),
                        newAttachment,
                      ],
                    };
                    setTasks((old) =>
                      old.map((t) => (t.id === updated.id ? updated : t))
                    );
                    setEditableTask(updated);
                    updateTaskOnServer(updated);
                  }}
                  className="hidden"
                  id={`file-upload-${editableTask.id || "new"}`}
                />
                <label
                  htmlFor={`file-upload-${editableTask.id || "new"}`}
                  className="border border-gray-300 px-4 py-2 rounded-md text-sm cursor-pointer hover:bg-gray-100 flex items-center gap-2 text-gray-700"
                >
                  <Upload className="w-4 h-4 text-gray-600" />
                  Upload Files
                </label>
              </div>
            </div>

            {/* DELETE BUTTON */}
            <div className="mt-6 border-t pt-4">
              <button
                type="button"
                onClick={() => setShowDeleteModal(true)}
                className="flex items-center gap-2 text-red-600 hover:text-red-700 border border-red-600 hover:border-red-700 px-4 py-2 rounded transition text-sm"
              >
                <TrashIcon className="w-4 h-4" />
                Delete Task
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Folder Modal */}
      <FolderSelectionModal
        isOpen={isFolderModalOpen}
        folders={nestedFolders}
        onClose={() => setIsFolderModalOpen(false)}
        onSelectFolder={handleFolderSelect}
      />

      {/* Delete Confirm Modal */}
      <DeleteConfirmModal
        isOpen={showDeleteModal}
        taskName={editableTask?.name || ""}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => {
          if (editableTask?.id) {
            deleteTask(editableTask.id);
            setShowDeleteModal(false);
          }
        }}
      />
    </div>
  );
};

export default ProjectPlan;
