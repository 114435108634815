// src/components/ProjectPlan/utils.js

/**
 * Remove duplicates in an array of objects based on `id`.
 */
export function deduplicateById(array) {
  const seen = new Set();
  return array.filter((obj) => {
    if (seen.has(obj.id)) return false;
    seen.add(obj.id);
    return true;
  });
}

/**
 * Try to parse a date string. If parsing fails, return the localValue.
 */
export function parseDateOrFallback(serverValue, localValue) {
  if (!serverValue) return localValue;
  const dt = new Date(serverValue);
  if (isNaN(dt.getTime())) {
    return localValue;
  }
  return dt;
}

/**
 * Format a Date object as a locale date string. If invalid or not a Date, return "—".
 */
export function formatDate(date) {
  if (date instanceof Date && !isNaN(date)) {
    return date.toLocaleDateString();
  }
  return "—";
}

/**
 * Convert file size (bytes) to a more readable format: KB, MB, etc.
 */
export function formatFileSize(bytes) {
  if (bytes < 1024) return `${bytes} B`;
  if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`;
  return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
}
