// UserGroupModal.jsx
import React from "react";
import { XCircleIcon } from "@heroicons/react/outline";

const UserGroupModal = ({
  show,
  onClose,
  editingUserGroup,
  setEditingUserGroup,
  newUserGroup,
  setNewUserGroup,
  addUserGroup,
  updateUserGroup,
  toggleUserInUserGroup,
  users,
  confirmDeleteUserGroup,
}) => {
  if (!show) return null;

  const currentData = editingUserGroup || newUserGroup;

  const handleChange = (field, value) => {
    if (editingUserGroup) {
      setEditingUserGroup({ ...editingUserGroup, [field]: value });
    } else {
      setNewUserGroup({ ...newUserGroup, [field]: value });
    }
  };

  const handleDelete = () => {
    if (!editingUserGroup) return;
    confirmDeleteUserGroup(editingUserGroup.id);
  };

  return (
    // 1) Outer div for backdrop
    <div
      className="fixed z-10 inset-0 overflow-y-auto bg-gray-500 bg-opacity-50"
      onClick={onClose}
      /* 
         Clicking this backdrop closes the modal.
         But we'll stop propagation on the child so 
         clicks inside the modal won't close it.
      */
    >
      {/* 2) Inner container for the modal content */}
      <div
        className="flex items-center justify-center min-h-screen px-4"
        onClick={(e) => e.stopPropagation()}
        /* 
           This stops clicks on the modal itself from 
           bubbling up to the backdrop.
        */
      >
        <div className="bg-white rounded-lg shadow-xl sm:max-w-2xl w-full overflow-hidden transform transition-all relative">
          {/* Header */}
          <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
            <h3 className="text-xl font-medium text-gray-900">
              {editingUserGroup ? "Edit User Group" : "Add New User Group"}
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <XCircleIcon className="w-6 h-6" />
            </button>
          </div>

          {/* Body */}
          <div className="px-6 py-4">
            {/* Group Name */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Group Name
              </label>
              <input
                type="text"
                value={currentData.name}
                onChange={(e) => handleChange("name", e.target.value)}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm 
                           focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

            {/* Description */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                value={currentData.description}
                onChange={(e) => handleChange("description", e.target.value)}
                rows={3}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm 
                           focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

            {/* Assign Users */}
            <div className="border rounded-md p-4 max-h-48 overflow-auto">
              <h4 className="text-sm font-medium text-gray-700 mb-2">
                Assign Users
              </h4>
              <div className="space-y-2">
                {users.map((usr) => {
                  const isChecked = currentData.users.some(
                    (u) => u.id === usr.id
                  );
                  return (
                    <label key={usr.id} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => toggleUserInUserGroup(usr)}
                        className="form-checkbox h-4 w-4 text-indigo-600"
                      />
                      <span className="text-gray-700 text-sm">
                        {usr.firstName} {usr.lastName} ({usr.email})
                      </span>
                    </label>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="bg-gray-50 px-6 py-3 flex justify-end border-t border-gray-200">
            {editingUserGroup && (
              <button
                onClick={handleDelete}
                className="bg-red-600 text-white px-4 py-2 rounded-md 
                           hover:bg-red-700 transition mr-auto"
              >
                Delete
              </button>
            )}
            <button
              onClick={onClose}
              className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md 
                         hover:bg-gray-300 transition mr-2"
            >
              Cancel
            </button>
            <button
              onClick={editingUserGroup ? updateUserGroup : addUserGroup}
              className="bg-indigo-600 text-white px-4 py-2 rounded-md 
                         hover:bg-indigo-700 transition"
            >
              {editingUserGroup ? "Update User Group" : "Add User Group"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGroupModal;
