// App.js
import React from "react";
import "./index.css";
import { Routes, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { auth0Config } from "./auth0Config";
import ProtectedRoute from "./ProtectedRoute";
import LoginPage from "./Pages/LoginPage";
import Layout from "./components/Layout";
import TopicRegister from "./Pages/DMA/TopicRegister";
import ProjectPlan from "./Pages/DMA/ProjectPlan/ProjectPlan";
import AdminDashboard from "./Pages/DMA/AdminDashboaard/AdminDashboard";
import FileRepository from "./Pages/DMA/FileRepository";
import DashboardHome from "./Pages/DMA/DashboardHome";
import Test from "./Test";
import ESGForm from "./Pages/DMA/ESGForm ";
import LevelsPage from "./Pages/DMA/LevelsPage";
import LevelAttributesPage from "./Pages/DMA/LevelAttributes";
import NoAccessPage from "./Pages/NoAccessPage";
// import SimpleAzureBlobUploadDownload from "./components/blobTest";
import AuditTrail from "./Pages/DMA/auditTrail";

function App() {
  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      redirectUri={auth0Config.redirectUri}
      audience={auth0Config.audience}
      scope={auth0Config.scope}
    >
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/test" element={<Test />} />
        <Route path="/no-access" element={<NoAccessPage />} />

        {/* Protected Routes */}
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <Layout>
                <Routes>
                  <Route path="/" element={<DashboardHome />} />
                  <Route path="/admin-dashboard" element={<AdminDashboard />} />
                  <Route path="/project-plan" element={<ProjectPlan />} />
                  <Route path="/topic-register" element={<TopicRegister />} />
                  <Route path="/file-repository" element={<FileRepository />} />
                  <Route path="/pre-kick-off-survey" element={<ESGForm />} />
                  <Route path="/levels" element={<LevelsPage />} />

                  <Route
                    path="/level-attributes"
                    element={<LevelAttributesPage />}
                  />
                  <Route path="/audit-trail" element={<AuditTrail />} />
                </Routes>
              </Layout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Auth0Provider>
  );
}

export default App;
