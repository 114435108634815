// src/components/ProjectPlan/folderUtils.js

/**
 * Converts a flat list of folder objects into a nested folder hierarchy.
 * @param {Array} flatArray - Array of folder objects from the server.
 */
export function buildNestedFolders(flatArray) {
  const folderMap = new Map();
  flatArray.forEach((f) => {
    folderMap.set(f.folder_id, {
      id: f.folder_id,
      name: f.folder_name,
      type: "folder",
      children: [],
      rolls_up_to: f.rolls_up_to,
    });
  });

  const roots = [];
  folderMap.forEach((node) => {
    if (!node.rolls_up_to || node.rolls_up_to === "null") {
      roots.push(node);
    } else {
      const parentId = parseInt(node.rolls_up_to, 10);
      const parent = folderMap.get(parentId);
      if (parent) {
        parent.children.push(node);
      } else {
        roots.push(node);
      }
    }
  });
  return roots;
}
