// /Pages/DMA/LevelAttributesPage.jsx
import React, { useState, useEffect } from "react";
import { FaTrashAlt } from "react-icons/fa";
import Loader from "../../components/Loader";

function LevelAttributesPage() {
  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // -- State for adding a new attribute and its values in one step --
  const [attributeName, setAttributeName] = useState("");
  const [attributeValues, setAttributeValues] = useState("");

  // --------------------------------------------------
  // Fetch existing attributes
  // --------------------------------------------------
  const fetchAttributes = async () => {
    setLoading(true);
    setError(null);

    try {
      // Adjust clientUrl as needed for your environment
      const clientUrl =
        process.env.REACT_APP_CLIENT_URL ||
        "https://miagendb9091-miagen.msappproxy.net";

      const clientId = process.env.REACT_APP_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
      const authString = `${clientId}:${clientSecret}`;
      const encodedAuth = btoa(authString);

      const response = await fetch(`${clientUrl}/ws/rest/getLevelAttributes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify({ string: "cducker@miagen.com" }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`getLevelAttributes request failed: ${errorText}`);
      }

      const data = await response.json();
      console.log("Raw getLevelAttributes response:", data);

      // In your data structure, 'data' might be nested or wrapped,
      // so adjust accordingly if needed.
      const parsed =
        Array.isArray(data) && Array.isArray(data[0]) ? data[0] : data;

      setAttributes(parsed);
    } catch (err) {
      console.error("Error fetching level attributes:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // On mount, load attributes
  useEffect(() => {
    fetchAttributes();
    // eslint-disable-next-line
  }, []);

  // --------------------------------------------------
  // Add a new attribute (and values in one step)
  // --------------------------------------------------
  const handleAddAttributeWithValues = async (e) => {
    e.preventDefault();
    if (!attributeName.trim()) {
      alert("Please enter an attribute name.");
      return;
    }

    // Split comma-separated values -> array
    const valuesArray = attributeValues
      .split(",")
      .map((val) => val.trim())
      .filter((val) => val.length > 0);

    // Build the payload
    const payload = {
      id: "",
      name: attributeName.trim(),
      user: "cducker@miagen.com", // Hard-coded user
      values: valuesArray,
    };

    try {
      const clientUrl =
        process.env.REACT_APP_CLIENT_URL ||
        "https://miagendb9091-miagen.msappproxy.net";

      const clientId = process.env.REACT_APP_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
      const authString = `${clientId}:${clientSecret}`;
      const encodedAuth = btoa(authString);

      console.log("Adding attribute + values with payload:", payload);

      const response = await fetch(`${clientUrl}/ws/rest/addLevelAttribute`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedAuth}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`addLevelAttribute request failed: ${errorText}`);
      }

      // Clear inputs
      setAttributeName("");
      setAttributeValues("");

      // Refresh the attribute list
      await fetchAttributes();
      alert("New attribute and values added successfully!");
    } catch (err) {
      console.error("Error adding attribute + values:", err);
      alert("Error: " + err.message);
    }
  };

  // --------------------------------------------------
  // Delete an attribute
  // --------------------------------------------------
  const handleDeleteAttribute = async (attributeId) => {
    if (!window.confirm("Are you sure you want to delete this attribute?")) {
      return;
    }

    try {
      const clientUrl =
        process.env.REACT_APP_CLIENT_URL ||
        "https://miagendb9091-miagen.msappproxy.net";

      const clientId = process.env.REACT_APP_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
      const authString = `${clientId}:${clientSecret}`;
      const encodedAuth = btoa(authString);

      const payload = {
        user: "cducker@miagen.com",
        id: attributeId.toString(),
      };

      const response = await fetch(
        `${clientUrl}/ws/rest/deleteLevelAttribute`,
        {
          method: "POST", // Use "DELETE" if that's what your endpoint expects
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`deleteLevelAttribute request failed: ${errorText}`);
      }

      await fetchAttributes();
      alert(`Attribute deleted successfully!`);
    } catch (err) {
      console.error("Error deleting attribute:", err);
      alert("Error: " + err.message);
    }
  };

  // --------------------------------------------------
  // Delete an attribute value
  // --------------------------------------------------
  const handleDeleteValue = async (valueId) => {
    if (!window.confirm("Are you sure you want to delete this value?")) {
      return;
    }

    try {
      const clientUrl =
        process.env.REACT_APP_CLIENT_URL ||
        "https://miagendb9091-miagen.msappproxy.net";

      const clientId = process.env.REACT_APP_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
      const authString = `${clientId}:${clientSecret}`;
      const encodedAuth = btoa(authString);

      const payload = {
        user: "cducker@miagen.com",
        id: valueId.toString(),
      };

      const response = await fetch(
        `${clientUrl}/ws/rest/deleteLevelAttributeValue`,
        {
          method: "POST", // Use "DELETE" if that's what your endpoint expects
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedAuth}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `deleteLevelAttributeValue request failed: ${errorText}`
        );
      }

      await fetchAttributes();
      alert("Value deleted successfully!");
    } catch (err) {
      console.error("Error deleting attribute value:", err);
      alert("Error: " + err.message);
    }
  };

  // --------------------------------------------------
  // Rendering
  // --------------------------------------------------
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <p className="text-red-600">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
      {/* Page Title */}
      <h1 className="text-3xl font-bold text-gray-800 mb-8">
        Level Attributes
      </h1>

      {/* Form: Add New Attribute + Values */}
      <div className="mb-8 bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Add New Attribute + Values
        </h2>
        <form
          onSubmit={handleAddAttributeWithValues}
          className="grid grid-cols-1 gap-6 sm:grid-cols-2"
        >
          {/* Attribute Name */}
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Attribute Name
            </label>
            <input
              type="text"
              value={attributeName}
              onChange={(e) => setAttributeName(e.target.value)}
              className="border border-gray-300 rounded-md w-full py-2 px-3 focus:outline-none focus:ring-1 focus:ring-green-200"
              placeholder="e.g. ESG Standard"
            />
          </div>

          {/* Attribute Values */}
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Values (comma-separated)
            </label>
            <input
              type="text"
              value={attributeValues}
              onChange={(e) => setAttributeValues(e.target.value)}
              className="border border-gray-300 rounded-md w-full py-2 px-3 focus:outline-none focus:ring-1 focus:ring-green-200"
              placeholder='e.g. "CSRD, EU Taxonomy"'
            />
          </div>

          <div className="col-span-1 sm:col-span-2 flex items-center justify-end mt-2">
            <button
              type="submit"
              className="bg-green-600 text-white py-2 px-5 rounded-md transition-colors hover:bg-green-700 shadow-sm font-medium"
            >
              Add Attribute + Values
            </button>
          </div>
        </form>
      </div>

      {/* Display Existing Attributes */}
      {attributes.length === 0 ? (
        <p className="text-gray-500">No attributes found.</p>
      ) : (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">
            Existing Attributes
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-sm font-medium text-gray-600"
                  >
                    Attribute Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-sm font-medium text-gray-600"
                  >
                    Values
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-sm font-medium text-gray-600"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-sm text-gray-700">
                {attributes.map((attr) => (
                  <tr
                    key={attr.attribute_id}
                    className="hover:bg-gray-50 transition-colors"
                  >
                    {/* Attribute Name */}
                    <td className="px-4 py-3 font-medium text-gray-800">
                      {attr.attribute_name}
                    </td>

                    {/* Values */}
                    <td className="px-4 py-3">
                      {attr.values && attr.values.length > 0 ? (
                        <ul className="list-disc list-inside space-y-2">
                          {attr.values.map((val) => (
                            <li
                              key={val.value_id}
                              className="flex items-center gap-3"
                            >
                              {val.value}
                              {/* <button
                                onClick={() => handleDeleteValue(val.value_id)}
                                className="text-red-600 hover:text-red-800"
                                title="Delete this value"
                              >
                                <FaTrashAlt />
                              </button> */}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <span className="italic text-gray-500">
                          No values available.
                        </span>
                      )}
                    </td>

                    {/* Actions */}
                    <td className="px-4 py-3">
                      <button
                        onClick={() => handleDeleteAttribute(attr.attribute_id)}
                        className="text-red-600 hover:text-red-800 flex items-center gap-1"
                        title="Delete this attribute"
                      >
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default LevelAttributesPage;
