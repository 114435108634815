import React, { useEffect, useState, useRef } from "react";
import { FilterIcon, SearchIcon } from "@heroicons/react/outline";
import Loader from "../../components/Loader";

function AuditTrail() {
  const [auditData, setAuditData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Sleek search bar state
  const [globalSearch, setGlobalSearch] = useState("");

  /**
   * For each column, store a Set of checked values.
   * If empty => "All" for that column.
   */
  const [filters, setFilters] = useState({
    id: new Set(),
    user: new Set(),
    field: new Set(),
    action: new Set(),
    new_value: new Set(),
    old_value: new Set(),
    timestamp: new Set(),
    reference_id: new Set(),
    reference_table: new Set(),
  });

  // Which column's popover is open
  const [openPopover, setOpenPopover] = useState(null);

  // Basic Auth helper
  function getAuthHeaders() {
    const clientId = process.env.REACT_APP_CLIENT_ID || "someId";
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET || "someSecret";
    const encodedAuth = btoa(`${clientId}:${clientSecret}`);
    return {
      "Content-Type": "application/json",
      Authorization: `Basic ${encodedAuth}`,
    };
  }

  // Fetch data on mount
  useEffect(() => {
    async function fetchAuditTrail() {
      try {
        const response = await fetch(
          "https://miagendb9091-miagen.msappproxy.net/ws/rest/getAuditTrail",
          {
            method: "POST",
            headers: getAuthHeaders(),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Network error: ${response.status} - ${response.statusText}`
          );
        }

        let data = await response.json();
        // Sort by timestamp DESC (latest first)
        // parse each row’s 'timestamp' to a Date, compare in descending order
        data.sort((a, b) => {
          const dateA = a.timestamp ? new Date(a.timestamp) : new Date(0);
          const dateB = b.timestamp ? new Date(b.timestamp) : new Date(0);
          return dateB - dateA; // descending
        });

        setAuditData(data);
      } catch (err) {
        setError(err.message);
        console.error("Fetch audit trail error:", err);
      } finally {
        setLoading(false);
      }
    }

    fetchAuditTrail();
  }, []);

  // Table columns
  const columns = [
    // { key: "id", label: "ID" },
    { key: "user", label: "User" },
    { key: "field", label: "Field" },
    { key: "action", label: "Action" },
    { key: "new_value", label: "New Value" },
    { key: "old_value", label: "Old Value" },
    { key: "timestamp", label: "Timestamp" },
    // { key: "reference_id", label: "Ref. ID" },
    { key: "reference_table", label: "Ref. Table" },
  ];

  // Global search checks all columns
  const globalSearchableFields = columns.map((c) => c.key);

  // Return sorted unique values for each column
  function getUniqueValues(columnKey) {
    const values = new Set(
      auditData.map((row) => (row[columnKey] ? row[columnKey].toString() : ""))
    );
    return Array.from(values).sort();
  }

  // Handle column checkbox toggles
  const handleCheckboxChange = (columnKey, value) => {
    setFilters((prev) => {
      const newSet = new Set(prev[columnKey]);
      if (newSet.has(value)) {
        newSet.delete(value);
      } else {
        newSet.add(value);
      }
      return { ...prev, [columnKey]: newSet };
    });
  };

  // 1) Global search filter
  const globalFilteredData = auditData.filter((row) => {
    const search = globalSearch.toLowerCase().trim();
    if (!search) return true;
    return globalSearchableFields.some((fieldKey) => {
      const rowVal = row[fieldKey]
        ? row[fieldKey].toString().toLowerCase()
        : "";
      return rowVal.includes(search);
    });
  });

  // 2) Column-based filter
  const finalFilteredData = globalFilteredData.filter((row) => {
    return columns.every((col) => {
      const filterSet = filters[col.key];
      if (filterSet.size === 0) return true; // no items => "All"
      const rowVal = row[col.key] ? row[col.key].toString() : "";
      return filterSet.has(rowVal);
    });
  });

  // Manage popovers
  const popoverRefs = useRef({});
  useEffect(() => {
    function handleClickOutside(e) {
      if (!openPopover) return;
      const ref = popoverRefs.current[openPopover];
      if (ref && !ref.contains(e.target)) {
        setOpenPopover(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [openPopover]);

  const togglePopover = (colKey) => {
    setOpenPopover((prev) => (prev === colKey ? null : colKey));
  };

  // CLEAR FILTERS: empties all sets + optionally clears search
  const clearAllFilters = () => {
    const emptySets = {};
    columns.forEach((col) => {
      emptySets[col.key] = new Set();
    });
    setFilters(emptySets);
    setGlobalSearch("");
    setOpenPopover(null);
  };

  // Tailwind classes for the checkboxes
  const checkboxClass =
    "h-4 w-4 text-green-600 border-gray-300 focus:ring-green-500";

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Audit Trail</h1>

      {loading && <Loader />}
      {error && <div className="text-red-600 mb-4">Error: {error}</div>}

      {/* Search + Clear Filters Row */}
      <div className="flex items-center gap-2 mb-4">
        {/* Sleek Search Bar */}
        <div className="relative w-72">
          <SearchIcon className="absolute left-2 top-2.5 w-4 h-4 text-gray-400" />
          <input
            type="text"
            placeholder="Search..."
            className="border border-gray-300 rounded pl-8 pr-3 py-2 w-full focus:outline-none focus:ring-1 focus:ring-green-500 text-sm"
            value={globalSearch}
            onChange={(e) => setGlobalSearch(e.target.value)}
          />
        </div>

        {/* Clear Filters Button */}
        <button
          type="button"
          onClick={clearAllFilters}
          className="text-sm px-3 py-2 border rounded bg-gray-100 hover:bg-gray-200 text-gray-700 focus:outline-none"
        >
          Clear Filters
        </button>
      </div>

      {/* Table */}
      {!loading && !error && auditData.length > 0 && (
        <div className="overflow-x-auto shadow-sm border border-gray-200 rounded-lg">
          <table className="min-w-full table-auto text-sm text-gray-700">
            <thead className="bg-gray-100">
              <tr>
                {columns.map((col) => (
                  <th key={col.key} className="px-4 py-3 relative text-left">
                    <div className="flex items-center gap-2">
                      <span className="font-semibold text-gray-700 text-xs uppercase">
                        {col.label}
                      </span>
                      {/* Filter icon button */}
                      <button
                        type="button"
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        onClick={() => togglePopover(col.key)}
                      >
                        <FilterIcon className="w-4 h-4" />
                      </button>
                    </div>

                    {openPopover === col.key && (
                      <div
                        ref={(el) => (popoverRefs.current[col.key] = el)}
                        className="absolute z-50 bg-white border border-gray-200 shadow-lg rounded p-2 mt-1 w-64 text-sm"
                      >
                        <div className="max-h-44 overflow-y-auto">
                          {getUniqueValues(col.key).map((val) => {
                            const isChecked = filters[col.key].has(val);
                            return (
                              <label
                                key={val || "(empty)"}
                                className="flex items-center space-x-2 py-1 px-2 hover:bg-gray-50 rounded"
                                style={{ fontWeight: 400 }}
                              >
                                <input
                                  type="checkbox"
                                  className={checkboxClass}
                                  checked={isChecked}
                                  onChange={() =>
                                    handleCheckboxChange(col.key, val)
                                  }
                                />
                                <span>{val || "(empty)"}</span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {finalFilteredData.map((item) => (
                <tr key={item.id} className="hover:bg-gray-50">
                  {columns.map((col) => (
                    <td key={`${item.id}-${col.key}`} className="px-4 py-2">
                      {item[col.key] || ""}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* If no matching rows */}
      {!loading &&
        !error &&
        auditData.length > 0 &&
        finalFilteredData.length === 0 && (
          <p className="mt-4 text-gray-500">No matching records found.</p>
        )}
    </div>
  );
}

export default AuditTrail;
