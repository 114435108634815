// FileRepository.js

import React, { useState, useEffect, useRef } from "react";
import Loader from "../../components/Loader";
import {
  Folder,
  File as FileIcon,
  ChevronRight,
  ChevronDown,
  Plus,
  Upload,
  Download,
  Edit2,
  X,
  Trash,
  Grid,
  List as ListIcon,
} from "lucide-react";
import axios from "axios";

/** =========================
    1) ENDPOINTS & AUTH
========================= **/
const clientUrl = "https://miagendb9091-miagen.msappproxy.net";

const GET_FILEREPO_ENDPOINT = `${clientUrl}/ws/rest/getFileRepo`;
const ADD_FOLDER_ENDPOINT = `${clientUrl}/ws/rest/addFolder`;
const ADD_FILE_ENDPOINT = `${clientUrl}/ws/rest/addFile`;
const DELETE_FOLDER_ENDPOINT = `${clientUrl}/ws/rest/deleteFolder`;
const DELETE_FILE_ENDPOINT = `${clientUrl}/ws/rest/deleteFile`;

// Suppose you have endpoints for tasks & topics
const GET_TASKS_ENDPOINT = `${clientUrl}/ws/rest/getTasks`;
const GET_TOPICS_ENDPOINT = `${clientUrl}/ws/rest/getTopics`;

/** Basic Auth, if needed */
function getAuthHeaders() {
  const clientId = process.env.REACT_APP_CLIENT_ID || "someId";
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET || "someSecret";
  const encodedAuth = btoa(`${clientId}:${clientSecret}`);
  return {
    "Content-Type": "application/json",
    Authorization: `Basic ${encodedAuth}`,
  };
}

/** =========================
    2) AZURE BLOB UPLOAD DETAILS
========================= **/
//  Replace these with your actual SAS token & container URL
const sasToken =
  "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2026-01-01T04:39:29Z&st=2025-01-08T20:39:29Z&spr=https&sig=O%2F0i1VStuud2ZXC%2FNDjb9AzwX2E14TY37M9El4TT61I%3D";
const containerUrl =
  "https://greengenblobstorage.blob.core.windows.net/development"; // Example

/** =========================
    3) BUILD & FLATTEN TREE
========================= **/
function buildTreeFromApiData(apiFolders) {
  const folderMap = new Map();

  // Create folder nodes
  apiFolders.forEach((folder) => {
    folderMap.set(folder.folder_id, {
      id: folder.folder_id,
      name: folder.folder_name,
      type: "folder",
      children: [],
      rolls_up_to: folder.rolls_up_to,

      // Additional metadata
      created_by: folder.created_by,
      modified_by: folder.modified_by,
      created_at: folder.created_at || null,
      modified_at: folder.modified_at || null,
      levels: folder.levels || [],
    });
  });

  // Attach file nodes
  apiFolders.forEach((folder) => {
    const node = folderMap.get(folder.folder_id);
    if (!node || !folder.files) return;
    folder.files.forEach((f) => {
      if (!f) return;
      node.children.push({
        id: f.id || null,
        name: f.file_name,
        type: "file",
        mime: f.mime,
        size: f.size,
        extension: f.extension,
        download_uri: f.download_uri,
        tags: f.tags || [],
        created_by: f.created_by,
        modified_by: f.modified_by,
        created_at: f.created_at || null,
        modified_at: f.modified_at || null,
      });
    });
  });

  // Root node
  const root = {
    id: "root",
    name: "File Repository",
    type: "folder",
    children: [],
  };

  // Attach subfolders to root or their parent
  apiFolders.forEach((folder) => {
    const node = folderMap.get(folder.folder_id);
    if (!node) return;

    if (!folder.rolls_up_to || folder.rolls_up_to === "null") {
      root.children.push(node);
    } else {
      const parentId = parseInt(folder.rolls_up_to, 10);
      const parent = folderMap.get(parentId);
      if (parent) {
        parent.children.push(node);
      } else {
        // If we can't find the parent, attach to root just in case
        root.children.push(node);
      }
    }
  });

  return root;
}

function flattenFolders(rootNode) {
  const result = [];
  function dfs(node) {
    if (!node) return;
    if (node.type === "folder" && node.id !== "root") {
      result.push({ id: node.id, name: node.name });
    }
    if (node.children) {
      node.children.forEach(dfs);
    }
  }
  dfs(rootNode);
  return result;
}

/** =========================
   4) MULTI-CHECK DROPDOWN
   (for selecting multiple tasks)
========================= **/
function MultiCheckDropdown({ options, selectedValues, onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown if click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (optionValue) => {
    if (selectedValues.includes(optionValue)) {
      onChange(selectedValues.filter((val) => val !== optionValue));
    } else {
      onChange([...selectedValues, optionValue]);
    }
  };

  const displayText =
    selectedValues.length === 0
      ? "---No tasks selected---"
      : `Selected: ${selectedValues.length} Task(s)`;

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        type="button"
        className="
          relative
          block
          w-full
          rounded
          border border-gray-300
          bg-white
          px-3 py-2
          text-left
          text-sm text-gray-700
          hover:border-gray-400
          focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500
          appearance-none
        "
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {displayText}
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400">
          <ChevronDown size={16} />
        </span>
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-full max-h-60 overflow-auto rounded border border-gray-300 bg-white p-2 shadow">
          {options.length === 0 ? (
            <p className="text-sm text-gray-500">No tasks available.</p>
          ) : (
            options.map((opt) => (
              <label
                key={opt.value}
                className="flex items-center py-1 text-sm cursor-pointer"
              >
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={selectedValues.includes(opt.value)}
                  onChange={() => handleCheckboxChange(opt.value)}
                />
                {opt.label}
              </label>
            ))
          )}
        </div>
      )}
    </div>
  );
}

/** =========================
    5) UPLOAD MODAL
    - with multi-task + single-topic
========================= **/
const UploadModal = ({
  isOpen,
  onClose,
  onUpload,
  folders,
  taskTags,
  topicTags,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [uploading, setUploading] = useState(false);

  // For tasks
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  // We won't store names in state—just the IDs

  // For topics (single <select>)
  const [selectedTopicId, setSelectedTopicId] = useState("");

  // We'll store both in final "tags" array
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFolderId) {
      alert("Please select a folder before uploading.");
      return;
    }
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    setUploading(true);
    try {
      const dataToUpload = {
        file: selectedFile,
        folderId: selectedFolderId,
        selectedTaskIds,
        selectedTopicId,
      };
      await onUpload(dataToUpload);

      // reset
      setSelectedFile(null);
      setSelectedFolderId("");
      setSelectedTaskIds([]);
      setSelectedTopicId("");
      onClose();
    } catch (err) {
      console.error("Error uploading file:", err);
      alert("Failed to upload file. See console.");
    } finally {
      setUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="relative w-full max-w-md rounded-md bg-white p-6 shadow-md">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-700"
        >
          <X size={20} />
        </button>
        <h2 className="mb-4 text-xl font-semibold text-gray-800">
          Upload File
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Folder dropdown */}
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              Choose Folder
            </label>
            <select
              className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring focus:ring-blue-400"
              value={selectedFolderId}
              onChange={(e) => setSelectedFolderId(e.target.value)}
            >
              <option value="">-- Select a Folder --</option>
              {folders.map((fld) => (
                <option key={fld.id} value={fld.id}>
                  {fld.name}
                </option>
              ))}
            </select>
          </div>

          {/* File input */}
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              Select File
            </label>
            <input
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              required
              className="w-full rounded-md border border-gray-300 px-3 py-2
                         focus:outline-none focus:ring focus:ring-blue-400"
            />
          </div>

          {/* Task Tags */}
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              Task Tags
            </label>
            <MultiCheckDropdown
              options={taskTags.map((tg) => ({
                value: String(tg.id),
                label: tg.name,
              }))}
              selectedValues={selectedTaskIds}
              onChange={(updatedIds) => {
                setSelectedTaskIds(updatedIds);
              }}
            />
          </div>

          {/* Topic Tag (single <select>) */}
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              Topic Association
            </label>
            <select
              className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring focus:ring-blue-400"
              value={selectedTopicId}
              onChange={(e) => setSelectedTopicId(e.target.value)}
            >
              <option value="">-- Select a Topic --</option>
              {topicTags.map((tt) => (
                <option key={tt.id} value={tt.id}>
                  {tt.name}
                </option>
              ))}
            </select>
          </div>

          {/* Submit */}
          <button
            type="submit"
            disabled={uploading}
            className={`w-full rounded-md bg-green-600 px-4 py-2 text-white hover:bg-green-700 transition ${
              uploading ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {uploading ? "Uploading..." : "Upload"}
          </button>
        </form>
      </div>
    </div>
  );
};

/** =========================
    6) ITEM (FOLDER/FILE)
========================= **/
const Item = ({
  item,
  level,
  isExpanded,
  onToggle,
  onSelect,
  isSelected,
  onRename,
  onDelete,
  onDownload,
  viewMode,

  // DRAG-AND-DROP props
  onDragStartItem,
  onDragEndItem,
  onDragOverItem,
  onDropItem,
  dragOverFolderId,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(item.name);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const saveName = () => {
    if (editName.trim()) {
      onRename(item.id, editName.trim());
      setIsEditing(false);
    }
  };

  // ========== TILE (GRID) VIEW ==========
  if (viewMode === "tile") {
    return (
      <div
        draggable
        onDragStart={(e) => onDragStartItem(e, item)}
        onDragEnd={(e) => onDragEndItem(e, item)}
        onDragOver={(e) => {
          if (item.type === "folder") {
            onDragOverItem(e, item);
          }
        }}
        onDrop={(e) => {
          if (item.type === "folder") {
            onDropItem(e, item);
          }
        }}
        className={`group relative cursor-pointer
          rounded-lg border border-gray-200 bg-white p-4
          text-center shadow-sm transition hover:shadow-md
          ${isSelected ? "ring-2 ring-blue-300" : ""}
          ${
            item.type === "folder" && item.id === dragOverFolderId
              ? "bg-blue-50 border-blue-300"
              : ""
          }`}
        onClick={() => !isEditing && onSelect(item)}
      >
        <div className="flex flex-col items-center space-y-2">
          {item.type === "folder" ? (
            <Folder className="text-yellow-500" size={36} />
          ) : (
            <FileIcon size={36} />
          )}
          {isEditing ? (
            <input
              ref={inputRef}
              type="text"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              onBlur={saveName}
              onKeyDown={(e) => e.key === "Enter" && saveName()}
              className="mt-1 w-full rounded-md border border-gray-300
                         px-2 py-1 text-sm focus:ring-2 focus:ring-blue-500"
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <span className="mt-1 block text-sm font-medium text-gray-800">
              {item.name}
            </span>
          )}
        </div>

        {!isEditing && (
          <div
            className="absolute bottom-2 left-2 right-2
              flex items-center justify-between opacity-0
              transition-opacity group-hover:opacity-100"
          >
            <div className="flex gap-2">
              {item.type === "file" && onDownload && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDownload(item);
                  }}
                  className="rounded-md p-1 hover:bg-gray-100"
                >
                  <Download size={16} />
                </button>
              )}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(true);
                }}
                className="rounded-md p-1 hover:bg-gray-100"
              >
                <Edit2 size={16} />
              </button>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(item);
              }}
              className="rounded-md p-1 text-red-500 hover:bg-gray-100"
            >
              <Trash size={16} />
            </button>
          </div>
        )}
      </div>
    );
  }

  // ========== LIST VIEW ==========
  return (
    <div
      draggable
      onDragStart={(e) => onDragStartItem(e, item)}
      onDragEnd={(e) => onDragEndItem(e, item)}
      onDragOver={(e) => {
        if (item.type === "folder") {
          onDragOverItem(e, item);
        }
      }}
      onDrop={(e) => {
        if (item.type === "folder") {
          onDropItem(e, item);
        }
      }}
      className={`transition-colors hover:bg-gray-100 rounded-md
        ${isSelected ? "bg-blue-50" : ""}
        ${
          item.type === "folder" && item.id === dragOverFolderId
            ? "border border-blue-300 bg-blue-50"
            : ""
        }`}
      style={{ paddingLeft: `${level * 1.2}rem` }}
      onClick={() => !isEditing && onSelect(item)}
    >
      <div className="group relative flex items-center p-2">
        {item.type === "folder" ? (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onToggle(item.id);
              }}
              className="mr-1 text-gray-600 hover:text-gray-800"
            >
              {isExpanded ? (
                <ChevronDown size={18} />
              ) : (
                <ChevronRight size={18} />
              )}
            </button>
            <Folder className="text-yellow-500" size={18} />
          </>
        ) : (
          <FileIcon size={18} />
        )}

        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            onBlur={saveName}
            onKeyDown={(e) => e.key === "Enter" && saveName()}
            className="ml-2 w-36 rounded-md border border-gray-300 px-2 py-1 text-sm focus:ring-2 focus:ring-blue-500"
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <span className="ml-2 text-sm font-medium text-gray-800">
            {item.name}
          </span>
        )}

        {!isEditing && (
          <div
            className="absolute right-2 flex items-center gap-2 opacity-0
                       transition-opacity group-hover:opacity-100"
          >
            {item.type === "file" && onDownload && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onDownload(item);
                }}
                className="rounded-md p-1 hover:bg-gray-200"
              >
                <Download size={15} />
              </button>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
              }}
              className="rounded-md p-1 hover:bg-gray-200"
            >
              <Edit2 size={15} />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(item);
              }}
              className="rounded-md p-1 text-red-500 hover:bg-gray-200"
            >
              <Trash size={15} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

/** =========================
    7) MAIN FileRepository
========================= **/
export default function FileRepository() {
  const [data, setData] = useState(null);
  const [expandedFolders, setExpandedFolders] = useState(new Set());
  const [selectedItem, setSelectedItem] = useState(null);

  // For the upload modal
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  // For creating folder
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [parentFolderId, setParentFolderId] = useState("");

  // Data states
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState("list");
  const [folders, setFolders] = useState([]);

  // Hard-coded user for now
  const user = { email: "kkurian@miagen.com" };

  // DRAG-AND-DROP
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverFolderId, setDragOverFolderId] = useState(null);

  // For tile mode
  const [currentFolder, setCurrentFolder] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);

  // ========== 7.1) FETCH TASKS & TOPICS (for tags) ==========
  const [taskTags, setTaskTags] = useState([]);
  const [topicTags, setTopicTags] = useState([]);

  useEffect(() => {
    async function fetchTasks() {
      try {
        const headers = getAuthHeaders();
        const payload = { string: "someUser@miagen.com" }; // or user.email
        const resp = await axios.post(GET_TASKS_ENDPOINT, payload, { headers });
        if (resp.data && resp.data[0]) {
          const arrayOfTasks = resp.data[0].map((item) => item.tasks);
          setTaskTags(arrayOfTasks);
        }
      } catch (err) {
        console.error("Error fetching tasks:", err);
      }
    }

    async function fetchTopics() {
      try {
        const headers = getAuthHeaders();
        const payload = { string: "someUser@miagen.com" }; // or user.email
        const resp = await axios.post(GET_TOPICS_ENDPOINT, payload, {
          headers,
        });
        if (resp.data && resp.data[0]) {
          const arrayOfTopics = resp.data[0].map((item) => item.topics);
          setTopicTags(arrayOfTopics);
        }
      } catch (err) {
        console.error("Error fetching topics:", err);
      }
    }

    fetchTasks();
    fetchTopics();
  }, []);

  // ========== 7.2) FETCH FILE REPO ==========
  const fetchFileRepo = async () => {
    setLoading(true);
    try {
      const headers = getAuthHeaders();
      const payload = { string: user.email };

      const response = await axios.post(GET_FILEREPO_ENDPOINT, payload, {
        headers,
      });

      if (!response.data || !response.data[0]) {
        console.warn("[fetchFileRepo] No data or empty array-of-array");
        return null;
      }
      const folderArray = response.data[0];
      if (!folderArray || folderArray.length === 0) {
        console.warn("[fetchFileRepo] No folders found in folderArray.");
        return null;
      }

      const root = buildTreeFromApiData(folderArray);
      const flist = flattenFolders(root);
      setFolders(flist);

      return root;
    } catch (err) {
      console.error("[fetchFileRepo] Error:", err);
      alert("Failed to load file repository.");
      return null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const root = await fetchFileRepo();
      if (root) {
        setData(root);
        setExpandedFolders(new Set([root.id]));
        setCurrentFolder(root);
        setCurrentPath([{ id: root.id, name: root.name }]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If user toggles back to list mode, reset to root
  useEffect(() => {
    if (viewMode === "list" && data) {
      setCurrentFolder(data);
      setCurrentPath([{ id: data.id, name: data.name }]);
    }
  }, [viewMode, data]);

  // ========== 7.3) CREATE FOLDER ==========
  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      alert("Folder name cannot be empty.");
      return;
    }

    setLoading(true);
    try {
      const headers = getAuthHeaders();
      const rolls_up_to = parentFolderId || "null";

      const payload = {
        folder_id: "",
        folder_name: newFolderName,
        rolls_upto: rolls_up_to,
        files: [],
        levels: ["2", "4"],
        user: user.email,
      };

      await axios.post(ADD_FOLDER_ENDPOINT, payload, { headers });
      alert("Folder created successfully!");

      // Re-fetch
      const root = await fetchFileRepo();
      if (root) {
        setData(root);
        const flist = flattenFolders(root);
        setFolders(flist);

        setParentFolderId("");
        setNewFolderName("");

        setCurrentFolder(root);
        setCurrentPath([{ id: root.id, name: root.name }]);
        setExpandedFolders(new Set([root.id]));
      }
    } catch (err) {
      console.error("[handleCreateFolder] Error:", err);
      alert("Failed to create folder. See console.");
    } finally {
      setIsCreatingFolder(false);
      setLoading(false);
    }
  };

  // ========== 7.4) UPLOAD FILE ==========
  const handleUpload = async (fileData) => {
    const { file, folderId, selectedTaskIds, selectedTopicId } = fileData;
    if (!folderId) {
      alert("No folder chosen.");
      return;
    }
    if (!file) {
      alert("No file selected.");
      return;
    }

    // 1) Upload to Azure
    const azureName = file.name; // or rename
    const uploadUrl = `${containerUrl}/${azureName}${sasToken}`;

    setLoading(true);
    try {
      await axios.put(uploadUrl, file, {
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": file.type || "application/octet-stream",
        },
      });
      console.log("File uploaded to Azure successfully.");
    } catch (err) {
      console.error("Azure upload failed:", err);
      alert("Azure upload failed. See console.");
      setLoading(false);
      return;
    }

    // 2) Build the final "tags" array
    //    - multiple Task tags
    //    - single Topic tag
    const taskTagObjs =
      selectedTaskIds.length > 0
        ? selectedTaskIds.map((taskId) => {
            // find the matching name
            const match = taskTags.find((t) => String(t.id) === String(taskId));
            return {
              section: "Task",
              tag_id: String(taskId),
              name: match ? match.name : "NoName",
            };
          })
        : [];

    let topicTagObj = null;
    if (selectedTopicId) {
      const match = topicTags.find(
        (tt) => String(tt.id) === String(selectedTopicId)
      );
      topicTagObj = {
        section: "Topic",
        tag_id: String(selectedTopicId),
        name: match ? match.name : "NoTopicName",
      };
    }

    // Combine them
    const finalTags = [...taskTagObjs];
    if (topicTagObj) {
      finalTags.push(topicTagObj);
    }

    // 3) POST to addFile
    try {
      const payload = {
        folder_id: folderId,
        files: [
          {
            file_name: file.name,
            extension: file.name.split(".").pop(),
            mime: file.type,
            size: String(file.size),
            download_uri: uploadUrl,
            tags: finalTags,
          },
        ],
        user: user.email,
      };

      await axios.post(ADD_FILE_ENDPOINT, payload, {
        headers: getAuthHeaders(),
      });
      alert("File uploaded & recorded successfully.");
    } catch (err) {
      console.error("Error saving file metadata:", err);
      alert("File upload OK, but could not record metadata on server.");
    } finally {
      setLoading(false);
    }

    // 4) Re-fetch
    const root = await fetchFileRepo();
    if (root) {
      setData(root);
      setExpandedFolders((prev) => new Set([...prev, folderId]));
    }
  };

  // ========== 7.5) DELETE (folder or file) ==========
  const handleServerDelete = async (item) => {
    const isFolder = item.type === "folder";
    const endpoint = isFolder ? DELETE_FOLDER_ENDPOINT : DELETE_FILE_ENDPOINT;

    const payload = {
      id: String(item.id),
      user: user.email,
    };

    const confirmMsg = isFolder
      ? `Are you sure you want to delete folder "${item.name}"?`
      : `Are you sure you want to delete file "${item.name}"?`;
    if (!window.confirm(confirmMsg)) return;

    setLoading(true);
    try {
      const headers = getAuthHeaders();
      await axios.delete(endpoint, {
        headers,
        data: payload,
      });
      alert(`${isFolder ? "Folder" : "File"} deleted successfully!`);

      const root = await fetchFileRepo();
      if (root) {
        setData(root);
        setSelectedItem(null);
        setExpandedFolders(new Set([root.id]));
      }
    } catch (err) {
      console.error("[handleServerDelete] Error:", err);
      alert(`Failed to delete ${isFolder ? "folder" : "file"}. Check console.`);
    } finally {
      setLoading(false);
    }
  };

  // ========== 7.6) SELECT / TOGGLE / BREADCRUMB / DOWNLOAD / RENAME ==========
  const handleSelect = (item) => {
    setSelectedItem(item);
    if (viewMode === "tile" && item.type === "folder") {
      setCurrentFolder(item);
      setCurrentPath([...currentPath, { id: item.id, name: item.name }]);
      setSelectedItem(null);
    }
  };

  const handleBreadcrumbClick = (index) => {
    const newPath = currentPath.slice(0, index + 1);
    setCurrentPath(newPath);

    const findFolderById = (node, targetId) => {
      if (node.id === targetId) return node;
      if (node.children) {
        for (const child of node.children) {
          const found = findFolderById(child, targetId);
          if (found) return found;
        }
      }
      return null;
    };

    const targetId = newPath[newPath.length - 1].id;
    const folder = findFolderById(data, targetId);
    setSelectedItem(null);
    setCurrentFolder(folder);
  };

  const toggleFolder = (id) => {
    setExpandedFolders((prev) => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  };

  const handleRename = (id, newName) => {
    // local rename only
    const renameInTree = (node) => {
      if (node.id === id) {
        node.name = newName;
        return;
      }
      node.children?.forEach(renameInTree);
    };
    if (data) {
      const updated = { ...data };
      renameInTree(updated);
      setData(updated);

      if (selectedItem && selectedItem.id === id) {
        setSelectedItem({ ...selectedItem, name: newName });
      }
    }
  };

  const handleDownload = async (file) => {
    if (!file.download_uri) {
      alert(`No download URI found for ${file.name}.`);
      return;
    }
    try {
      const response = await fetch(file.download_uri);
      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name || "downloadedFile");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download error:", error);
      alert("File download failed.");
    }
  };

  const handleDelete = (item) => {
    handleServerDelete(item);
  };

  // ========== 7.7) DRAG-AND-DROP ==========
  const handleDragStartItem = (e, item) => {
    setDraggedItem(item);
    e.dataTransfer.setData("application/json", JSON.stringify(item));
  };

  const handleDragEndItem = () => {
    setDraggedItem(null);
    setDragOverFolderId(null);
  };

  const handleDragOverItem = (e, folderItem) => {
    if (folderItem.type === "folder") {
      e.preventDefault();
      setDragOverFolderId(folderItem.id);
    }
  };

  const handleDropItem = async (e, folderItem) => {
    e.preventDefault();
    if (!draggedItem || folderItem.id === draggedItem.id) {
      return;
    }

    if (draggedItem.type === "file") {
      await handleUpdateFileParent(draggedItem, folderItem.id);
    } else if (draggedItem.type === "folder") {
      await handleUpdateFolderParent(draggedItem, folderItem.id);
    }

    setDraggedItem(null);
    setDragOverFolderId(null);
  };

  async function handleUpdateFileParent(fileItem, newParentFolderId) {
    try {
      setLoading(true);
      const headers = getAuthHeaders();

      const payload = {
        folder_id: String(newParentFolderId),
        files: [
          {
            id: fileItem.id,
            file_name: fileItem.name,
            extension: fileItem.extension,
            mime: fileItem.mime,
            size: fileItem.size,
            download_uri: fileItem.download_uri,
            tags: fileItem.tags || [],
          },
        ],
        user: user.email,
      };

      await axios.post(ADD_FILE_ENDPOINT, payload, { headers });
      const root = await fetchFileRepo();
      if (root) setData(root);
    } catch (err) {
      console.error("Error re-parenting file:", err);
      alert("Failed to move file. Check console.");
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateFolderParent(folderItem, newParentFolderId) {
    try {
      setLoading(true);
      const headers = getAuthHeaders();
      const newRollsUpTo =
        newParentFolderId === "root" ? "null" : String(newParentFolderId);

      const payload = {
        folder_id: String(folderItem.id),
        folder_name: folderItem.name,
        rolls_upto: newRollsUpTo,
        files: [],
        levels: folderItem.levels || [],
        user: user.email,
      };

      await axios.post(ADD_FOLDER_ENDPOINT, payload, { headers });
      const root = await fetchFileRepo();
      if (root) setData(root);
    } catch (err) {
      console.error("Error re-parenting folder:", err);
      alert("Failed to move folder. Check console.");
    } finally {
      setLoading(false);
    }
  }

  // ========== 7.8) RENDER TREE/TILES ==========
  const renderTree = (node, level = 0) => {
    if (!node) return null;
    return (
      <div key={node.id}>
        <Item
          item={node}
          level={level}
          isExpanded={expandedFolders.has(node.id)}
          onToggle={toggleFolder}
          onSelect={handleSelect}
          isSelected={selectedItem?.id === node.id}
          onRename={handleRename}
          onDelete={handleDelete}
          onDownload={handleDownload}
          viewMode={viewMode}
          onDragStartItem={handleDragStartItem}
          onDragEndItem={handleDragEndItem}
          onDragOverItem={handleDragOverItem}
          onDropItem={handleDropItem}
          dragOverFolderId={dragOverFolderId}
        />
        {node.type === "folder" &&
          expandedFolders.has(node.id) &&
          node.children?.map((child) => renderTree(child, level + 1))}
      </div>
    );
  };

  const renderTiles = (folder) => {
    if (!folder?.children || folder.children.length === 0) {
      return <div className="p-4 text-sm text-gray-500">No items here.</div>;
    }
    return (
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {folder.children.map((child) => (
          <Item
            key={child.id}
            item={child}
            level={0}
            isExpanded={false}
            onToggle={() => {}}
            onSelect={handleSelect}
            isSelected={selectedItem?.id === child.id}
            onRename={handleRename}
            onDelete={handleDelete}
            onDownload={handleDownload}
            viewMode={viewMode}
            onDragStartItem={handleDragStartItem}
            onDragEndItem={handleDragEndItem}
            onDragOverItem={handleDragOverItem}
            onDropItem={handleDropItem}
            dragOverFolderId={dragOverFolderId}
          />
        ))}
      </div>
    );
  };

  // ========== 7.9) RENDER JSX ==========
  if (loading) {
    return <Loader />;
  }

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      {/* LEFT COLUMN */}
      <div className="flex w-2/3 flex-col space-y-4 border-r bg-white p-4 overflow-auto">
        {/* Top Bar */}
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-800">
            File Repository
          </h2>
          <div className="flex items-center gap-2">
            {/* Toggle list/tile */}
            <button
              onClick={() =>
                setViewMode((prev) => (prev === "list" ? "tile" : "list"))
              }
              className="rounded-md p-2 hover:bg-gray-100"
              title="Toggle View Mode"
            >
              {viewMode === "list" ? (
                <Grid size={16} />
              ) : (
                <ListIcon size={16} />
              )}
            </button>

            {/* Upload File => opens modal */}
            <button
              onClick={() => setIsUploadModalOpen(true)}
              className="flex items-center gap-2 rounded-md bg-green-600 px-3 py-2 text-white hover:bg-green-700"
            >
              <Upload size={16} />
              Upload File
            </button>

            {/* New Folder */}
            <button
              onClick={() => setIsCreatingFolder(true)}
              className="flex items-center gap-2 rounded-md bg-blue-600 px-3 py-2 text-white hover:bg-blue-700"
            >
              <Plus size={16} />
              New Folder
            </button>
          </div>
        </div>

        {/* Create Folder Form */}
        {isCreatingFolder && (
          <div className="rounded-md border bg-gray-50 p-3 space-y-2">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Parent Folder (optional)
              </label>
              <select
                className="w-full rounded-md border border-gray-300 px-3 py-2 
                           focus:outline-none focus:ring focus:ring-blue-400"
                value={parentFolderId}
                onChange={(e) => setParentFolderId(e.target.value)}
              >
                <option value="">-- Top Level (no parent) --</option>
                {folders.map((fld) => (
                  <option key={fld.id} value={fld.id}>
                    {fld.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Folder Name
              </label>
              <input
                type="text"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                placeholder="Folder name"
                className="w-full rounded-md border border-gray-300 px-3 py-2 
                           focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            <div className="flex justify-end gap-2">
              <button
                onClick={handleCreateFolder}
                className="rounded-md bg-green-600 px-4 py-2 text-white hover:bg-green-700"
              >
                Create
              </button>
              <button
                onClick={() => {
                  setIsCreatingFolder(false);
                  setParentFolderId("");
                  setNewFolderName("");
                }}
                className="rounded-md bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300"
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* If tile view, show breadcrumb */}
        {viewMode === "tile" && currentPath.length > 1 && (
          <div className="flex items-center space-x-1 text-sm text-gray-600">
            {currentPath.map((crumb, i) => (
              <React.Fragment key={crumb.id}>
                <button
                  onClick={() => {
                    handleBreadcrumbClick(i);
                  }}
                  className="cursor-pointer text-blue-600 hover:underline"
                >
                  {crumb.name}
                </button>
                {i < currentPath.length - 1 && (
                  <span className="text-gray-400">/</span>
                )}
              </React.Fragment>
            ))}
          </div>
        )}

        {/* Main Content => list or tile */}
        <div className="flex-1 overflow-auto rounded-md border bg-white p-2">
          {viewMode === "list"
            ? data
              ? renderTree(data)
              : "No data loaded..."
            : currentFolder
            ? renderTiles(currentFolder)
            : "No data loaded..."}
        </div>
      </div>

      {/* RIGHT COLUMN – Detail Pane */}
      <div
        className={`relative w-1/3 border-l bg-white p-6 transition-all duration-300 ${
          selectedItem ? "translate-x-0" : "translate-x-full"
        } overflow-auto`}
      >
        {selectedItem ? (
          <div className="mx-auto max-w-md">
            <button
              onClick={() => setSelectedItem(null)}
              className="absolute right-6 top-6 rounded-full p-2 hover:bg-gray-100"
              title="Close"
            >
              <X size={20} />
            </button>
            <h2 className="mb-2 text-2xl font-bold text-gray-800">
              {selectedItem.name}
            </h2>

            {selectedItem.type === "folder" ? (
              /* ====== Folder Details ====== */
              <div className="space-y-2 text-sm text-gray-700">
                <p className="flex items-center gap-1">
                  <Folder className="text-yellow-500" size={16} />
                  <span className="font-medium">Folder Info</span>
                </p>
                <p className="text-xs italic text-gray-500">
                  Folder ID: {selectedItem.id}
                </p>
                <div>
                  <div className="mt-1">
                    <span className="font-medium">Created By:</span>{" "}
                    {selectedItem.created_by || "Unknown"}
                  </div>
                  <div>
                    <span className="font-medium">Modified By:</span>{" "}
                    {selectedItem.modified_by || "Unknown"}
                  </div>
                  <div>
                    <span className="font-medium">Created At:</span>{" "}
                    {selectedItem.created_at
                      ? new Date(selectedItem.created_at).toLocaleString()
                      : "N/A"}
                  </div>
                  <div>
                    <span className="font-medium">Modified At:</span>{" "}
                    {selectedItem.modified_at
                      ? new Date(selectedItem.modified_at).toLocaleString()
                      : "N/A"}
                  </div>
                  {selectedItem.levels && selectedItem.levels.length > 0 && (
                    <div>
                      <span className="font-medium">Levels:</span>{" "}
                      {selectedItem.levels.join(", ")}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              /* ====== File Details ====== */
              <div className="space-y-4 text-sm text-gray-700">
                <div className="flex items-center gap-1 text-gray-600">
                  <FileIcon size={16} />
                  <span className="font-medium">File Details</span>
                </div>
                <div>
                  <div>
                    <span className="font-medium">MIME:</span>{" "}
                    {selectedItem.mime || "N/A"}
                  </div>
                  <div>
                    <span className="font-medium">Extension:</span>{" "}
                    {selectedItem.extension || "N/A"}
                  </div>
                  <div>
                    <span className="font-medium">Size:</span>{" "}
                    {selectedItem.size || "N/A"}
                  </div>
                  <div className="mt-1">
                    <span className="font-medium">Created By:</span>{" "}
                    {selectedItem.created_by || "Unknown"}
                  </div>
                  <div>
                    <span className="font-medium">Modified By:</span>{" "}
                    {selectedItem.modified_by || "Unknown"}
                  </div>
                  <div>
                    <span className="font-medium">Created At:</span>{" "}
                    {selectedItem.created_at
                      ? new Date(selectedItem.created_at).toLocaleString()
                      : "N/A"}
                  </div>
                  <div>
                    <span className="font-medium">Modified At:</span>{" "}
                    {selectedItem.modified_at
                      ? new Date(selectedItem.modified_at).toLocaleString()
                      : "N/A"}
                  </div>
                </div>

                {selectedItem.tags && selectedItem.tags.length > 0 && (
                  <div className="mt-2 flex flex-wrap gap-2">
                    {selectedItem.tags.map((tagObj, i) => (
                      <span
                        key={i}
                        className="rounded-full bg-blue-100 px-2 py-1 text-xs text-blue-800"
                      >
                        {tagObj.section}: {tagObj.name}
                      </span>
                    ))}
                  </div>
                )}

                <div className="mt-4 flex gap-2">
                  <button
                    onClick={() => handleDownload(selectedItem)}
                    className="inline-flex items-center gap-1 rounded-md bg-green-600 px-3 py-2 text-xs text-white hover:bg-green-700"
                  >
                    <Download size={14} />
                    Download
                  </button>
                  <button
                    onClick={() => handleDelete(selectedItem)}
                    className="inline-flex items-center gap-1 rounded-md bg-red-600 px-3 py-2 text-xs text-white hover:bg-red-700"
                  >
                    <Trash size={14} />
                    Delete
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="flex h-full items-center justify-center text-gray-500">
            Select a file or folder to view details
          </div>
        )}
      </div>

      {/* Upload Modal => pass the flattened folder list, plus tasks & topics */}
      <UploadModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onUpload={handleUpload}
        folders={folders}
        taskTags={taskTags}
        topicTags={topicTags}
      />
    </div>
  );
}
