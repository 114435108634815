// PermissionGroupModal.jsx
import React from "react";
import { XCircleIcon } from "@heroicons/react/outline";

const PermissionGroupModal = ({
  show,
  onClose,
  editingGroup,
  setEditingGroup,
  newGroup,
  setNewGroup,
  addGroup,
  updateGroup,
}) => {
  if (!show) return null;

  // Decide which object we’re editing
  const currentData = editingGroup || newGroup;

  // For updating fields (name, description, etc.)
  const handleChange = (field, value) => {
    if (editingGroup) {
      setEditingGroup({ ...editingGroup, [field]: value });
    } else {
      setNewGroup({ ...newGroup, [field]: value });
    }
  };

  /**
   * Ensures only one subtask in a given section can be flagged.
   * When the user checks a subtask, we set `flag: true` for that subtask
   * and `flag: false` for all other subtasks in the same section.
   */
  const toggleSubtaskFlagSingleChoice = (sectionName, clickedSubtaskName) => {
    // We'll update whichever group is current (editingGroup or newGroup)
    const updatedPermissions = currentData.permissions.map((section) => {
      if (section.section === sectionName) {
        // Clear flags for all subtasks except the clicked one
        const updatedSubtasks = section.subtasks.map((st) => ({
          ...st,
          flag: st.subtask === clickedSubtaskName, // true if match, else false
        }));
        return { ...section, subtasks: updatedSubtasks };
      }
      return section; // unaffected sections
    });

    // Save back to editingGroup or newGroup
    if (editingGroup) {
      setEditingGroup({ ...editingGroup, permissions: updatedPermissions });
    } else {
      setNewGroup({ ...newGroup, permissions: updatedPermissions });
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        {/* Modal backdrop */}
        <div
          className="fixed inset-0 bg-gray-500 opacity-50"
          onClick={onClose}
        />
        {/* Modal content */}
        <div className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-4xl w-full">
          {/* Header */}
          <div className="bg-white px-6 py-4 border-b border-gray-200 flex items-center justify-between">
            <h3 className="text-xl font-medium text-gray-900">
              {editingGroup ? "Edit Group" : "Add New Group"}
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <XCircleIcon className="w-6 h-6" />
            </button>
          </div>

          {/* Body */}
          <div className="px-6 py-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Left side: Basic Info */}
              <div className="space-y-4">
                {/* Group Name */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Group Name
                  </label>
                  <input
                    type="text"
                    value={currentData.name || ""}
                    onChange={(e) => handleChange("name", e.target.value)}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm
                               focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                {/* Description */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <textarea
                    value={currentData.description || ""}
                    onChange={(e) =>
                      handleChange("description", e.target.value)
                    }
                    rows={3}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm
                               focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                {/* Enabled (optional) */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Enabled
                  </label>
                  <select
                    value={currentData.enabled ? "true" : "false"}
                    onChange={(e) =>
                      handleChange("enabled", e.target.value === "true")
                    }
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm
                               focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <option value="true">Enabled</option>
                    <option value="false">Disabled</option>
                  </select>
                </div>
              </div>

              {/* Right side: Permissions */}
              {currentData.permissions && (
                <div className="space-y-4 p-4 border border-gray-300 rounded-md bg-gray-50 max-h-64 overflow-auto">
                  <h4 className="text-sm font-medium text-gray-700 mb-2">
                    Assign Permissions (one subtask per section)
                  </h4>
                  {currentData.permissions.map((permSection, idx) => (
                    <div key={permSection.section} className="mb-4 last:mb-0">
                      <h5 className="font-semibold text-gray-800 text-sm mb-2">
                        {permSection.section}
                      </h5>
                      <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                        {permSection.subtasks.map((st) => (
                          <label
                            key={st.subtask}
                            className="inline-flex items-center space-x-2"
                          >
                            <input
                              type="checkbox"
                              checked={st.flag || false}
                              onChange={() =>
                                toggleSubtaskFlagSingleChoice(
                                  permSection.section,
                                  st.subtask
                                )
                              }
                              className="form-checkbox h-4 w-4 text-indigo-600"
                            />
                            <span className="text-gray-700 text-sm">
                              {st.subtask}
                            </span>
                          </label>
                        ))}
                      </div>
                      {idx < currentData.permissions.length - 1 && (
                        <hr className="my-4 border-gray-200" />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Footer */}
          <div className="bg-gray-50 px-6 py-3 flex justify-end border-t border-gray-200">
            <button
              onClick={onClose}
              className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition mr-2"
            >
              Cancel
            </button>
            <button
              onClick={editingGroup ? updateGroup : addGroup}
              className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition"
            >
              {editingGroup ? "Update Group" : "Add Group"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionGroupModal;
