// src/components/ProjectPlan/DeleteConfirmModal.jsx

import React from "react";
import { X } from "lucide-react";

/**
 * Modal to confirm the deletion of a task.
 */
function DeleteConfirmModal({ isOpen, onClose, onConfirm, taskName }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="relative w-full max-w-sm rounded-md bg-white shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-700"
        >
          <X size={20} />
        </button>
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3">
            Delete Task
          </h2>
          <p className="text-sm text-gray-600 mb-6">
            Are you sure you want to delete{" "}
            <span className="font-medium">{taskName}</span>? This action cannot
            be undone.
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-red-600 text-white font-medium rounded-md text-sm hover:bg-red-700"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmModal;
